import {FC, useEffect, useRef} from "react";
import ReactHlsPlayer from "react-hls-player";
import {VideoPlayerProps} from "../../types/props/live";

export const LivePlayer: FC<VideoPlayerProps> = (
  {
    isPlaying = true,
    hlsUri,
    tankName = null,
    cameraPosition = null,
    latestGridKey,
    onClick,
    isOnHover,
    ...props
  }
) => {
  const playerRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!isPlaying) playerRef.current?.pause();
    else playerRef.current?.play().catch(() => {
    });
  }, [isPlaying]);

  return (
    <div className={`relative justify-start overflow-hidden border-tdpldark border-1 w-full h-full
      ${
      latestGridKey.row === 0 ? "max-w-[100%] max-h-[100dvh]" :
      latestGridKey.row === 1 ? "max-h-[100dvh] min-h-[100dvh]" :
      latestGridKey.row === 2 ? "max-h-[50dvh] min-h-[50dvh]" :
      latestGridKey.row === 3 ? "max-h-[33dvh] min-h-[33dvh]" :
      "max-h-[25dvh] min-h-[25dvh]"
    }`} onClick={onClick} {...props}>
      <p className="absolute z-30 text-white bg-opacity-50 text-[1.25rem] font-bold hover:font-outline-4 m-3"
      style={{textShadow: "-2px 0 #092028, 0 2px #092028, 2px 0 #092028, 0 -2px #092028"}}>
        {tankName ===null? "Undefined" :`${tankName} ${cameraPosition? cameraPosition.replace(/\b[a-z]/, letter => letter.toUpperCase()) : ""}`}
      </p>
      {
        hlsUri === null
          ? <div className="relative w-full h-full bg-tdplindigo z-1 min-h-max"/>
          : <>
            {
              isOnHover
                ? <div className="absolute z-30 w-full h-full bg-white bg-opacity-0 min-h-max hover:bg-opacity-50"/>
                : null
            }

            <ReactHlsPlayer
              src={hlsUri ? hlsUri : ""}
              autoPlay={isPlaying}
              controls={false}
              muted={true}
              playerRef={playerRef}
              playsInline={true}
              width="100%"
              height="100%"
              className="relative w-full h-full z-1"
              style={{
                objectFit: "fill",
                aspectRatio: `16 / 9`
              }}
            />
          </>
      }
    </div>
  )
}
