import {getTankInfoCardDetail} from "../../api/tankInfo/cardContainer";
import {useQuery} from "@tanstack/react-query";
import {getTankById} from "../../api/tank/tank";
import {getOrder} from "../../components/tankInfo/constant";
import {useEffect} from "react";

export const useDetailSettings = (section: "Water"|"Fish"|"Tank") => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "detail", section],
    queryFn: async () => {
      return await getTankInfoCardDetail(getOrder(section))
    },
    staleTime: 1000* 3600,
    refetchOnMount: false
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(()=>{})
    }
  }, [queryResult.isStale]);
  return queryResult

}

export const useTankSpec = (tankId: string) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "tankSpec", tankId],
    queryFn: async () => {
      return await getTankById(tankId)
    },
    staleTime: 1000* 3600,
    refetchOnMount: false
  })


  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(()=>{})
    }
  }, [queryResult.isStale]);
  return queryResult
}