import {FC, useEffect} from "react";
import {ChartResponseBody, PropertyName, TimeUnitName} from "../../../types/apiResponse/chartTypes";
import {ResponsiveLine} from "@nivo/line";
import {TimeTooltip} from "./tooltips/TimeTooltip";
import {convertIntToDate, getTimeFormat} from "../../../utils/chart/stringUtils";
import {makeSingleDomainData} from "../../../utils/chart/makeSingleDomainData";
import {useAvgDistDateStore} from "../../../store/chart/useAvgDistDateStore";
import {onSingleDomainClick} from "../../../utils/chart/chartOnClick";

export type SingleDomainPlotProps = {
  property: PropertyName
  timeUnit: TimeUnitName
  schoolColors: { [schoolId: string]: string }
  chartResponse: ChartResponseBody
}

export type XY = { x: number, y: number }

export const SingleDomainPlot: FC<SingleDomainPlotProps> = (
  {
    property,
    timeUnit,
    chartResponse,
    schoolColors
  }
) => {
  const data = makeSingleDomainData(chartResponse, schoolColors)
  const {setDate} = useAvgDistDateStore()

  const curve = property === "count" ? "stepAfter" : "monotoneX"
  const onClick = onSingleDomainClick(property, setDate)
  const timeFormat = getTimeFormat(timeUnit)

  useEffect(() => {
    if (
      property === "averageWeight"
      && data.at(-1) !== undefined
      && data.at(-1)!.data.at(-1) !== undefined
    ) setDate(data.at(-1)!.data.at(-1)!.x)
  }, []);

  return (
    <ResponsiveLine
      data={property==="biomass"?data.map((item) => {
        return {
          ...item,
          data:item.data.map(v=> {return {x:v.x,y:v.y/1000}})
        }
      }):data}
      margin={{top: 50, right: 50, bottom: 50, left: 70}}
      lineWidth={5}
      xScale={{
        type: 'time',
        min: convertIntToDate(chartResponse.xMin),
        max: convertIntToDate(chartResponse.xMax)
      }}
      yScale={{
        type: 'linear',
        min: property==="biomass"?chartResponse.yMin/1000:chartResponse.yMin,
        max: property==="biomass"?chartResponse.yMax/1000:chartResponse.yMax,
        reverse: false
      }}
      colors={d => d.color}
      axisBottom={{
        format: timeFormat,
        tickSize: 5,
        tickValues: chartResponse.xTicks.map(tick => convertIntToDate(tick)),
        tickRotation: 30
      }}
      axisLeft={{
        tickSize: 5,
        tickValues: property==="biomass"? chartResponse.yTicks.map(tick=>tick/1000):chartResponse.yTicks
      }}
      curve={curve}
      pointSize={0}
      enableSlices="x"
      enableGridX={false}
      enableGridY={false}
      sliceTooltip={(p) => <TimeTooltip item={p}/>}
      onClick={onClick}
    />
  )
}