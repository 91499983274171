import {Dispatch} from "react"
import {UnknownAction} from "redux"
import {delSchool, reloadARTRList, reloadBatchList, reloadSchool} from "../../utils/loadStorage"
import {postData} from "../../utils"
import {TankValuesArrayType} from "../../types/apiResponse/tankTypes"

export type gradeDataType = {
  departureTankId: string
  arrivalTankList: TankValuesArrayType
}

export type gradeResponseType = {
  departureSchoolId: string
  arrivalSchoolsId: string[]
}

export const postGrade = async (
  gradeDate: number,
  gradeData: gradeDataType,
  dispatch: Dispatch<UnknownAction>,
  departureId: string,
  onClose: () => void) => {
  await postData<gradeResponseType>(`/grade?grading-date=${gradeDate}`, gradeData)
    .then((response) => {
      delSchool(departureId, dispatch)
      reloadSchool(response.arrivalSchoolsId, dispatch)
      reloadBatchList(dispatch)
      reloadARTRList(dispatch)
      onClose()
    })
    .catch(() => {
      console.log("Grade API Error")
      alert("ERROR!!")
    })
}