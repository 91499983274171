import {useEffect, useState} from "react";

export const chartBasicColors = [
  "#CC66FF",
  "#FF6699",
  "#FF6666",
  "#FF3366",
  "#FF0000",
  "#FF6633",
  "#FF9933",
  "#FFCC00",
  "#FFCC66",
  "#99FF99",
  "#00FF00",
  "#00CC00",
  "#33CCCC",
  "#00FFFF",
  "#33AAFF",
  "#3300FF",
  "#0000FF",
  "#330066",
  "#6600FF",
  "#990099",
  "#660066",
  "#663300",
  "#330000"
] as const

export const useColorBySchool = (initialSelectedSchools: string[]) => {
  const [coloredSchools, setColoredSchools] = useState<string[]>(initialSelectedSchools)
  const [colorForEachSchool, setColorForEachSchool] = useState<{[schoolId:string]: string}>({})
  const usedColorIndex: number[] = []
  const schoolIdIndex: {[schoolId: string]: number} = {}

  const makeColorHex = (schoolId: string) => {
    let index = -1
    while (true){
      const randomIndex = Math.floor(Math.random() * chartBasicColors.length);
      if (!usedColorIndex.includes(randomIndex)) {
        index = randomIndex
        break
      }
    }
    usedColorIndex.push(index)
    schoolIdIndex[schoolId] = index
    return chartBasicColors[index]
  }

  const popIndex = (schoolId: string, index: number)=>{
    const colorIndex = usedColorIndex.findIndex((value)=> value===index)
    delete usedColorIndex[colorIndex]
    delete schoolIdIndex[schoolId]
  }

  useEffect(() => {
    Object.entries<number>(schoolIdIndex).forEach(entry => {
      if (!coloredSchools.includes(entry[0])) popIndex(...entry)
    })
    coloredSchools.forEach((schoolId) => {
      if (!Object.keys(schoolIdIndex).includes(schoolId)) makeColorHex(schoolId)
    })
    const result: {[schoolId:string]: string} = {}
    Object.entries(schoolIdIndex).forEach(entry => {
      result[entry[0]] = chartBasicColors[entry[1]]
    })
    setColorForEachSchool(result)
  }, [coloredSchools]);

  return {colorForEachSchool, setColoredSchools}
}