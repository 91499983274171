import {create} from "zustand";
import {
  CardDetailSettingResponse,
  CardNameLiteral,
  CardToggleSetting,
  CardTypeLiteral,
  GaugeSetting
} from "../../types/apiResponse/tankInfoSettingTypes";
import {TankType} from "../../types/apiResponse/tankSpecTypes";

export type TankInfoStageState = {
  gaugeSettings: {
    [card in CardNameLiteral]?: GaugeSetting
  }
  toggleSettings: {
    [card in CardNameLiteral]?: boolean
  }
  typeSettings: {
    [card in CardNameLiteral]?: CardTypeLiteral
  }
  detailSettingIds: {
    [card in CardNameLiteral]?: string
  }
  tank?: TankType
  initialSettings: {
    tank?: TankType
    gaugeSettings: {
      [card in CardNameLiteral]?: GaugeSetting
    }
    toggleSettings: {
      [card in CardNameLiteral]?: boolean
    }
    typeSettings: {
      [card in CardNameLiteral]?: CardTypeLiteral
    }
  }
  callbackQueryKeys: any[]
}

export type TankInfoStageAction = {
  clear: () => void
  init: (
    toggleSettings: CardToggleSetting,
    waterDetailSettings: CardDetailSettingResponse[],
    fishDetailSettings: CardDetailSettingResponse[],
    tankSpec: TankType
  ) => void
  editGauge: (card: CardNameLiteral, setting: GaugeSetting) => void
  editType: (card: CardNameLiteral, type: CardTypeLiteral) => void
  editToggle: (card: CardNameLiteral, toggle: boolean) => void
  editTank: (tank: TankType) => void
  addCallbackQueryKeys: (queryKey: any) => void
}

export const useTankInfoStageStore = create<TankInfoStageState & TankInfoStageAction>((set, get) => ({
  gaugeSettings: {},
  toggleSettings: {},
  typeSettings: {},
  tank: undefined,
  detailSettingIds: {},
  initialSettings: {tank: undefined, gaugeSettings: {}, toggleSettings: {}, typeSettings: {}},
  callbackQueryKeys: [],
  init: (toggleSettings, waterDetailSettings, fishDetailSettings, tankSpec) => {
    const newToggleSettings: { [card in CardNameLiteral]?: boolean } = {}
    Object.entries(toggleSettings).forEach((entry) => {
      const key = entry[0]
      const val = entry[1]
      if (key !== "tankInfoSettingId") newToggleSettings[key as CardNameLiteral] = val as boolean
    })
    const gaugeSettings: { [card in CardNameLiteral]?: GaugeSetting } = {}
    const typeSettings: { [card in CardNameLiteral]?: CardTypeLiteral } = {}
    const detailSettingIds: { [card in CardNameLiteral]?: string } = {}
    waterDetailSettings.forEach(detail => {
      if (detail.gaugeSetting !== null) gaugeSettings[detail.cardName] = detail.gaugeSetting
      typeSettings[detail.cardName] = detail.cardType
      detailSettingIds[detail.cardName] = detail.tankInfoCardDetailId
    })
    fishDetailSettings.forEach(detail => {
      if (detail.gaugeSetting !== null) gaugeSettings[detail.cardName] = detail.gaugeSetting
      typeSettings[detail.cardName] = detail.cardType
      detailSettingIds[detail.cardName] = detail.tankInfoCardDetailId
    })
    set(state => ({
      ...state,
      initialSettings: {
        gaugeSettings: gaugeSettings,
        toggleSettings: newToggleSettings,
        typeSettings: typeSettings,
        tank: tankSpec
      },
      detailSettingIds: detailSettingIds
    }))
  },
  clear: () => set(state => ({
    ...state,
    gaugeSettings: {},
    toggleSettings: {},
    typeSettings: {},
    initialSettings: {gaugeSettings: {}, toggleSettings: {}, typeSettings: {}},
    callbackQueryKeys: []
  })),
  editGauge: (card, setting) => {
    if (Object.keys(get().initialSettings.gaugeSettings).includes(card)) {
      const initialGaugeSetting = get().initialSettings.gaugeSettings[card]
      if (initialGaugeSetting !== undefined
        && initialGaugeSetting.minLimit === setting.minLimit
        && initialGaugeSetting.maxLimit === setting.maxLimit
        && initialGaugeSetting.minWarning === setting.minWarning
        && initialGaugeSetting.maxWarning === setting.maxWarning
      ) {
        set(state => ({...state, gaugeSettings: {...state.gaugeSettings, [card]: undefined}}))
        return
      }
    }
    set(state => ({...state, gaugeSettings: {...state.gaugeSettings, [card]: setting}}))
  },
  editType: (card, type) => {
    if (Object.keys(get().initialSettings.typeSettings).includes(card)) {
      const initialTypeSetting = get().initialSettings.typeSettings[card]
      if (initialTypeSetting !== undefined && initialTypeSetting === type) {
        set(state => ({...state, typeSettings: {...state.typeSettings, [card]: undefined}}))
        return
      }
    }
    set(state => ({...state, typeSettings: {...state.typeSettings, [card]: type}}))
  },
  editToggle: (card, toggle) => {
    if (Object.keys(get().initialSettings.toggleSettings).includes(card)) {
      const initialToggleSetting = get().initialSettings.toggleSettings[card]
      if (initialToggleSetting !== undefined && initialToggleSetting === toggle) {
        set(state => ({...state, toggleSettings: {...state.toggleSettings, [card]: undefined}}))
        return
      }
    }
    set(state => ({...state, toggleSettings: {...state.toggleSettings, [card]: toggle}}))
  },
  editTank: (tank) => {
    const initialTank = get().initialSettings.tank
    if (initialTank !== undefined
      && initialTank.volume === tank.volume
      && initialTank.radius === tank.radius
      && initialTank.depth === tank.depth
      && initialTank.tankName === tank.tankName
    ) {
      set(state => ({...state, tank: undefined}))
      return
    }
    set(state => ({...state, tank: tank}))
  },
  addCallbackQueryKeys: (queryKey) => {
    set(state => ({...state, callbackQueryKeys: [...state.callbackQueryKeys, queryKey]}))
  }
}))