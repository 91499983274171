import { create } from "zustand";
import { SubmitTankArrayType, TankValuesArrayType } from "../../types/apiResponse/tankTypes";

type HomeStockState = {
  selectedTanks: SubmitTankArrayType
  tanksValues: TankValuesArrayType
  selectTanksBorderColor: string
  tanksInfoBorderColor: string
  stockData: TankValuesArrayType | null
  typeError: boolean
  requiredError: boolean
};

type HomeStockAction = {
  setSelectedTanks: (selectedTanks: SubmitTankArrayType) => void
  setBorderColor: () => void
  setTanksValues: (tanksValues:TankValuesArrayType) => void
  setTanksInfoBorderColor: () => void
  reset: () => void
};

const initialState: HomeStockState = {
  selectedTanks: [],
  tanksValues:[],
  selectTanksBorderColor: "border border-tdpldarkgrey",
  tanksInfoBorderColor: "border border-tdpldarkgrey",
  stockData: null,
  typeError: false,
  requiredError: false
}

const useHomeStockStore = create<HomeStockState & HomeStockAction>(
  (set, get) => ({
    ...initialState,
    setSelectedTanks: (selectedTanks) => {
      set(() => ({
        selectedTanks: selectedTanks,
        selectTanksBorderColor: "border border-tdpldarkgrey",
        tanksValues: selectedTanks.map((item) => {
          return {
            tankId: item.tankId,
            tankName: item.tankName,
            batchName: item.batchName,
            count: null,
            moveDate: null,
            sampleWeight: null,
          }
        })
      }))
    },
    setTanksValues: (tanksValues) => {
      set(() => {
        const typeError = !tanksValues.every(item=> item.count === null || Number.isInteger(item.count))
        return {
        tanksValues: tanksValues,
        tanksInfoBorderColor: typeError?"border-2 border-tdplmaroon":"border border-tdpldarkgrey",
        requiredError: false,
        stockData: tanksValues.map((item) => {
          return {
            tankId: item.tankId,
            count: item.count,
            moveDate: item.moveDate,
            sampleWeight: item.sampleWeight
          }
        }),
        typeError: typeError
      }})
    },
    setBorderColor: () => {
      set((state) => ({selectTanksBorderColor: state.selectedTanks.length !== 0? "border border-tdpldarkgrey" : "border-2 border-tdplmaroon"}))
    },
    setTanksInfoBorderColor: () => {
      set((state) => {
        const requiredError = !state.tanksValues.every(item => item.count !== null && item.moveDate !== null)
        return {
        tanksInfoBorderColor:requiredError || state.typeError?
        "border-2 border-tdplmaroon"
        : "border border-tdpldarkgrey",
        requiredError:requiredError
      }})
    },
    reset: () => {
      set(initialState)
    },
  }),
)

export default useHomeStockStore;
