import useHomeEditBatchStore from "../../store/home/useHomeEditBatchStore"
import { Input } from "@nextui-org/react"
import {AppState} from '../../store';
import * as BatchStore from "../../store/batch"
import { useSelector } from "react-redux";

export const InputBatchName = () => {
  const {setBatchName, batchId, batchName, batchNameBorderColor, notAllowedBatchName, nameDuplicated} = useHomeEditBatchStore()
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)

  return (
    <>
      <div className='mt-3 font-bold'>Batch Name</div>
      <Input
        radius='sm'
        size='lg'
        variant='bordered'
        placeholder='Batch Name'
        key="BatchName"
        name='BatchName'
        type='string'
        value={batchName??""}
        onChange={event => setBatchName(event.target.value===""?null:event.target.value, Object.entries(storedBatchList).filter(entry=>entry[0]!==batchId).map(item=>item[1].batchName))}
        classNames={{inputWrapper: `${batchNameBorderColor} border-opacity-60`}}/>
      {batchNameBorderColor.includes("tdplmaroon") ?
        (notAllowedBatchName ?
          <p className='m-0 text-sm font-semibold text-tdplmaroon'>Batch Names cannot contain "$".</p> :
            nameDuplicated?
            <p className='m-0 text-sm font-semibold text-tdplmaroon'>A batch with this name already exists.</p> :
          <p className='m-0 text-sm font-semibold text-tdplmaroon'>Please enter a Batch Name.</p>
        )
        :
        <p></p>
        }
      </>
  )
}