import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";
import {PropertyNameArray, SelectedTanksType, TankType } from "../../types/apiResponse/chartTypes";
import { getSchools } from "../../api/school/selectSchool";
import { Edge, EdgeChange, MarkerType, Node, NodeChange, applyEdgeChanges, applyNodeChanges, getConnectedEdges } from "reactflow";
import { ThemeConfig } from "../../components/selectSchools/config";

const propertiesOrder = ["averageWeight", "sampleWeight","feedingAmount","count","biomass","nh3no2"]

type ChartState = {
  batchId: string | null
  batchName: string | null
  selectedSchools: string[];
  properties: PropertyNameArray;
  selectedTanks: SelectedTanksType
  nodes: Node[]
  edges: Edge[]
  schools: SelectedTanksType
  preNodes: Node[]
  preEdges: Edge[]
  preBatchName: string | null
  preBatchId: string | null
  preSelectedTanks: SelectedTanksType
  showState: boolean
};

type ChartAction = {
  setBatch: (batchId: string, batchName: string) => void
  setSchools: () => void;
  setProperties: (properties: PropertyNameArray) => void;
  setSelectedTanks: (tank:TankType) => void
  getSchools: (batchId: string) => void
  reset: ()=>void
  setSelectedEdges: (id:string|null) => void
  onNodesChange: (change:NodeChange[]) => void
  onEdgesChange: (change:EdgeChange[]) => void
  preSet: () => void
  returnPreSet: () => void
  setShowState: () => void
};

const initialState = {
  batchId: null,
  batchName: null,
  selectedSchools: [],
  properties: [],
  selectedTanks: [],
  nodes:[],
  edges: [],
  schools: [],
  preNodes: [],
  preEdges: [],
  preBatchName: null,
  preBatchId: null,
  preSelectedTanks: [],
  showState: false
}

const useChartStore = create(
  persist<ChartState & ChartAction>(
    (set, get) => ({
      ...initialState,
      setBatch: (batchId, batchName) => {
        set((state) => {
          state.getSchools(batchId)
          return { 
          batchId: batchId,
          batchName:batchName, 
          selectedTanks:[],
        }})
      },
      setSchools: () => {
        set((state) => {
          if (state.selectedTanks.length !== 0) {
            return {
              schools: state.selectedTanks,
              selectedSchools: state.selectedTanks.map(item=> item.schoolId)
            }
          } else {
            return {
              schools: [],
              selectedSchools: [],
              batchId: null,
              batchName: null,
              nodes: [],
              edges: [],
              preBatchId:null,
              preBatchName:null,
              preNodes:[],
              preEdges:[]
            }
          }
        });
      },
      setProperties: (properties) => {
        set(() => ({
          properties: properties.sort((x,y) => propertiesOrder.indexOf(x) - propertiesOrder.indexOf(y)),
          showState: false
        }));
      },
      setSelectedTanks: (tank) => {
        set((state) => {
          const isExist = state.selectedTanks.some(item=> item.schoolId === tank.schoolId)
          return {
          selectedTanks: isExist?
            state.selectedTanks.filter(item => item.schoolId !== tank.schoolId)
            : [...state.selectedTanks, tank],
          nodes: isExist? get().nodes.map((node) => {
            if (node.id === tank.schoolId) {
              node.style = {...node.style, backgroundColor:ThemeConfig.inactiveNodeColor}
            }
            return node
          })
          : get().nodes.map((node) => {
            if (node.id === tank.schoolId) {
              node.style = {...node.style, backgroundColor:ThemeConfig.activeNodeColor}
            }
            return node
          })
        }})
      },
      reset: () => {
        set(initialState)
      },
      setSelectedEdges: (id) => {
        set((state) => {
          const selectedEdges = id? getConnectedEdges(state.nodes, state.edges).filter(item=> item.id.split("_")[1]===id):[]
          return {
          edges: get().edges.map((edge) => {
            if (selectedEdges.includes(edge)) {
              edge.style = { strokeWidth: 2, stroke: ThemeConfig.activeEdgeColor};
              edge.markerEnd = {
                type: MarkerType.ArrowClosed,
                width: 10,
                height: 10,
                color: ThemeConfig.activeEdgeColor,
              };
            } else {
              edge.style = { strokeWidth: 1, stroke: ThemeConfig.inactiveEdgeColor };
              edge.markerEnd = {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: ThemeConfig.inactiveEdgeColor,
              };
            }
            return edge
          })
        }})
      },
      getSchools: async (batchId) => {
        const currentData = await getSchools(batchId)
        set((state) => ({
          nodes: currentData? currentData.nodes.map((item) => {
            return {
              id: item.schoolId,
              position: 
              {
                x: ThemeConfig.unitSize*ThemeConfig.widthSize*item.x ,
                y: ThemeConfig.heightSize*ThemeConfig.unitSize*ThemeConfig.rawSize*item.y
              },
              type: "tankNode",
              data: {
                tankName: item.tankName,
                startDate: item.startDate,
                endDate: item.endDate,
                handles: item.handles,
                width: ThemeConfig.unitSize*ThemeConfig.widthSize*(item.width-1) + ThemeConfig.unitSize*(ThemeConfig.widthSize-1),
                height:ThemeConfig.unitSize * ThemeConfig.heightSize
              },
              style:{backgroundColor:
                state.selectedTanks.map(item=>item.schoolId).includes(item.schoolId)? ThemeConfig.activeNodeColor: ThemeConfig.inactiveNodeColor},
              deletable:false,
            }
          }) : [],
          edges: currentData? currentData.edges.map((item) => {
            return {
              id: `${item.sourceId}_${item.targetId}`,
              source: item.sourceId,
              target: item.targetId,
              style: {
                strokeWidth: 1,
                stroke: ThemeConfig.inactiveEdgeColor,
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: ThemeConfig.inactiveEdgeColor,
              },
              sourceHandle:item.sourceHandleId,
              targetHandle:item.targetHandleId
            }
          }) : []
        }))
      },
      onNodesChange: (changes) => {
        set({
          nodes: applyNodeChanges(changes, get().nodes)
        })
      },
      onEdgesChange: (changes) => {
        set({
          edges: applyEdgeChanges(changes, get().edges)
        })
      },
      preSet: () => {
        set((state) => ({
          preNodes: state.nodes,
          preEdges: state.edges,
          preBatchId: state.batchId,
          preBatchName: state.batchName,
          preSelectedTanks: state.selectedTanks,
          showState: false
        }))
      },
      returnPreSet: () => {
        set((state) => ({
          nodes: state.preNodes,
          edges: state.preEdges,
          batchId: state.preBatchId,
          batchName: state.preBatchName,
          selectedTanks: state.preSelectedTanks
        }))
      },
      setShowState: () => {
        set(() => ({showState:true}))
      }
    }),
    {
      name: "Chart-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useChartStore