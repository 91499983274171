import {Card, CardBody, Divider} from "@nextui-org/react";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import * as SchoolStore from '../../store/school'
import * as TankStore from '../../store/tank'
import {schoolCardProps} from "../../types/props/home";
import {CardDetail} from "./CardDetail";
import {useTankInfoModalStore} from "../../store/tankInfo/useTankInfoModalStore";

export const SchoolCard: FC<schoolCardProps> = (
  {
    schoolId, tankId, ...props
  }
) => {
  const schoolInfo = useSelector<AppState, SchoolStore.SchoolInfo>(state => state.school[schoolId])
  const tankInfo = useSelector<AppState, TankStore.TankInfo>(state => state.tank[tankId])
  const {open, setTank} = useTankInfoModalStore()
  const onOpenTankInfo = () => {
    setTank(tankId, tankInfo.tankName)
    open()
  }
  // const cardContent = testGetSchool(schoolId)

  const dateString = schoolInfo?.moveDate !== undefined ? `${schoolInfo?.moveDate.toString().slice(0, 4)}-${schoolInfo?.moveDate.toString().slice(4, 6)}-${schoolInfo?.moveDate.toString().slice(6, 8)}` : "unknown"
  return (
    <Card className="bg-tdplsky min-w-[16rem] items-center border-1 border-tdpldarkgrey border-opacity-50 p-[.5rem]"
          radius="lg" {...props} isPressable onPress={onOpenTankInfo} isHoverable
          style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
      <CardBody>
        <h2 className="font-bold text-tdpldark text-xl text-[1.25rem]">{tankInfo?.tankName}</h2>
        <Divider className="my-[1rem] h-[1px] bg-tdpldarkgrey"/>
        <CardDetail itemName="AVERAGE WEIGHT" itemDesc={schoolInfo?.averageWeight} itemUnit="g"/>
        <CardDetail itemName="COUNT" itemDesc={schoolInfo?.count}/>
        <CardDetail itemName="TOTAL BIOMASS" itemDesc={schoolInfo?.totalBiomass} itemUnit="g"/>
        <CardDetail itemName="DENSITY" itemDesc={schoolInfo?.density} itemUnit="g/m³"/>
        <CardDetail itemName="MAX DENSITY" itemDesc={tankInfo?.maxDensity} itemUnit="kg/m³"/>
        <CardDetail itemName="FEED AMOUNT" itemDesc={schoolInfo?.feedingRate} itemUnit="kg"/>
        <CardDetail itemName="MOVE DATE" itemDesc={dateString}/>
      </CardBody>
    </Card>
  )
}