import {batchListResponseType, batchResponseType} from "../types/apiResponse/batchTypes";
import * as BatchStore from "../store/batch";
import {ARListResponseType} from "../types/apiResponse/activeRelationTypes";
import * as ARStore from "../store/activeRelation";
import {TankListResponseType} from "../types/apiResponse/tankTypes";
import * as TankStore from "../store/tank";
import * as SchoolStore from '../store/school'
import * as TRStore from "../store/tankRelation"
import {getARList, getBatchList, getSchool, getTankList} from "../api/layout";
import {schoolResponseType} from "../types/apiResponse/schoolTypes";
import {SchoolInfo} from "../store/school";
import {Dispatch} from "react";
import {UnknownAction} from "redux";
import {BatchInfo} from "../store/batch";

const storeBatchList = (batchList: batchListResponseType, dispatch: Dispatch<UnknownAction>) => {
  const batchStoreState: BatchStore.BatchState = {}
  batchList?.forEach((value) => {
    batchStoreState[value.batchId] = {
      batchName: value.batchName,
      startDate: value.startDate
    }
  })
  batchStoreState["$unbatched"] = {batchName: "Unbatched Tanks", startDate: 0}
  batchStoreState["$empty"] = {batchName: "Empty Tanks", startDate: 0}
  dispatch(BatchStore.setBatches(batchStoreState))
}
const storeARList = (activeRelationList: ARListResponseType, dispatch: Dispatch<UnknownAction>) => {
  const activeRelationStoreState: ARStore.ARState = {}
  activeRelationList?.forEach((value) => {
    const curBatchId = value.schoolId === null ? "$empty" : value.batchId === null ? "$unbatched" : value.batchId
    if (curBatchId in activeRelationStoreState) {
      activeRelationStoreState[curBatchId] = [
        ...activeRelationStoreState[curBatchId],
        {
          schoolId: value.schoolId,
          tankId: value.tankId
        }
      ]
    } else {

      activeRelationStoreState[curBatchId] = [
        {
          schoolId: value.schoolId,
          tankId: value.tankId
        }
      ]
    }
  })
  dispatch(ARStore.setAR(activeRelationStoreState))

  const tankRelationStoreState: TRStore.TRState = {}
  activeRelationList?.forEach((value) => {
    const curBatchId = value.schoolId === null ? "$empty" : value.batchId === null ? "$unbatched" : value.batchId
    if (value.tankId in tankRelationStoreState) {
      tankRelationStoreState[value.tankId] = [
        ...tankRelationStoreState[value.tankId],
        {
          schoolId: value.schoolId,
          batchId: curBatchId
        }
      ]
    } else {

      tankRelationStoreState[value.tankId] = [
        {
          schoolId: value.schoolId,
          batchId: curBatchId
        }
      ]
    }
  })
  dispatch(TRStore.setTR(tankRelationStoreState))
}

const storeTankList = (tankList: TankListResponseType, dispatch: Dispatch<UnknownAction>) => {
  const tankStoreState: TankStore.TankState = {}
  tankList?.forEach((value) => {
    tankStoreState[value.tankId] = {
      size: value.size,
      location: value.location,
      tankName: value.tankName,
      maxDensity: value.maxDensity
    }
  })
  dispatch(TankStore.setTanks(tankStoreState))
}

export const addSchool = (schoolResponse: schoolResponseType, dispatch: Dispatch<UnknownAction>) => {
  const schoolInfo: SchoolInfo & { schoolId: string } = {...schoolResponse}
  dispatch(SchoolStore.addSchool(schoolInfo))
}

export const delSchool = (schoolId: string, dispatch: Dispatch<UnknownAction>) => {
  dispatch(SchoolStore.delSchool({schoolId: schoolId}))
}

export const updateSchool = (schoolResponse: schoolResponseType, dispatch: Dispatch<UnknownAction>) => {
  dispatch(SchoolStore.updateSchool(schoolResponse))
}

export const delSchoolStoreMany = (schoolIdList: string[], dispatch: Dispatch<UnknownAction>) => {
  schoolIdList?.forEach((schoolId) => delSchool(schoolId, dispatch))
}

export const reloadSchool = (schoolIdList: string[], dispatch: Dispatch<UnknownAction>) => {
  schoolIdList?.forEach((schoolId) => getSchool(
    schoolId, (schoolResponse) => addSchool(schoolResponse, dispatch)
  ))
}

export const addBatch = (batchResponse: batchResponseType, dispatch: Dispatch<UnknownAction>) => {
  const batchInfo: BatchInfo & { batchId: string } = {...batchResponse}
  dispatch(BatchStore.addBatch(batchInfo))
}

export const delBatch = (batchId: string, dispatch: Dispatch<UnknownAction>) => {
  dispatch(BatchStore.delBatch({batchId: batchId}))
}

export const updateBatch = (batchResponse: batchResponseType, dispatch: Dispatch<UnknownAction>) => {
  dispatch(BatchStore.updateBatch(batchResponse))
}

export const reloadBatchList = (dispatch: Dispatch<UnknownAction>) => {
  getBatchList(
    (batchResponse) => storeBatchList(batchResponse, dispatch)
  )
}

export const reloadTankList = (dispatch: Dispatch<UnknownAction>) => {
  getTankList(
    (tankResponse) => storeTankList(tankResponse, dispatch)
  )
}

export const reloadARTRList = (dispatch: Dispatch<UnknownAction>) => {
  getARList(
    (activeRelationResponse) => storeARList(activeRelationResponse, dispatch)
  )
}