import {LineChart, Line} from 'recharts';
import {FC} from "react";
import {makeIndexedGraphForm} from "../../../utils/graph/tinyLineGraphUtils";
import {CustomizedDot} from "../svg/CustomizedDot";

export type TinyLineGraphProps = {
  values: (number|null)[]
}

export const TinyLineGraph: FC<TinyLineGraphProps> = ({values}) => {
  const data = makeIndexedGraphForm(values)
  return (
    <LineChart width={100} height={40} data={data} >
      <Line dataKey="x" type="monotone" stroke="#9CA7A9" strokeWidth={2} dot={CustomizedDot} connectNulls/>
    </LineChart>
  )
}