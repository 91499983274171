import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, Selection} from "@nextui-org/react"
import {FC} from 'react'
import useCustomizeLiveStore from "../../store/live/useCustomizeLiveStore"
import {cameraPosUpdater} from "../../utils/live/cameraPositionFuncs";
import { camListType } from "../../types/props/live";


export interface DropDownContentProps {
  title: string
  num: number
  selectedValue: Selection
  setSelectedValue: (value:Selection) => void
}

export const DropDownContent: FC<DropDownContentProps> = ({title, num, selectedValue, setSelectedValue}) => (
  <div>
    <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{title}</div>
    <Dropdown>
      <DropdownTrigger>
        <Button
          variant="bordered"
          className="capitalize border border-tdpldarkgrey border-opacity-60"
        >
          {selectedValue}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Single selection"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedValue?? undefined}
        onSelectionChange={setSelectedValue}
      >
        {Array.from({length: num}, (_, index) => index + 1).map(item => (
          <DropdownItem key={item} textValue={String(item)} title={item}>{item}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  </div>
)

type PositionDropDownProps = {
  splitIndex: number
  arrIndex: number
  item: camListType
}
export const PositionDropdown: FC<PositionDropDownProps> = ({
  splitIndex,
  arrIndex,
  item,
}) => {
  const { cameraGrid, cameraList, setCameraList, allCamerasMap} = useCustomizeLiveStore()
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          id={String((splitIndex) * Number(cameraGrid[0]) + arrIndex)}
          variant="flat"
          className={`capitalize bg-white border border-tdpldarkgrey border-opacity-60 ${item.cameraPosition?"":"text-tdpllightgrey"}`}
          size='sm'
          style={{alignSelf: 'center', fontSize: "1rem"}}
          isDisabled={item.tankName === null}
        >
          {item.cameraPosition ?? "Camera"}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        id={String((splitIndex) * Number(cameraGrid[0]) + arrIndex)}
        aria-label="Select Camera Position"
        variant="flat"
        closeOnSelect={true}
        selectionMode="single"
        selectedKeys={item.cameraPosition ? item.cameraPosition : undefined}
        onSelectionChange={(value) => cameraPosUpdater(
          Number(((splitIndex) * Number(cameraGrid[0]) + arrIndex)),
          value,
          cameraList,
          setCameraList
        )}
        disabledKeys={allCamerasMap && item.tankId && allCamerasMap[item.tankId]? allCamerasMap[item.tankId]: ["left", "right", "top"]}
      >
        <DropdownItem key="left">Left</DropdownItem>
        <DropdownItem key="right">Right</DropdownItem>
        <DropdownItem key="top">Top</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}