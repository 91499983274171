import { FC } from "react"
import { useDisclosure } from "@nextui-org/react"
import useHomeUpdateStore from "../../store/home/useHomeUpdateStore"
import { CommonAlert } from "../common/Alert"
import { useDispatch } from "react-redux"
import { patchUpdate } from "../../api/tank/update"
import { BaseModalFooter } from "../common/Modal"
import { useQueryClient } from "@tanstack/react-query"

type InitialInfoButtonProps = {
  onClose: () => void
  isBack: () => void
}
export const InitialInfoButton:FC<InitialInfoButtonProps> = ({
  onClose,
  isBack
}) => {
  const {isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert} = useDisclosure()
  const {beEmptyTanks, updateDate, updateData, typeError, requiredError, checkRequired } = useHomeUpdateStore()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const chekTanksValuesBeforeFinish = () => {
    checkRequired()
    if(!typeError && !requiredError) {
      if (beEmptyTanks.length !== 0) {
        onOpenAlert()
      } else {
        finishJob(onClose) 
      }
    }
  }

  const finishJob = (onClose:()=>void) => {
    if (updateDate && updateData.length !== 0) {
      patchUpdate(updateDate, updateData, dispatch, updateData.map(item => item.schoolId), onClose).then( async () => {
        await queryClient.invalidateQueries({queryKey: ["undoable"]})
      })
    }
  }
  return (
    <>
      <BaseModalFooter back="Back" next="Finish" backOnPress={()=>{onClose();isBack()}} nextOnPress={chekTanksValuesBeforeFinish}/>
      <CommonAlert isOpenAlert={isOpenAlert} onCloseAlert={onCloseAlert} onClose={onClose} continueJob={finishJob} title='Some tanks will be empty' 
        children={beEmptyTanks.map((item, index) => <p key={index}>{item}</p>
      )}/>
    </>
  )
}