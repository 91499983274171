import {FC} from "react";
import {useSingleLive} from "../../hooks/live/useSingleLive";
import {Card, CardBody, CardHeader} from "@chakra-ui/react";
import {LivePlayer} from "../live/LivePlayer";
import {CameraDropdown} from "./CameraDropdown";

export type TankInfoLiveContainerProps = {
  tankId: string | null
  initialHlsUri: string | null
  initialPosition: string | null
}

export const TankInfoLiveContainer: FC<TankInfoLiveContainerProps> = (
  {
    tankId,
    initialHlsUri,
    initialPosition
  }
) => {
  const {
    hlsUri,
    cameraPosition,
    setCameraPosition,
    cameraDetail
  } = useSingleLive(tankId, initialPosition, initialHlsUri)

  return (
    <div>
      <div className="font-bold text-[1.25rem] text-tdplindigo my-1">Live</div>
      <Card bg={'#F5F2F0'} borderColor={'#5A6C6F99'} borderWidth={0.5} my={1.5}
            style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
        <CardHeader className="flex flex-row justify-between">
          <p className="text-tdpldarkgrey text-[1rem] font-bold pr-1">Live</p>
          <CameraDropdown initialPosition={cameraPosition} setCameraPosition={setCameraPosition}
                          cameraDetail={cameraDetail}/>
        </CardHeader>
        <CardBody className="w-[100%] h-auto]">
          {
            cameraPosition !== null
              ? <LivePlayer hlsUri={hlsUri} tankName={""} cameraPosition={""}
                            latestGridKey={{col: 0, row: 0}} onClick={() => {
              }}/>
              : null
          }
        </CardBody>
      </Card>
    </div>
  )
}