import "reactflow/dist/style.css";
import { Select, SelectItem } from "@nextui-org/react";
import { useSelector } from "react-redux";
import useChartStore from "../../store/chart/useChartStore";
import { AppState } from "../../store";
import * as BatchStore from "../../store/batch";
import { useShallow } from "zustand/react/shallow";

export const SelectBatch = () => {
  const { batchId, setBatch } = useChartStore(useShallow((state) => ({
    batchId: state.batchId,
    setBatch: state.setBatch
  })));
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(
    (state) => state.batch
  );

  const onSelectionChange = (event: any) => {
    let batchId = event.target.value;
    if (batchId) {
      setBatch(String(batchId), storedBatchList[event.target.value].batchName);
    }
  };

  return (
    <Select
      label="Select Batch"
      variant="bordered"
      radius="sm"
      size="md"
      
      placeholder={`Batch Name${"\u00a0\u00a0"}|${"\u00a0\u00a0"}Start Date`}
      classNames={{
        trigger:"w-full border border-tdpldarkgrey border-opacity-60",
        label:"font-bold text-[1rem]",
        base:"pt-[1rem] pb-[1rem]",
        value:"text-tdpldark",
        
      }}
      disallowEmptySelection={true}
      labelPlacement="outside"
      defaultSelectedKeys={batchId?[batchId]:undefined}
      onChange={onSelectionChange}
      selectorIcon={<p/>}
    >
      {Object.entries(storedBatchList)
        .filter((entries) => !["$empty"].includes(entries[0]))
        .sort((a, b) => b[1].startDate - a[1].startDate)
        .map((entries) => (
          <SelectItem key={entries[0]} value={entries[1].batchName}>
            {entries[0] === "$unbatched"
            ? entries[1].batchName
            : `${entries[1].batchName}\u00a0\u00a0|\u00a0\u00a0${String(entries[1].startDate).slice(0, 4)}-${String(entries[1].startDate).slice(4, 6)}-${String(entries[1].startDate).slice(6, 8)}`}
          </SelectItem>
        ))}
    </Select>
  );
}