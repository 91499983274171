import {HomeIcon} from "./svg/HomeIcon";
import {ChartIcon} from "./svg/ChartIcon";
import {LiveIcon} from "./svg/LiveIcon";
// import {DataIcon} from "./svg/DataIcon";
import {SideButton} from "./SideButton";


export default function SideBar() {

  return (
    <div className="bg-white mt-0 border-solid border-t-0 border-l-0 border-r-1 border-b-0
    border-tdpldarkgrey border-opacity-50 h-100vh min-w-fit max-w-fit px-[1rem]"
     >
      <SideButton to="/" title="Home"><HomeIcon/></SideButton>
      <SideButton to="/chart" title="Chart"><ChartIcon/></SideButton>
      <SideButton to="/live" title="Live"><LiveIcon/></SideButton>
      {/*<SideButton to="/data" title="Data"><DataIcon/></SideButton>*/}
    </div>
  )
}
