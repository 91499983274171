import {CameraDetailResponseType, cameraMapType, getAllCamerasResponseType, videoMapResponseType} from "../types/apiResponse/liveTypes";
import {getData, postData} from "../utils"

export const postLive = async (cameraMap: cameraMapType, setIsUpdated: () => void, onClose: () => void) => {
  postData<string[]>('/update-video-map', cameraMap)
    .then(() => {
      setIsUpdated()
      onClose()
    })
    .catch(() => {
      alert("ERROR!!")
    })
}

export const getAllCameras = async (setAllCameras:(allCameras:getAllCamerasResponseType)=>void) => {
  getData<getAllCamerasResponseType>('/camera-set/all')
    .then((response) => {
      setAllCameras(response)
    })
    .catch(() => {
      alert("ERROR!!")
    })
}

export const getVideoMap = (setVideoMap: (videoResponse: videoMapResponseType) => void) => {
  getData<videoMapResponseType>("video-map")
    .then((response) => {
      setVideoMap(response)
    })
}


export const getCameraDetail = (tankId: string, setCameraDetail: (cameraDetail: CameraDetailResponseType | null) => void) => {
  getData<CameraDetailResponseType>(`camera-set/camera-detail?tank-id=${tankId}`)
    .then((response) => setCameraDetail(response))
    .catch(() => setCameraDetail(null))
}