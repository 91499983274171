export const columns = [
  {
    key: "tankName",
    label: "TANK NAME",
    placeholder:""
  },
  {
    key: "nh3",
    label: "NH3 AMMONIA",
    placeholder:"Enter NH3 (mg/L)"
  },
  {
    key: "no2",
    label: "NO2 NITRITE",
    placeholder:"Enter NO2 (mg/L)"
  },
  {
    key: "sampleWeight",
    label: "SAMPLE WEIGHT",
    placeholder:"Enter weight (g)"
  },
  {
    key: "feedingAmount",
    label: "FEED AMOUNT",
    placeholder:"Enter feed amount (kg)"
  },
  {
    key: "feedTimes",
    label: "FEED TIMES",
    placeholder:"Enter number of times"
  },
  {
    key: "mortality",
    label: "MORTALITY",
    placeholder:"Enter number"
  },
  {
    key: "harvest",
    label: "HARVEST",
    placeholder:"Enter number"
  },
  {
    key: "resultingCount",
    label: "RESULTING COUNT",
    placeholder:""
  },
];

export default columns