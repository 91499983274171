import {Card, CardBody, Divider} from "@nextui-org/react";
import {SchoolCard} from "./SchoolCard";
import {FC} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {BatchInfo} from "../../store/batch";
import * as ARStore from '../../store/activeRelation'
import {EditBatch} from "../editBatch";
import {TankState} from "../../store/tank";


export const SchoolCardContainer: FC<{ batchId: string }> = ({batchId}) => {
  const batchInfo = useSelector<AppState, BatchInfo>(state => state.batch[batchId])
  const activeRelationInfo = useSelector<AppState, ARStore.ARInfo>(state => state.activeRelation[batchId])
  const tankState = useSelector<AppState, TankState>(state => state.tank)

  const createCardBlocks = activeRelationInfo&&tankState?[...activeRelationInfo].sort(
    (arBefore, arAfter) => {
      // if (tankState[arBefore.tankId] === undefined || tankState[arAfter.tankId] === undefined) return 1
      if (tankState[arBefore.tankId]?.tankName.toLowerCase() > tankState[arAfter.tankId]?.tankName.toLowerCase()) return 1
      else return -1
    }).map(
    (props, index) => {
      return (
        <span key={index}>
          <SchoolCard schoolId={props.schoolId} tankId={props.tankId}/>
        </span>
      )
    }
  ):[<></>]
  const dateString = String(batchInfo.startDate)

  return (
    <div className="mt-10">
      <div className="flex justify-between my-1">
        {batchId !== "$unbatched"
          ? <div className="flex justify-start gap-[1.25rem]">
            <div className="ml-1 text-tdplindigo text-[1.25rem]">{batchInfo.batchName}</div>
            <Divider className="w-[1px] h-[60%] mt-1 mx-2 bg-tdpldarkgrey bg-opacity-60" orientation="vertical"/>
            <div className="text-tdpldarkgrey text-[1.25rem] text-opacity-60">{
              `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`
            }</div>
          </div>
          : <div className="flex justify-start my-1">
            <div className="ml-1 text-tdplindigo text-[1.25rem]">{batchInfo.batchName}</div>
          </div>

        }
        {batchId !== "$unbatched"
        ? <EditBatch batchId={batchId} baseBatchName={batchInfo.batchName}/>
          : null
        }
      </div>
      <Card className="bg-white border-opacity-50 border-1 border-tdpldarkgrey p-2" shadow="none">
        <CardBody className="flex flex-row flex-wrap justify-start gap-[1rem] p-[1.5rem]">
          {createCardBlocks}
        </CardBody>
      </Card>
    </div>
  )
}

export default SchoolCardContainer;