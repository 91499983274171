import {CustomizeLive} from "../components"
import React, {SyntheticEvent, useEffect, useState} from "react";
import {videoMapResponseType} from "../types/apiResponse/liveTypes";
import {LiveGrid} from "../components/live/LiveGrid";
import {getVideoMap} from "../api/live";
import {LivePlayer} from "../components/live/LivePlayer";
import {useTankInfoModalStore} from "../store/tankInfo/useTankInfoModalStore";

export default function Live() {
  const [videoMap, setVideoMap] = useState<videoMapResponseType | null>(null)
  const {open, setTank, setVideo, isOpen} = useTankInfoModalStore()
  const videoUpdate = () => {
    getVideoMap(setVideoMap)
  }
  useEffect(() => {
    getVideoMap(setVideoMap)
  }, []);

  return (
    <div className="p-[40px]">
      <div className="flex justify-between">
        <p className="font-bold text-[2rem] mb-[40px]">Live</p>
        <CustomizeLive setIsUpdated={videoUpdate} videoMap={videoMap}/>
      </div>
      {videoMap !== null
        ? <LiveGrid {...videoMap}>
          {videoMap.videoUrls.map((videoProps, index) => {
            const onVideoClick = (e: SyntheticEvent) => {
              setTank(videoProps.tankId, videoProps.tankName)
              setVideo(videoProps.hlsUri, videoProps.cameraPosition)
              e.preventDefault()
              e.stopPropagation()
              open()
            }
            return (
              <LivePlayer
                key={index}
                isPlaying={!isOpen}
                latestGridKey={videoMap.latestGridKey}
                tankName={videoProps.tankName}
                hlsUri={videoProps.hlsUri}
                cameraPosition={videoProps.cameraPosition}
                onClick={videoProps.hlsUri?onVideoClick:()=>{}}
                isOnHover={true}
              />
            )
          })}
        </LiveGrid>
        : <></>
      }
    </div>
  )
}