import {FC} from 'react'
import {ModalContent, ModalBody, ModalFooter, Button} from "@nextui-org/react"
import useHomeStockStore from '../../store/home/useHomeStockStore';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { SelectStockTanks } from './SelectStockTanks';
import { ModalText } from '../common/Text';

export interface SelectTanksStockProps {
  isOpen: boolean
  onOpen: () => void
  onOpenChange: () => void
}

export const SelectTanksStock : FC<SelectTanksStockProps> = ({isOpen, onOpenChange, onOpen}) => {
  const { selectedTanks, setBorderColor } = useHomeStockStore()

  const checkTanksBeforeContinue = (onClose1: () => void, onOpen: () => void) => {
    setBorderColor()
    if (selectedTanks.length !== 0) {
      onClose1()
      onOpen()
    }
  }

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"} isDismissable={true}>
      <ModalContent>
      {(onClose) => (
        <>
        <BaseModalHeader title='Stock' description={ModalText('Stock')}/>
        <ModalBody className='gap-1 min-h-[8rem] pr-10 pl-10'>
          <BaseDivider/>
          <SelectStockTanks/>
        </ModalBody>
        <ModalFooter className='pb-8 pl-10 pr-10'>
          <Button className="font-bold text-white w-[6rem] mr-0 bg-tdplplum" onPress={() => {checkTanksBeforeContinue(onClose, onOpen)}}>
          Continue
          </Button>
        </ModalFooter>
        </>
      )}
      </ModalContent>
    </BaseModal>
  )
}