import { Tooltip } from '@nextui-org/react';
import { format, parse } from 'date-fns';
import { FC,memo } from 'react';
import { Handle, Position } from 'reactflow';
import { HandleType } from '../../types/apiResponse/selectSchoolsTypes';
import { ThemeConfig } from './config';

type TankNodeType = {
    data: {
      tankName:string
      startDate:number
      endDate: number | null
      handles: HandleType[]
      width:number,
      height:number
    }
}
type PositionKey = 'right' | 'left' | 'top' | 'bottom'

const PositionDict: Record<PositionKey, Position> = {
  right: Position.Right,
  left: Position.Left,
  top: Position.Top,
  bottom: Position.Bottom
}

export const TankNode: FC<TankNodeType> = memo(({ data }) => {
  return (
    <div className="text-updater-node" >
      <Tooltip className='bg-opacity-50' content={
        <div className="px-1 py-2">
          <div className="font-bold text-small">Tank Name: {data.tankName}</div>
          <div className="font-bold text-small">Start Date: {format(parse(String(data.startDate), 'yyyymmdd', new Date()), 'yyyy-mm-dd')}</div>
          <div className="font-bold text-small">End Date: {data.endDate? format(parse(String(data.endDate), 'yyyymmdd', new Date()), 'yyyy-mm-dd'): ""}</div>      
        </div>
      } placement="top" closeDelay={0} disableAnimation={true}>
        <div key={data.tankName} style={{width:data.width, height:data.height, padding:0}}><span>{data.tankName}</span></div>
      </Tooltip>
      {data.handles.map((handle)=> {
        return (
          <Handle
          key={handle.handleId}
          id={handle.handleId}
          type={handle.type === "source"? "source": "target"}
          position={PositionDict[handle.position as PositionKey]}
          style={handle.shift && (handle.position==='top' || handle.position==='bottom')? {left: handle.shift * ThemeConfig.unitSize* ThemeConfig.widthSize}: {}}
          />
        )
      })}
    </div>
  );
});