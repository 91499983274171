import {Dispatch} from "react"
import {TankValuesArrayType} from "../../types/apiResponse/tankTypes"
import {postData} from "../../utils"
import {UnknownAction} from "redux"
import {reloadARTRList, reloadBatchList, reloadSchool} from "../../utils/loadStorage"

export type addBatchType = {
  batchName: string | null
  startDate: number | null
  filledTanks: string[]
  emptyTanks: TankValuesArrayType | []
}

export type addBatchResponseType = {
  batchId: string
  emptyTankSchoolId: string[]
}

export const postAddBatch = async (
  addBatchData: addBatchType,
  dispatch: Dispatch<UnknownAction>,
  onClose: () => void) => {
  await postData<addBatchResponseType>('/batch/add', addBatchData)
    .then((response) => {
      reloadSchool(response.emptyTankSchoolId, dispatch)
      reloadBatchList(dispatch)
      reloadARTRList(dispatch)
      onClose()
    })
    .catch(() => {
      console.log("Add Batch API Error")
      alert("ERROR!!")
    })
}