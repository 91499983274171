import {ARListResponseType} from "../types/apiResponse/activeRelationTypes";
import {getData} from "../utils";
import {batchListResponseType} from "../types/apiResponse/batchTypes";
import {TankListResponseType} from "../types/apiResponse/tankTypes";
import {schoolResponseType} from "../types/apiResponse/schoolTypes";

export const getARList = (
  setState: (activeRelationResponse: ARListResponseType) => void
) => {
  getData<ARListResponseType>('active-relations')
    .then((response) => {
      setState(response)
    })
}

export const getBatchList = (
  setState: (batchResponse: batchListResponseType) => void
) => {
  getData<batchListResponseType>('batches')
    .then((response) => {
      setState(response)
    })
}

export const getTankList = (
  setState: (tankResponse: TankListResponseType) => void
) => {
  getData<TankListResponseType>('tanks')
    .then((response) => {
      setState(response)
    })
}

export const getSchool = (
  schoolId: string,
  setState: (schoolResponse: schoolResponseType) => void
) => {
  getData<schoolResponseType>(`school/${schoolId}`)
    .then((response) => {
      setState(response)
    })

}