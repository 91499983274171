import React, {FC, HTMLProps} from "react";
import {convertMetric} from "../../utils/metricConvert";

export type DetailProps = {
  itemName: string
  itemDesc: number | null | undefined | string
  itemUnit?: string
} & HTMLProps<HTMLDivElement>

export const CardDetail: FC<DetailProps> = (
  {
    itemName,
    itemDesc,
    itemUnit,
    ...props
  }
) => {
  const {val, metric} = convertMetric(itemDesc, itemName, itemUnit)
  const descString = metric&&val!=="---"?`${val} ${metric}`:`${val}`

  return (
    <div {...props} className="flex justify-between">
      <span className={"text-tdplprussian text-[0.875rem] font-bold"}>{itemName} :</span>
      <span className="text-tdplprussian text-[0.875rem]">
        {descString}
      </span>
    </div>
  )
}