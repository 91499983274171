import {convertIntToDate} from "./stringUtils";
import {chartBasicColors} from "../../hooks/chart/useColorBySchool";
import {ChartResponseBody} from "../../types/apiResponse/chartTypes";

export const makeSingleDomainData = (
  chartResponse: ChartResponseBody,
  schoolColors: { [id: string]: string }
) => {
  return Object.entries(chartResponse.value).map(entry => {
    const schoolId = entry[0]
    const points = entry[1].sort((a, b) => a.x < b.x ? -1 : 1)
    const data = points.map(({x, y}) => ({
      x: convertIntToDate(x),
      y: y
    }))
    return {
      id: schoolId,
      data: data,
      color: schoolColors[schoolId] !== undefined ? schoolColors[schoolId] : chartBasicColors[Math.floor(Math.random() * 14)]
    }
  })
}