import {Card} from "@nextui-org/react";
import {SliceTooltipProps} from "@nivo/line";
import {FC} from "react";

export type PlotTooltipProps = {
  item: SliceTooltipProps
  labels: number[]
}

export const HistTooltip: FC<PlotTooltipProps> = (
  {
    item,
    labels
  }
) => {
  return (
    <Card className="w-[25dvh] h-fill">
      {item.slice.points.map(value => {
        const targetIndex = value.index % (labels.length - 1)
          return (
            <div key={value.id} className="flex flex-row justify-between mr-1">
              <div className="mt-2 ml-2">
                <svg width="10" height="10">
                  <rect width="10" height="10" fill={value.color}/>
                </svg>
              </div>
              <p>{`${labels[targetIndex]} ~ ${labels[targetIndex + 1]}`}</p>
              <p>{value.data.y.toLocaleString()} %</p>
            </div>
          )
        }
      )}
    </Card>
  )
}