import {Card} from "@nextui-org/react";
import {SliceTooltipProps} from "@nivo/line";
import {FC} from "react";

export type PlotTooltipProps = {
  item: SliceTooltipProps
}

export const TimeTooltip: FC<PlotTooltipProps> = (
  {
    item,
  }
) => {
  const timeUnitOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric"
  }

  return (
    <Card className="w-[25dvh] h-fill">
      {item.slice.points.map(value => {
          return (
            <div key={value.id} className="flex flex-row justify-between mr-1">
              <div className="mt-2 ml-2">
                <svg width="10" height="10">
                  <rect width="10" height="10" fill={value.color}/>
                </svg>
              </div>
              <p>{((value.data.x) as Date).toLocaleDateString("en-US", timeUnitOptions)}</p>
              <p>{Math.round(Number(value.data.y)).toLocaleString()}</p>
            </div>
          )
        }
      )}
    </Card>
  )
}