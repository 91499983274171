import {
  CardNameLiteral,
  CardToggleSetting,
  CardTypeLiteral,
  GaugeSetting
} from "../../types/apiResponse/tankInfoSettingTypes";
import {TankType} from "../../types/apiResponse/tankSpecTypes";
import {patchData, putData} from "../../utils";
import {useTankInfoStageStore} from "../../store/tankInfo/useTankInfoStageStore";


export const patchToggleSetting = async (body: {[card in CardNameLiteral]?: boolean}) => {
  await patchData("tank-info-setting", body)
}
export const putGaugeSetting = async (body: GaugeSetting, detailSettingId: string) => {
  await putData(`tank-info-card-detail/${detailSettingId}/gauge-setting`, body)
}
export const putCardTypeSetting = async (cardType: CardTypeLiteral, detailSettingId: string) => {
  await putData(`tank-info-card-detail/${detailSettingId}/card-type`, {cardType: cardType})
}
export const putTankSpec = async (tankSpec: TankType, tankId: string) => {
  await putData(`tank?tank-id=${tankId}`, tankSpec)
}

export const totalSettingMutation = async (tankId: string) => {
  const {
    gaugeSettings,
    toggleSettings,
    typeSettings,
    tank,
    detailSettingIds,
    addCallbackQueryKeys
  } = useTankInfoStageStore.getState()
  let isSettingMutated = false
  for (const entry of Object.entries(gaugeSettings)) {
    if (entry[1] === undefined) continue;
    await putGaugeSetting(entry[1], detailSettingIds[entry[0] as CardNameLiteral] as string)
    isSettingMutated = true
  }
  for (const entry of Object.entries(typeSettings)) {
    if (entry[1] === undefined) continue;
    await putCardTypeSetting(entry[1], detailSettingIds[entry[0] as CardNameLiteral] as string)
    isSettingMutated = true
  }
  if (Object.keys(toggleSettings).length>0) {
    await patchToggleSetting(toggleSettings)
    addCallbackQueryKeys(["tankInfo", "toggle"])
  }
  if (isSettingMutated) {
    addCallbackQueryKeys(["tankInfo", "detail", "Water"])
    addCallbackQueryKeys(["tankInfo", "detail", "Fish"])
    addCallbackQueryKeys(["tankInfo", "detail", "Tank"])
  }

  if (tank !== undefined) {
    await putTankSpec(tank, tankId)
    addCallbackQueryKeys(["tankInfo", "detail", "Tank"])
    addCallbackQueryKeys(["tankInfo", "value", tankId, "tankVolume"])
  }
}