import {getData, postData} from "../utils";
import {undoResponseType} from "../types/apiResponse/undoTypes";
import {schoolResponseType} from "../types/apiResponse/schoolTypes";
import {batchResponseType} from "../types/apiResponse/batchTypes";
import {AxiosError} from "axios";

export const postUndo = (
  setState: (undoResponse: undoResponseType) => void
) => {
  postData<undoResponseType>('undo')
    .then((response) => {
      setState(response)
    }).catch((reason: unknown) => {
    if (reason instanceof AxiosError) {
      alert("can not undo more.")
    } else throw new Error()
  })
}

export const getSchoolById = async (schoolId: string) => {
  return await getData<schoolResponseType>(`school/${schoolId}`)
}

export const getBatchById = async (batchId: string) => {
  return await getData<batchResponseType>(`batch/${batchId}`)
}

export const getUndoable = async () => {
  return await getData<boolean>('undoable')
}