import {FC, useRef} from 'react'
import {
  ModalContent,
  ModalBody,
  Card,
} from "@nextui-org/react";
import {TankValuesType} from '../../types/apiResponse/tankTypes';
import { InsertTable } from '../common/Table';
import columns from '../common/columns';
import { insertDataInGradeUpdate } from '../../utils/selectTank/common';
import useHomeEditBatchStore from '../../store/home/useHomeEditBatchStore';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { InitialInfoButton } from './InitialInfoButton';
import { EventType } from '../common/Input';
import { ModalText } from '../common/Text';

export interface InitialInfoProps {
  onOpenChange: () => void
  isOpen: boolean
  isBack: () => void
}

export const InitialInfo: FC<InitialInfoProps> = ({
  onOpenChange,
  isOpen,
  isBack,
}) => {
  const { selectedEmptyTanks, setSelectedEmptyTanks, tanksInfoBorderColor, typeError, requiredError } = useHomeEditBatchStore()
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(selectedEmptyTanks.length).fill(null));

  const insertData = (event:EventType) => {
    insertDataInGradeUpdate<TankValuesType>(event, selectedEmptyTanks, setSelectedEmptyTanks)
  }

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Edit Batch Information' description={ModalText('InsertTable')}/>
            <ModalBody className='gap-0 min-h-[25rem] pr-10 pl-10'>
              <BaseDivider/>
              <Card className={`mt-3 ${tanksInfoBorderColor} border-opacity-60 min-h-[20rem]`}>         
                <InsertTable tanksValues={selectedEmptyTanks} inputRefs={inputRefs} columns={columns} insertData={insertData}/>
              </Card>
              {typeError?
                <p className='m-0 text-sm font-semibold text-tdplmaroon'>*COUNT must be integers</p> :
                <p></p>}
              {requiredError?
                <p className='m-0 text-sm font-semibold text-tdplmaroon'>*COUNT and *MOVE DATE fields are required.</p> :
                <p></p>}
            </ModalBody>
            <InitialInfoButton isBack={isBack} onClose={onClose} />
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
};
