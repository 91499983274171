import {FC, useEffect, useState} from "react";
import LoginBackgroundImage from "../assets/image/LoginBackground.png"
import {LoginForm} from "../components/login/LoginForm";
import {BookDemo} from "../components/login/BookDemo";
import {useAuthStore} from "../store/auth/useAuthStore";
import {introspect} from "../api/axiosAuthorization";
import {Navigate} from "react-router-dom";

export const Login: FC = () => {
  const {token, refreshToken} = useAuthStore()
  const [isAccessTokenValid, setIsAccessTokenValid] = useState<boolean>(false)
  const [isRefreshTokenValid, setIsRefreshTokenValid] = useState<boolean>(false)

  useEffect(() => {
    if (refreshToken !== null) introspect("refresh", setIsRefreshTokenValid)
    if (token !== null) introspect("access", setIsAccessTokenValid)
  }, [token, refreshToken]);

  return (
    <div className="relative w-[100%] h-[100dvh] ">
      <img className="absolute w-[100%] h-[100dvh] object-fill z-0" src={LoginBackgroundImage} alt=""/>
      <div className="absolute w-[100%] h-[100dvh] flex flex-col justify-center items-center">
        {isAccessTokenValid && isRefreshTokenValid
          ?<Navigate to="/"/>
          :<></>
        }
        <LoginForm/>
        <BookDemo/>
      </div>
    </div>
  )
}