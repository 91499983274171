import {FC} from "react";
import {
  CardNameLiteral,
} from "../../../types/apiResponse/tankInfoSettingTypes";
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {makeTitle} from "../../../utils/tankInfo";
import {TinyLineDist} from "./TinyLineDist";
import {useTankInfoDistribution} from "../../../hooks/tankInfo/useTankInfoValues";
import {NoContent} from "./NoContent";

export type DistProps = {
  tankId: string
  cardName: CardNameLiteral
}

export const DistCard: FC<DistProps> = (
  {
    tankId,
    cardName,
  }
) => {
  const tankInfoDist = useTankInfoDistribution(tankId, cardName)
  if (tankInfoDist === null || tankInfoDist === undefined) return <></>
  else {
    const values = tankInfoDist[cardName]?.values
    const xTicks = tankInfoDist[cardName]?.xTicks
    const {title, subTitle} = makeTitle(cardName)

    return (
      <Card className="rounded-lg col-span-1 outline outline-[1px] outline-tdpldarkgrey/[.60] bg-tdplbisque basis-[253px] h-[140px] p-[19px] pb-[9px]"
            style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
        <CardHeader className="flex flex-row items-start m-0 p-0">
          <p className="text-tdpldarkgrey text-[1rem] font-bold">
            {title}
          </p>
          {subTitle !== undefined
            ? <p className="text-tdplmiddlegrey text-[1rem]">
              {subTitle}
            </p>
            : null
          }
        </CardHeader>
        <CardBody className="m-0 p-0 h-[65px] overflow-hidden">
          <div>
            {
              values && xTicks
                ? <TinyLineDist values={values} xTicks={xTicks}/>
                : <NoContent/>
            }
          </div>
        </CardBody>
      </Card>
    )
  }
}