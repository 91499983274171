import {FC, PropsWithChildren} from "react";
import {videoMapResponseType} from "../../types/apiResponse/liveTypes";


export const LiveGrid: FC<PropsWithChildren<videoMapResponseType>> = ({
 latestGridKey,
  children
}) => {

  return (
    <div className={`grid grid-flow-auto gap-0 mb-1 max-w-100 content-center justify-center items-center border-1 ${
      latestGridKey.col === 1 ? 'grid-cols-1' : 
      latestGridKey.col === 2 ? 'grid-cols-2' : 
      latestGridKey.col === 3 ? 'grid-cols-3' : 
      'grid-cols-4'
    }`}>
      {children}
    </div>
  )
}