import {Button} from "@nextui-org/react"
import { SelectTanksToBrowse } from "../components/selectSchools"
import useChartStore from "../store/chart/useChartStore"
import {ChartContainer} from "../components/chart/ChartContainer"
import { AddSelectSchools } from "../components/selectSchools/AddSelectSchools"
import { SelectProperties } from "../components/selectSchools/SelectProperties"

export default function Chart() {
  const {selectedSchools, properties, setShowState, showState} = useChartStore(
    (state) => ({
      selectedSchools: state.selectedSchools,
      properties: state.properties,
      setShowState: state.setShowState,
      showState: state.showState
    })
  )

  return (
    <div className="p-[40px]">
      <div className="flex justify-between">
        <div>
          <h1 className="font-bold text-tdplindigo mb-[40px] text-[2rem]">Chart</h1>
        </div>
      </div>
      <div className="flex gap-2 justify-evenly overflow">
        <SelectTanksToBrowse/>
      </div>
      <div className="flex items-center justify-between mt-1">
        <AddSelectSchools />
        <div className="flex items-center">
          <SelectProperties />
          <Button isDisabled={(!(selectedSchools.length > 0 && properties.length > 0))}
           size="sm" className="ml-2 text-white bg-tdplplum" onClick={setShowState}>
            Browse
          </Button>
        </div>
      </div>
      <ChartContainer showState={showState}/>
    </div>
  )
}