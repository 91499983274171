import {FC, PropsWithChildren, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLayoutCurrent} from "../../hooks/layout/useLayoutCurrent";

export type SideButtonProps = {
  to: string,
  title: string
} & PropsWithChildren

export const SideButton: FC<SideButtonProps> = (
  {
    to,
    title,
    children
  }
) => {
  const {activeButton} = useLayoutCurrent()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(activeButton === title)
  }, [activeButton, setIsActive, title]);

  const onMouseOverEvent = () => {
    setIsHovered(true)
  }

  const onMouseOutEvent = () => {
    setIsHovered(false)
  }


  return (
    <Link to={to} onMouseOver={onMouseOverEvent} onMouseOut={onMouseOutEvent}>
      <div className={`${isActive?"bg-tdplprussian":isHovered?"bg-tdplbisque":"bg-white"} rounded-xl my-[1rem]`}>
        <div className="grid grid-cols-3 gap-6 p-4 items-center justify-center align-middle">
          {children}
          <p className={`col-span-2 text-[1.25rem] font-bold whitespace-nowrap
            ${isActive?"text-white":"text-tdplindigo"}
          `}>
            {title}
          </p>
        </div>
      </div>
    </Link>
  )
}