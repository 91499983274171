import LoginBackgroundImage from "../assets/image/LoginBackground.png";
import {NotFoundForm} from "../components/notFound404/NotFoundForm";

export default function NotFound404() {


  return (
    <div className="relative w-[100%] h-[100dvh] ">
      <img className="absolute w-[100%] h-[100dvh] object-fill z-0" src={LoginBackgroundImage} alt=""/>
      <div className="absolute w-[100%] h-[100dvh] flex flex-col justify-center items-center">
        <NotFoundForm/>
      </div>
    </div>
  )
}