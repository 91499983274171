import { FC, MutableRefObject, useState } from "react";
import { DateInput, DatePicker, Input } from "@nextui-org/react";
import { format, parse } from "date-fns";
import { keyDownChange } from "../../utils/selectTank/common";
import {I18nProvider} from "@react-aria/i18n";
import {getLocalTimeZone, today} from "@internationalized/date";
import {parseDate} from "@internationalized/date";

export type EventType = {
  id: string
  name: string
  value: string
}

export type IputBaseProps = {
  id: number
  inputRefs: MutableRefObject<(HTMLInputElement | null)[]>
  index: number
  insertData:(value:EventType) => void
  col:string
  value: {[key: string]: string | number | null}
  placeholder?:string
};

type BaseCellProps = {
  inputRefs:MutableRefObject<(HTMLInputElement | null)[]>
  hidden: boolean
  index: number
  setHidden: (bool:boolean)=>void
  col:string
  value: {[key: string]: string | number | null}
}

export const InputBase: FC<IputBaseProps> = ({
  id,
  inputRefs,
  index,
  insertData,
  col,
  value,
  placeholder,
}) => {
  const [hidden, setHidden] = useState<boolean>(value[col]!==null)

  return (
    hidden?
    <BaseCell hidden={hidden} setHidden={setHidden} col={col} value={value} inputRefs={inputRefs} index={index}/>
    : col.includes("Date") ?
    <BaseDateInput 
      setHidden={setHidden} 
      id={id} 
      inputRefs={inputRefs} 
      index={index} 
      insertData={insertData} 
      col={col} 
      value={value}/>
    : <BaseInput 
        placeholder={placeholder}
        hidden={hidden} 
        setHidden={setHidden} 
        id={id} 
        inputRefs={inputRefs} 
        index={index} 
        insertData={insertData} 
        col={col} 
        value={value}/>
  )
};

type BaseInputProps = {
  hidden:boolean
  setHidden: (bool:boolean)=>void
} & IputBaseProps

const BaseInput :FC<BaseInputProps> = ({
  hidden,
  id,
  value,
  col,
  placeholder,
  setHidden,
  insertData,
  index,
  inputRefs
}) => {
  return (
    <Input
    hidden={hidden}
    id={String(id)}
    defaultValue={
      value[col] !== null? 
      String(value[col])
      : ""
    }
    size="sm"
    name={col}
    type={"number"}
    placeholder={placeholder}
    radius='none'
    min={0}
    autoComplete="off"
    ref={(el) => (inputRefs.current[index] = el)}
    onBlur={(event)=>{
      if (
        !(event.target as HTMLInputElement).value || 
        (!Number.isInteger(Number((event.target as HTMLInputElement).value)) && ["stock", "feedTimes", "mortality", "harvest", "count"].includes(col) )
        ) {
        setHidden(false)
      } else {
        setHidden(true)
      }
      insertData({
        id:(event.target as HTMLInputElement).id,
        name: (event.target as HTMLInputElement).name,
        value: (event.target as HTMLInputElement).value.replace(/-/g, "")
      })
    }}
    isInvalid={!col.includes("Date") && ["stock", "feedTimes", "mortality", "harvest", "count"].includes(col) 
      && typeof value[col] === 'number' && !Number.isInteger(value[col])? true:false}
    validate={(value) => Number.isInteger(Number(value))?"none":true}
    onKeyDown={(event)=>{keyDownChange(event, index, inputRefs)}} 
    classNames={{inputWrapper:"p-0 shadow-none group-data-[focus-visible=true]:ring-opacity-0", base:"m-0 p-0", input:"m-0 p-0 outline-none"}} 
    style={{textAlign:'center'}} 
    onWheel={ event => event.currentTarget.blur() }
    />
  )
}

const BaseDateInput:FC<{setHidden:(bool:boolean)=>void}&IputBaseProps> = ({
  id,
  inputRefs,
  index,
  value,
  col,
  setHidden,
  insertData
}) => {
  return (
    <I18nProvider locale="en-CA">
    <DateInput
      autoFocus={true}
      size="sm"
      label={String(id)}
      id={String(id)}
      isInvalid={false}
      inputRef={(el) => (inputRefs.current[index] = el)}
      onKeyDown={(event)=>{keyDownChange(event, index, inputRefs)}}
      value={value[col]? parseDate(format(parse(String(value[col]), 'yyyymmdd', new Date()), 'yyyy-mm-dd')): null}
      onBlur={() => {
        if (value[col]!==null) {
          setHidden(true)
        }
      }}
      onChange={(v) => {
        const value = String(v.year)+String(v.month).padStart(2, '0')+String(v.day).padStart(2, '0')
        insertData({
          id:String(id),
          name:col,
          value: value.length===8?value:""
        })
      }}
      classNames={{
        inputWrapper:"h-0 w-full p-0 m-0 bg-white border-none shadow-none hover:bg-white focus-within:hover:bg-white",
        label:"hidden",
        base:"shadow-none", 
        input:"justify-center"}}
    />
  </I18nProvider>
  )
}

const BaseCell :FC<BaseCellProps> = ({
  hidden,
  inputRefs,
  index,
  setHidden,
  col,
  value,
}) => {
  return (
  <div className="w-full" 
    onClick={ async () => {await setHidden(false);inputRefs.current[index]?.focus()}} 
    key={col} style={{textAlign:'center'}} hidden={!hidden}>
    {value[col]!==null?col.includes("Date")
    ? format(parse(String(value[col]), 'yyyymmdd', new Date()), 'yyyy-mm-dd')
    : ["no2", "nh3"].includes(col)
    ? String(Number(value[col]).toLocaleString('en-US'))+" mg/L"
    : col==="sampleWeight"
    ? String(Number(value[col]).toLocaleString('en-US'))+" g"
    : col==="feedingAmount"
    ? String(Number(value[col]).toLocaleString('en-US'))+" kg"
    : String(Number(value[col]).toLocaleString('en-US')):null}
  </div>
  )
}

type CustomDatePickerProps = {
  label: string
  value: number | null
  dateBorderColor: string
  setValue: (startDate: number|null) => void
  warnText?: string
}

export const CustomDatePicker :FC<CustomDatePickerProps> = ({
  label,
  value,
  dateBorderColor,
  setValue,
  warnText,
}) => {
  return (
    <>
    <I18nProvider locale="en-CA">
      <DatePicker
        label={label}
        key="StartDate"
        name="StartDate"
        labelPlacement="outside"
        radius="sm"
        size="lg"
        maxValue={today(getLocalTimeZone())}
        isInvalid={false}
        value={value? parseDate(format(parse(String(value), 'yyyymmdd', new Date()), 'yyyy-mm-dd')): null}
        className={`gap-y-0 font-bold [&>div]:bg-white [&>div]:rounded-md 
        ${dateBorderColor}
        [&>div]:hover:bg-white [&>div]:gap-y-0
          [&>span]:text-base [&>span]:mt-3`}
        onChange={(value) => {
          const dateValue = String(value.year)+String(value.month).padStart(2, '0')+String(value.day).padStart(2, '0')
          if (dateValue.length === 8) {
            setValue(Number(dateValue))
          } else {
            setValue(null)
          }
        }}
      />
    </I18nProvider>
    {dateBorderColor.includes("tdplmaroon") ?
      <p className='m-0 text-sm font-semibold text-tdplmaroon'>{warnText!==undefined?warnText:"Please enter a Start Date."}</p> : <p></p>}
    </>
  )
}
