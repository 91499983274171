import {FC} from "react";
import {useLayoutCurrent} from "../../../hooks/layout/useLayoutCurrent";


export const ChartIcon: FC = () => {
  const {activeButton} = useLayoutCurrent()
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 0H4C1.8 0 0 1.8 0 4V32C0 34.2 1.8 36 4 36H32C34.2 36 36 34.2 36 32V4C36 1.8 34.2 0 32 0ZM12 28H8V14H12V28ZM20 28H16V8H20V28ZM28 28H24V20H28V28Z"
        fill={activeButton==="Chart"?"white":"#B98CA4"}
      />
    </svg>
  )
}