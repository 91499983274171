import {FC, useState} from "react";
import {Card, CardHeader} from "@chakra-ui/react";
import {ToggleButton} from "./ToggleButton";
import {CardNameLiteral, GaugeSetting} from "../../../types/apiResponse/tankInfoSettingTypes";
import {cardPossibleOptions} from "../constant";
import {GaugeSettingForm} from "./GaugeSettingForm";
import {makeTitle} from "../../../utils/tankInfo";

export type SettingCardProps = {
  cardName: CardNameLiteral
  cardType: string
  isToggled: boolean,
  gaugeSetting?: GaugeSetting
}

export const SettingCard: FC<SettingCardProps> = (
  {
    cardName,
    cardType,
    isToggled,
    gaugeSetting
  }
) => {
  const [isActive, setIsActive] = useState<boolean>(isToggled)
  const [isGauge, setIsGauge] = useState<boolean>(cardType === "gauge")
  const possibleOptions = cardPossibleOptions[cardName]

  const onToggleChange = (action: string) => {
    if (action === "off") setIsActive(false)
    else setIsActive(true)
    if (action === "gauge") setIsGauge(true)
    else setIsGauge(false)
  }


  return (
    <Card bg={'#F5F2F0'} borderColor={'#5A6C6F99'} borderWidth={0.5} my={2} shadow={0}>
      <CardHeader className="flex flex-row justify-between" pt={2} pb={1}>
        <p className="font-bold text-tdpldarkgrey">{makeTitle(cardName).title}</p>
        <ToggleButton
          cardName={cardName}
          isToggled={isActive}
          onTypeChange={onToggleChange}
          cardType={cardType}
          possibleKeys={possibleOptions}
        />
      </CardHeader>
      {
        isGauge
          ? (
            <GaugeSettingForm
              cardName={cardName}
              gaugeSetting={gaugeSetting}
            />
          )
          : <></>
      }
    </Card>
  )
}