type ColumnsType = {
  key:string
  label: string
  placeholder: string
}

export type ColumnsArrayType = ColumnsType[]

export const columns:ColumnsArrayType = [
  {
    key: "tankName",
    label: "TANK NAME",
    placeholder: ""
  },
  {
    key: "count",
    label: "COUNT*",
    placeholder: "Enter number"
  },
  {
    key: "moveDate",
    label: "MOVE DATE*",
    placeholder: ""
  },
  {
    key: "sampleWeight",
    label: "SAMPLE WEIGHT",
    placeholder: "Enter weight (g)"
  },
];

export default columns