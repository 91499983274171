import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {NextUIProvider} from "@nextui-org/react";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = extendTheme({
  fonts: {
    heading: "Noto Sans",
    body: "Noto Sans",
    html: "Noto Sans",
    mono: "Noto Sans",

  },
})
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
    <NextUIProvider>
        {/*<ChakraProvider>*/}
        <App/>
    </NextUIProvider>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();