import {ChangeEvent, FC, useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "@chakra-ui/react";
import {ToggleButton} from "./ToggleButton";
import {TankType} from "../../../types/apiResponse/tankSpecTypes";
import {useTankInfoStageStore} from "../../../store/tankInfo/useTankInfoStageStore";

export type TankSettingCardProps = {
  isToggled: boolean,
  tankSpec: TankType | undefined
}

export const TankSettingCard: FC<TankSettingCardProps> = (
  {
    isToggled,
    tankSpec
  }
) => {
  const [isActive, setIsActive] = useState<boolean>(isToggled)
  const [volume, setVolume] = useState<string>(tankSpec ? tankSpec.volume.toString() : "")
  const [radius, setRadius] = useState<string>(tankSpec ? tankSpec.radius.toString() : "")
  const [depth, setDepth] = useState<string>(tankSpec ? tankSpec.depth.toString() : "")

  const {editToggle, editTank, tank} = useTankInfoStageStore()

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => setVolume(e.target.value.replace(/[^.0-9]/g, ""))
  const handleRadiusChange = (e: ChangeEvent<HTMLInputElement>) => setRadius(e.target.value.replace(/[^.0-9]/g, ""))
  const handleDepthChange = (e: ChangeEvent<HTMLInputElement>) => setDepth(e.target.value.replace(/[^.0-9]/g, ""))
  const possibleOptions = ["on", "off"]

  const onToggleChange = (action: string) => {
    if (action === "off") setIsActive(false)
    else setIsActive(true)
    editToggle("tankVolume", action === "on")
  }

  useEffect(() => {
    if (tankSpec !== undefined
      && (Number.isInteger(volume) || Number.parseFloat(volume))
      && (Number.isInteger(depth) || Number.parseFloat(depth))
      && (Number.isInteger(radius) || Number.parseFloat(radius))
    ) {
      if (tank === undefined) editTank({...tankSpec, volume: Number(volume), depth: Number(depth), radius: Number(radius)})
      else editTank({...tank, volume: Number(volume), depth: Number(depth), radius: Number(radius)})
    }
  }, [volume, radius, depth]);

  useEffect(() => {
    if (tankSpec !== undefined) {
      setVolume(tankSpec.volume.toString())
      setDepth(tankSpec.depth.toString())
      setRadius(tankSpec.radius.toString())
    }
  }, [tankSpec]);


  return (
    <Card bg={'#F5F2F0'} borderColor={'#5A6C6F99'} borderWidth={0.5} my={2} shadow={0}>
      <CardHeader className="flex flex-row justify-between" pt={2} pb={1}>
        <p className="font-bold text-tdpldarkgrey">Tank Volume</p>
        <ToggleButton
          cardName={"tankVolume"}
          isToggled={isActive}
          onTypeChange={onToggleChange}
          cardType={isActive ? "on" : "off"}
          possibleKeys={possibleOptions}
        />

      </CardHeader>
      <CardBody py={1}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-around">
            <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">TANK VOLUME</p>
            <div className="flex flex-row justify-end">
              <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none" onChange={handleVolumeChange}
                     value={volume}/>
              <p className="ml-1">m³</p>
            </div>
          </div>
          <div className="flex flex-row justify-around gap-2">
            <div className="flex flex-row justify-between w-1/2">
              <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">RADIUS</p>
              <div className="flex flex-row justify-end">
                <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none"
                       onChange={handleRadiusChange} value={radius}/>
                <p className="ml-1">m</p>
              </div>
            </div>
            <div className="flex flex-row justify-around w-1/2">
              <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">DEPTH</p>
              <div className="flex flex-row justify-end">
                <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none"
                       onChange={handleDepthChange} value={depth}/>
                <p className="ml-1">m</p>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}