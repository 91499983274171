import {FC} from 'react'
import {
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react"
import { getUpdate } from '../../api/tank/update';
import useHomeUpdateStore from '../../store/home/useHomeUpdateStore';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { SelectUpdateTanks } from './SelectUpdateTanks';
import { useSelector } from 'react-redux';
import {AppState} from '../../store';
import * as SchoolStore from '../../store/school'
import { CustomDatePicker } from '../common/Input';
import { ModalText } from '../common/Text';

export interface SelectTanksUpdateProps {
  isOpen: boolean
  onOpenChange: () => void
  onOpen: () => void
}

export const SelectTanksUpdate: FC<SelectTanksUpdateProps> = ({
  isOpen,
  onOpenChange,
  onOpen,
}) => {
  const storedSchoolList = useSelector<AppState, SchoolStore.SchoolState>(state => state.school)
  const { 
    updateDate,
    setSchoolValues, 
    refreshSchools, 
    setDateBorderColor,
    setSelectedTanksBorderColor,
    setUpdateDate,
    dateBorderColor
  } = useHomeUpdateStore()

  const checkTanksBeforeContinue = (onClose: () => void, onOpen: () => void) => {
    setDateBorderColor()
    setSelectedTanksBorderColor()

    if (updateDate !== null && refreshSchools.length !== 0) {
      getUpdate(refreshSchools, updateDate, storedSchoolList, setSchoolValues, onClose, onOpen)
    }
  }

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"} isDismissable={true}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Update' description={ModalText('Update')}/>
            <ModalBody className='gap-1 min-h-[8rem] pr-10 pl-10'>
              <BaseDivider/>
              <SelectUpdateTanks/>
              <CustomDatePicker label='Date to Update' value={updateDate} setValue={setUpdateDate} 
              dateBorderColor={dateBorderColor} warnText='Please enter a Date to Update.'/>
            </ModalBody>
            <ModalFooter className='pb-8 pl-10 pr-10'>
              <Button className="font-bold text-white w-[6rem] mr-0 bg-tdplplum" onPress={() => {
                checkTanksBeforeContinue(onClose, onOpen)
              }}>
                Continue
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
}