import {FC} from "react";
import useChartStore from "../../store/chart/useChartStore";
import {ChartResponseBody, PropertyName, QueryPropertyName} from "../../types/apiResponse/chartTypes";
import {Card} from "@nextui-org/react";
import {useQuery} from "@tanstack/react-query";
import {getChartData} from "../../api/chart/chartComponent";
import {SingleDomainPlot} from "./plot/SingleDomainPlot";
import {makeChartYAxisTitle} from "../../utils/chart/stringUtils";
import {DoubleDomainPlot} from "./plot/DoubleDomainPlot";
import {DownloadCSV} from "./DownloadCSV";
import {makeCSVForm} from "../../utils/chart/makeCSV";
import {Spinner} from "@chakra-ui/react";

export type ChartComponentProps = {
  property: PropertyName
  schoolColors: { [id: string]: string }
}

export const ChartComponent: FC<ChartComponentProps> = (
  {
    property,
    schoolColors
  }
) => {
  const {selectedSchools, selectedTanks} = useChartStore()
  const properties: QueryPropertyName[] = property === "nh3no2" ? ["nh3", "no2"] : [property]
  const chartQueryResult = useQuery({
    queryKey: ["chart", [properties], selectedSchools],
    queryFn: async () => {
      if (selectedSchools.length === 0) return null
      else return await getChartData({
      // else return await getTestChartData({
        properties: properties,
        schoolIds: selectedSchools
      })
    },
    staleTime: 3600,
    refetchOnMount: false
  })

  const csvData = chartQueryResult.data&&selectedTanks.length!==0?makeCSVForm(chartQueryResult.data, selectedTanks):null

  return (
    <div className="flex flex-col">
      <div className="mx-1 mb-1 flex flex-row justify-between">
        <p className="font-bold text-[1.25rem]">{makeChartYAxisTitle(property)}</p>
          <DownloadCSV csvData={csvData?csvData:[]} isActive={!(!csvData)}/>
      </div>
      {
        chartQueryResult.isLoading?
          <Card shadow="none" className="h-[25dvw] max-h-[35dvh] min-w-[100%] border-[1px] border-tdpldarkgrey border-opacity-60
          flex items-center justify-center">
            <Spinner color="#B98CA4" emptyColor="gray.200" size="lg"/>
          </Card>
        :!chartQueryResult.isError && chartQueryResult.data !== undefined && chartQueryResult.data !== null
        ? <Card shadow="none" className="min-w-[100%] border-[1px] border-tdpldarkgrey border-opacity-60">
          <div className="p-0 h-[25dvw] max-h-[35dvh]">
            {
              property !== "nh3no2"
                ? chartQueryResult.data[property] !== undefined
                  ? <SingleDomainPlot
                    schoolColors={schoolColors}
                    property={property}
                    chartResponse={chartQueryResult.data[property] as ChartResponseBody}
                    timeUnit="day"
                  /> : null
                : <DoubleDomainPlot
                  timeUnit="day"
                  properties={properties}
                  chartResponses={chartQueryResult.data}
                  schoolColors={schoolColors}
                />
            }
          </div>
        </Card>
        : null
      }
    </div>
  )
}