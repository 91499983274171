import {SubmitTankArrayType} from "../../types/apiResponse/tankTypes";
import {Selection} from "@react-types/shared";
import {camListArrayType} from "../../types/props/live";

export function cameraListUpdater(
  index: number,
  selectedTank: SubmitTankArrayType,
  cameraList: camListArrayType,
  setCameraList: (camList: camListArrayType) => void
) {
  const currentCameraList = [...cameraList]
  const tankName = selectedTank[0]?.tankName
  const tankId = selectedTank[0]?.tankId
  currentCameraList[index] = {tankName: tankName, tankId: tankId, cameraPosition: null}
  if (tankName && tankId) {
    setCameraList(currentCameraList)
  }
}

export function removeCamera(
  index: number,
  cameraList:camListArrayType,
  setCameraList: (camList: camListArrayType) => void
) {
    setCameraList(cameraList.map((item, i) => {
      if (index === i) {
        return {tankName: null, tankId: null, cameraPosition:null}
      } else {
        return item
      }
    }))
}

export function cameraPosUpdater(
  index: number,
  value: Selection,
  cameraList: camListArrayType,
  setCameraList: (camList: camListArrayType) => void
) {
  const currentCameraList = [...cameraList]
  const pos = String(Array.from(value)[0])
  const tankName = currentCameraList[index].tankName
  const tankId = currentCameraList[index].tankId
  currentCameraList[index] = {tankName: tankName, tankId: tankId, cameraPosition: pos}
  setCameraList(currentCameraList)
}