import { Avatar, Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import { LightIcon } from "./svg/light";
import { useLocation } from 'react-router-dom';
import { HelperText } from "./HelperText";

export const Help = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const location = useLocation()
  return (
    <div className="mx-2">
      <Avatar className={`${isOpen?"contrast-50":""} w-[1.5rem] h-[1.5rem] bg-white`} fallback={LightIcon()} onClick={() => onOpen()}/>
      <Modal 
        placement="top"
        classNames={{wrapper:"justify-end mt-5", base:"border border-tdpllightgrey border-opacity-60"}}
        shouldBlockScroll={false}
        backdrop="transparent"
        size="xs"
        isOpen={isOpen} 
        onClose={onClose}
        hideCloseButton={true}
        motionProps={{
          variants: {
            enter: {y: 0, opacity: 1, 
              transition: {duration: 0.3, ease: "easeOut"},
            },
            exit: {y: -20, opacity: 0,
              transition: {duration: 0.2, ease: "easeIn"},
            },
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <HelperText page={location.pathname} onClose={onClose}/>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}