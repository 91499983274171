import {FC} from "react"
import {useLayoutCurrent} from "../../../hooks/layout/useLayoutCurrent";

export const LiveIcon: FC = () => {
  const {activeButton} = useLayoutCurrent()

  return (
    <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 9V2C28 0.9 27.1 0 26 0H2C0.9 0 0 0.9 0 2V22C0 23.1 0.9 24 2 24H26C27.1 24 28 23.1 28 22V15L36 23V1L28 9Z"
        fill={activeButton==="Live"?"white":"#B98CA4"}/>
    </svg>

  )
}