import {FC} from "react";
import {useLayoutCurrent} from "../../../hooks/layout/useLayoutCurrent";

export const HomeIcon: FC = (
) => {
  const {activeButton} = useLayoutCurrent()
  return (
    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0L0 12V36H10V22H22V36H32V12L16 0Z" fill={activeButton==="Home"?"white":"#B98CA4"}/>
    </svg>

  )
}