import {SyntheticEvent} from "react";
import {PropertyName} from "../../types/apiResponse/chartTypes";

export const onSingleDomainClick = (
  property: PropertyName,
  setDate: (date: Date) => void
) => {
  return property !== "averageWeight"
    ? undefined
    : (p: any, e: SyntheticEvent) => {
      if (p.points === undefined || p.points.length === 0) return
      e.preventDefault()
      e.stopPropagation()
      setDate(p.points[0].data.x)
    }
}