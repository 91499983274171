import { SchoolsType } from "../../types/apiResponse/selectSchoolsTypes"
import {getData} from "../../utils"

export const getSchools = async(
  batchId: string,
) => {
  const result = await getData<SchoolsType>(`/school-diagram?batch-id=${batchId}`)
    .then((response) => {
      return response
    })
    .catch(() => {
      console.log("Update API Error")
      alert("ERROR!")
      return null
    })
  return result
}
