import {Dispatch} from "react"
import {TankValuesArrayType} from "../../types/apiResponse/tankTypes"
import {postData} from "../../utils"
import {UnknownAction} from "redux"
import {reloadARTRList, reloadBatchList, reloadSchool} from "../../utils/loadStorage"

export type editBatchType = {
  batchName: string | null
  startDate: number | null
  filledTanks: string[]
  emptyTanks: TankValuesArrayType
}

export const postEditBatch = async (
  editBatchData: editBatchType,
  batchId: string,
  dispatch: Dispatch<UnknownAction>,
  onClose: () => void,
) => {
  await postData<string[]>(`/batch/edit/${batchId}`, editBatchData)
    .then((response) => {
      reloadARTRList(dispatch)
      reloadBatchList(dispatch)
      reloadSchool(response, dispatch)
      onClose()
    })
    .catch(() => {
      console.log("Edit Batch API Error")
      alert("ERROR!!")
    })
}