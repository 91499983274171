import {FC} from "react";

export type UndoIconProps = {
  isActive: boolean
}

export const UndoIcon: FC<UndoIconProps> = (
  {
    isActive
  }
) => {

  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={isActive?"group-hover:fill-tdpldark":""}
        d="M5.22565 17.968C4.88456 17.968 4.59844 17.8525 4.3673 17.6213C4.13696 17.391 4.02179 17.1053 4.02179 16.7642C4.02179 16.4231 4.13696 16.1374 4.3673 15.907C4.59844 15.6759 4.88456 15.5603 5.22565 15.5603H12.5691C13.8332 15.5603 14.9315 15.159 15.8641 14.3565C16.7975 13.5539 17.2642 12.5507 17.2642 11.3468C17.2642 10.143 16.7975 9.13977 15.8641 8.3372C14.9315 7.53464 13.8332 7.13335 12.5691 7.13335H4.98488L7.2722 9.42067C7.4929 9.64138 7.60326 9.92228 7.60326 10.2634C7.60326 10.6045 7.4929 10.8854 7.2722 11.1061C7.05149 11.3268 6.77059 11.4371 6.4295 11.4371C6.08841 11.4371 5.80751 11.3268 5.5868 11.1061L1.25293 6.7722C1.13255 6.65181 1.04708 6.52139 0.996514 6.38094C0.946754 6.24049 0.921875 6.09001 0.921875 5.9295C0.921875 5.76899 0.946754 5.6185 0.996514 5.47805C1.04708 5.3376 1.13255 5.20719 1.25293 5.0868L5.5868 0.752934C5.80751 0.532228 6.08841 0.421875 6.4295 0.421875C6.77059 0.421875 7.05149 0.532228 7.2722 0.752934C7.4929 0.97364 7.60326 1.25454 7.60326 1.59563C7.60326 1.93672 7.4929 2.21762 7.2722 2.43833L4.98488 4.72565H12.5691C14.5154 4.72565 16.1859 5.35767 17.5808 6.62171C18.9748 7.88576 19.6719 9.4608 19.6719 11.3468C19.6719 13.2329 18.9748 14.8079 17.5808 16.072C16.1859 17.336 14.5154 17.968 12.5691 17.968H5.22565Z"
        fill="#355968" fillOpacity={isActive?"1":"0.5"}/>
    </svg>

  )
}