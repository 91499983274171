import {
  GetChartQueryType,
  GetChartResponseType,
  GetHistogramQueryType,
  GetHistogramResponseType
} from "../../types/apiResponse/chartTypes";
import {getData} from "../../utils";

export const getChartData = async (chartQuery: GetChartQueryType): Promise<GetChartResponseType> => {
  const queryParams: string[] = []
  const propertyQueryString = chartQuery.properties?"property="+chartQuery.properties.join("&property="):""
  const schoolIdQueryString = chartQuery.schoolIds?"school-id="+chartQuery.schoolIds.join("&school-id="):""
  if (propertyQueryString) queryParams.push(propertyQueryString)
  if (schoolIdQueryString) queryParams.push(schoolIdQueryString)

  return await getData<GetChartResponseType>(`chart?${queryParams.join('&')}`)
}


export const getHistogramData = async (histogramQuery: GetHistogramQueryType): Promise<GetHistogramResponseType> => {
  const queryParams : string[] = []
  const schoolIdQueryString = histogramQuery.schoolIds?"school-id="+histogramQuery.schoolIds.join("&school-id="):""
  const dateQueryString = histogramQuery.date?`date=${histogramQuery.date}`:""

  if (schoolIdQueryString) queryParams.push(schoolIdQueryString)
  if (dateQueryString) queryParams.push(dateQueryString)

  return await getData<GetHistogramResponseType>(`weight-histogram?${queryParams.join('&')}`)
}