import { Card, Chip, ModalContent, useDisclosure } from "@nextui-org/react"
import SelectTank from "../../pages/SelectTank"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes"
import useHomeUpdateStore from "../../store/home/useHomeUpdateStore"
import { useSelector } from 'react-redux'
import {AppState} from '../../store'
import * as BatchStore from "../../store/batch"
import * as TankRelationStore from "../../store/tankRelation"
import { BaseModal } from "../common/Modal"


export const SelectUpdateTanks = () => {
  const {isOpen: isOpenTanks, onOpen: onOpenTanks, onOpenChange: onOpenChangeTanks} = useDisclosure()
  const {selectTanksBorderColor, setSelectedTanks, selectedTanks } = useHomeUpdateStore()

  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)
  const batchIdList = Object.keys(storedBatchList).filter(batchId => batchId !== "$empty")
  const schoolIdList = useSelector<AppState, TankRelationStore.TRState>(state => state.tankRelation)
  return (
    <>
    <div className='mt-3 font-bold'>Tanks to Update</div>
      <Card
        className={`flex flex-row flex-wrap justify-start p-1 ${selectTanksBorderColor} border-opacity-60 min-h-[3rem]`}
        radius='sm' shadow="sm" isPressable onPress={onOpenTanks}>
        <BaseModal isOpen={isOpenTanks} onOpenChange={onOpenChangeTanks} size={"2xl"}>
          <ModalContent>
            {(onCloseTanks) => (
              <SelectTank
                submitTankArray={(tankSelectedList: SubmitTankArrayType) => setSelectedTanks(tankSelectedList, schoolIdList)}
                batchIdList={batchIdList}
                onClose={onCloseTanks}
                initialTankArray={selectedTanks}
              />
            )}
          </ModalContent>
        </BaseModal>
        {selectedTanks.map((item, index) => (
          <Chip key={index} radius="sm" classNames={{
            base: `border-small border-tdpldarkgrey bg-${item.batchName === "$empty" ? "tdpllightgrey" : "tdplsky"} border-opacity-60 m-1 p-0`,
            content: "font-bold"
          }}>{item.tankName}</Chip>
        ))}
      </Card>
      {selectTanksBorderColor && selectTanksBorderColor.includes("tdplmaroon") ?
        <p className='m-0 text-sm font-semibold text-tdplmaroon'>Please select Tanks to Update.</p> :
        <p></p>}
    </>
  )
}