import {HistComponent} from "./HistComponent";
import {ChartComponent} from "./ChartComponent";
import {FC} from "react";
import {useAvgDistDateStore} from "../../store/chart/useAvgDistDateStore";
import {Card} from "@nextui-org/react";
import {Spinner} from "@chakra-ui/react";

export type AverageWeightChartProps = {
  schoolColors: { [id: string]: string }
}

export const AverageWeightChart: FC<AverageWeightChartProps> = (
  {
    schoolColors
  }
) => {
  const {date} = useAvgDistDateStore()

  return (
    <div className="col-span-2 grid grid-cols-2 gap-[2rem]">
      <ChartComponent property={"averageWeight"} schoolColors={schoolColors}/>
      {
        date === null
          ?
          <div className="flex flex-col">
            <div className="mx-1 mb-1 flex flex-row justify-between">
              <p className="font-bold text-[1.25rem]">Weight Histogram</p>
            </div>
            <Card shadow="none"
                  className="h-[25dvw] max-h-[35dvh] min-w-[100%] border-[1px] border-tdpldarkgrey border-opacity-60
                  flex items-center justify-center">
              <Spinner color="#B98CA4" emptyColor="gray.200" size="lg"/>
            </Card>
          </div>
          : <HistComponent date={date} schoolColors={schoolColors}/>
      }
    </div>
  )
}