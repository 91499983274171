import {FC} from "react";

export const AlertIcon: FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6.00002C0 7.47335 0.606667 8.80002 1.57333 9.76002L0 11.3334H4V7.33335L2.50667 8.82669C1.78667 8.10002 1.33333 7.10669 1.33333 6.00002C1.33333 4.26002 2.44667 2.78002 4 2.23335V0.840021C1.7 1.43335 0 3.51335 0 6.00002ZM5.33333 9.33335H6.66667V8.00002H5.33333V9.33335ZM12 0.666687H8V4.66669L9.49333 3.17335C10.2133 3.90002 10.6667 4.89335 10.6667 6.00002C10.6667 7.74002 9.55333 9.22002 8 9.76669V11.16C10.3 10.5667 12 8.48669 12 6.00002C12 4.52669 11.3933 3.20002 10.4267 2.24002L12 0.666687ZM5.33333 6.66669H6.66667V2.66669H5.33333V6.66669Z"
        fill="#355968"/>
    </svg>

  )
}