import {FC, useEffect} from 'react'
import {Button, useDisclosure} from "@nextui-org/react"
import {SelectTanksEditBatch} from './SelectTanksEditBatch';
import {useSelector} from 'react-redux';
import {AppState} from '../../store';
import * as BatchStore from "../../store/batch"
import useHomeEditBatchStore from '../../store/home/useHomeEditBatchStore';
import { InitialInfo } from './InitialInfo';
import { EditIcon } from './svg/EditIcon';

export interface EditBatchProps {
  batchId: string
  baseBatchName: string
}

export const EditBatch: FC<EditBatchProps> = ({batchId, baseBatchName}) => {
  const {isOpen: isOpenSelect, onOpen: onOpenSelect, onOpenChange: onOpenChangeSelect} = useDisclosure();
  const {isOpen: isOpenInfo, onOpen: onOpenInfo, onOpenChange: onOpenChangeInfo} = useDisclosure()
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)
  const baseDate = storedBatchList[batchId].startDate
  const { setBaseData, reset, startDate, batchName } = useHomeEditBatchStore()

  useEffect(() => {
    if (isOpenSelect && baseDate && batchId && baseBatchName && (!startDate && !batchName)) {
      setBaseData(baseDate, batchId, baseBatchName)
    }
  }, [setBaseData, baseDate, batchId, baseBatchName, isOpenSelect, startDate, batchName])
  
  useEffect(() => {
    if (!isOpenSelect && !isOpenInfo) {
      reset()
    }
  }, [isOpenSelect, reset, isOpenInfo])
  return (
    <div>
      <Button className="bg-tdplbisque hover:bg-tdpllightgrey group" isIconOnly onPress={onOpenSelect}>
        <EditIcon/>
        </Button>
      <SelectTanksEditBatch
        isOpen={isOpenSelect}
        onOpenChange={onOpenChangeSelect}
        onOpen={onOpenInfo}
      />
      <InitialInfo
        onOpenChange={onOpenChangeInfo}
        isOpen={isOpenInfo}
        isBack={onOpenSelect}
      />
    </div>
  )
}