import { DropDownContent } from "./DropDown";
import cross from '../../assets/icons/cross.png'
import useCustomizeLiveStore from "../../store/live/useCustomizeLiveStore";

export const SetGrid= () => {
  const { selectedCol, selectedRow, setSelectedCol, setSelectedRow} = useCustomizeLiveStore()
  return (
    <div className='flex flex-warp gap4'>
      <DropDownContent key="Rows" title="Rows" num={3} selectedValue={selectedRow} setSelectedValue={setSelectedRow}/>
      <div className="relative flex items-end">
        <img className='m-3 w-[1rem] h-[1rem]' src={cross} alt=""/>
      </div>
      <DropDownContent key="Columns" title="Columns" num={4} selectedValue={selectedCol} setSelectedValue={setSelectedCol}/>
    </div>
  )
};
