import {GetChartResponseType, SelectedTanksType} from "../../types/apiResponse/chartTypes";

export const makeCSVForm = (chartQueryResult: GetChartResponseType, selectedTanks: SelectedTanksType) => {
  const resultArray: object[] = []
  Object.entries(chartQueryResult).forEach(propertyEntry => {
    const property = propertyEntry[0]
    Object.entries(propertyEntry[1].value).forEach(schoolEntry=>{
      const schoolId = schoolEntry[0]
      const values = schoolEntry[1]
      const filteredSelectedTanks = selectedTanks.filter((val) => val.schoolId===schoolId)
      if (filteredSelectedTanks.length === 0) return
      const tankName = selectedTanks.filter((val) => val.schoolId===schoolId)[0].tankName

      values.forEach(({x, y}) => resultArray.push({
          "property": property,
          "value": y,
          "date": x,
          "tank": tankName
        }))
    })
  })
  return resultArray
}
