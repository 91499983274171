import {CardNameLiteral} from "../types/apiResponse/tankInfoSettingTypes";

const cardNameTitle: {[cardName in CardNameLiteral]: {title: string, subTitle: string|undefined}} = {
  nh3: {
    title: "NH3",
    subTitle: "Ammonia"
  },
  tankVolume: {
    title: "Tank Volume",
    subTitle: undefined
  },
  memo: {
    title: "Memo",
    subTitle: undefined
  },
  no2: {
    title: "NO2",
    subTitle: "Nitrite"
  },
  do: {
    title: "Dissolved Oxygen",
    subTitle: undefined
  },
  ph: {
    title: "pH",
    subTitle: undefined
  },
  waterLevel: {
    title: "Water Level",
    subTitle: undefined
  },
  temperature: {
    title: "Temperature",
    subTitle: undefined
  },
  feeding: {
    title: "Feeding",
    subTitle: undefined
  },
  count: {
    title: "Count",
    subTitle: undefined
  },
  sampleWeight: {
    title: "Sample Weight",
    subTitle: undefined
  },
  averageWeight: {
    title: "Average Weight",
    subTitle: undefined
  },
  weightDistribution: {
    title: "Weight Distribution",
    subTitle: undefined
  },
  stockingDensity: {
    title: "Stocking Density",
    subTitle: undefined
  },
  biomass: {
    title: "Biomass",
    subTitle: undefined
  },
  kFactor: {
    title: "K",
    subTitle: "Condition Factor"
  },
  fcr: {
    title: "FCR",
    subTitle: "Feed Conversion Ratio"
  }
}

export function makeTitle(cardName: CardNameLiteral) {
  return cardNameTitle[cardName]
}