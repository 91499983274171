type ColumnsType = {
  key: string;
  label: string;
  placeholder: string;
};

export type ColumnsArrayType = ColumnsType[];

export const columns: ColumnsArrayType = [
  {
    key: "tankName",
    label: "TANK NAME",
    placeholder: "",
  },
  {
    key: "count",
    label: "STOCK*",
    placeholder: "Enter number",
  },
  {
    key: "sampleWeight",
    label: "SAMPLE WEIGHT",
    placeholder: "Enter weight (g)",
  },
  {
    key: "moveDate",
    label: "MOVE DATE",
    placeholder: "",
  },
  {
    key: "resultingCount",
    label: "RESULTING COUNT",
    placeholder: "",
  },
];

export default columns;
