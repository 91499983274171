import {Button, useDisclosure} from "@nextui-org/react";
import {getBatchById, getSchoolById, getUndoable, postUndo} from "../../api/home";
import {undoResponseType} from "../../types/apiResponse/undoTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {BatchState} from "../../store/batch";
import {SchoolState} from "../../store/school";
import {
  addBatch,
  addSchool, delBatch,
  delSchool,
  reloadARTRList,
  updateBatch,
  updateSchool
} from "../../utils/loadStorage";
import {schoolResponseType} from "../../types/apiResponse/schoolTypes";
import {batchResponseType} from "../../types/apiResponse/batchTypes";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import {UndoIcon} from "./svg/UndoIcon";
import {UndoCheckAlert} from "./UndoCheckAlert";
import {UndoImpossibleAlert} from "./UndoImpossibleAlert";
import {useEffect} from "react";

export const UndoButton = () => {
  const storedBatchList = useSelector<AppState, BatchState>(state => state.batch)
  const storedSchoolList = useSelector<AppState, SchoolState>(state => state.school)
  const {isOpen: isOpenCheck, onOpen: onOpenCheck, onClose: onCloseCheck} = useDisclosure()
  const {isOpen: isOpenImpossible, onOpen: onOpenImpossible, onClose: onCloseImpossible} = useDisclosure()
  const dispatch = useDispatch()

  const {data: undoAble, refetch: undoRefetch, isStale} = useQuery(
    {
      queryKey: ["undoable"],
      queryFn: async () => {
        return await getUndoable()
      },
      staleTime: 0
    }
  )

  const undoReload = (undoResponse: undoResponseType) => {
    if (undoResponse.school !== undefined) {
      undoResponse.school.forEach((schoolId) => {
        getSchoolById(schoolId).then((schoolResponse: schoolResponseType) => {
          if (schoolId in Object.keys(storedSchoolList)) updateSchool(schoolResponse, dispatch)
          else addSchool(schoolResponse, dispatch)
        }).catch((reason: unknown) => {
          if (reason instanceof AxiosError) {
            if (reason.response?.status === 404) delSchool(schoolId, dispatch)
            else throw reason
          } else throw reason
        })
      })
    }
    if (undoResponse.batch !== undefined) {
      undoResponse.batch.forEach((batchId) => {
        getBatchById(batchId).then((batchResponse: batchResponseType) => {
          if (batchId in Object.keys(storedBatchList)) updateBatch(batchResponse, dispatch)
          else addBatch(batchResponse, dispatch)
        }).catch((reason: unknown) => {
          console.log(reason instanceof AxiosError)
          if (reason instanceof AxiosError) {
            if (reason.response?.status === 404) delBatch(batchId, dispatch)
          }
          throw reason
        })
      })
    }
    if (undoResponse.activeRelation !== undefined) reloadARTRList(dispatch)
    undoRefetch().then()
  }

  useEffect(() => {
    if (isStale) undoRefetch().then(() => {
      console.log("refetch occurred")
    })
  }, [isStale]);
  return (
    <>
      <Button
        className={"bg-tdplbisque group" + `${undoAble === true ? " hover:bg-tdpllightgrey" : 'bg-opacity-60'}`}
        isIconOnly
        onClick={undoAble === null || undoAble === undefined ? onOpenImpossible : undoAble ? onOpenCheck : onOpenImpossible}
        // disabled={undoAble===null||undoAble===undefined?true:!undoAble}
      >
        <UndoIcon isActive={undoAble === null || undoAble === undefined ? false : undoAble}/>
      </Button>
      <UndoCheckAlert
        title="Undo the previous action."
        isOpenAlert={isOpenCheck}
        onCloseAlert={onCloseCheck}
        onContinue={() => postUndo(undoReload)}
      >
        <p>This action cannot be reversed.</p>
      </UndoCheckAlert>
      <UndoImpossibleAlert
        title="Exceeded the number of undo operations."
        isOpenAlert={isOpenImpossible}
        onCloseAlert={onCloseImpossible}
      >
        <p>You can only undo a total of five actions.</p>
      </UndoImpossibleAlert>

    </>
  )
}
