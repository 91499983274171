import {FC, PropsWithChildren, useEffect, useState} from "react";
import {useAuthStore} from "../../store/auth/useAuthStore";
import {Navigate} from "react-router-dom";
import {introspect} from "../../api/axiosAuthorization";

export const Authorization: FC<PropsWithChildren> = ({children}) => {
  const {refreshToken, token, logout} = useAuthStore()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(token!==null)

  useEffect(() => {
      introspect("access", setIsAuthorized)
      introspect("refresh", (isValid)=> {
        if (!isValid) logout()
      })
  }, []);


  useEffect(() => {
    if (token === null) {
      if (refreshToken === null) setIsAuthorized(false)
      else introspect("refresh", setIsAuthorized)
    }
    else setIsAuthorized(true)
  }, [token, refreshToken]);


  if (isAuthorized) return <>{children}</>
  else return <Navigate to="/login"/>
}