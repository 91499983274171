import * as T from './types'

const initialState: T.TankState = {}

export const reducer = (state: T.TankState = initialState, action: T.TankActions) => {
  switch (action.type) {
    case '@tank/setTank':
      return action.payload
  }
  return state
}