import {CardNameLiteral} from "../../types/apiResponse/tankInfoSettingTypes";
import {useQuery} from "@tanstack/react-query";
import {
  getTankInfoDistribution,
  getTankInfoGauge,
  getTankInfoGraph, getTankInfoMemo,
  getTankInfoSpec
} from "../../api/tankInfo/cardDetail";
import {useEffect} from "react";
// import {
//   getTestTankInfoDist,
//   getTestTankInfoGauge,
//   getTestTankInfoGraph,
//   getTestTankInfoSpec
// } from "../../api/test/tankInfoTest";

export const useTankInfoGauge = (
  tankId: string,
  cardName: CardNameLiteral
) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "value", "gauge", tankId, cardName],
    queryFn: async () => {
      if (tankId === null) return null
      return await getTankInfoGauge(tankId, [cardName],)
      // return getTestTankInfoGauge(tankId, [cardName],)
    },
    staleTime: 1000 * 60 * 60
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(() => {
      })
    }
  }, [queryResult.isStale]);

  return queryResult.data
}

export const useTankInfoSpec = (
  tankId: string,
  cardName: CardNameLiteral
) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "value", "spec", tankId, cardName],
    queryFn: async () => {
      if (tankId === null) return null
      return await getTankInfoSpec(tankId, [cardName],)
      // return getTestTankInfoSpec(tankId, [cardName],)
    },
    staleTime: 1000 * 60 * 60
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(() => {
      })
    }
  }, [queryResult.isStale]);

  return queryResult.data
}

export const useTankInfoGraph = (
  tankId: string,
  cardName: CardNameLiteral
) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "value", "graph", tankId, cardName],
    queryFn: async () => {
      if (tankId === null) return null
      return await getTankInfoGraph(tankId, [cardName],)
      // return await getTestTankInfoGraph(tankId, [cardName],)
    },
    staleTime: 1000 * 60 * 60
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(() => {
      })
    }
  }, [queryResult.isStale]);

  return queryResult.data
}

export const useTankInfoDistribution = (
  tankId: string,
  cardName: CardNameLiteral
) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "value", "distribution", tankId, cardName],
    queryFn: async () => {
      if (tankId === null) return null
      return await getTankInfoDistribution(tankId, [cardName])
      // return await getTestTankInfoDist(tankId, [cardName])
    },
    staleTime: 1000 * 60 * 60
  })
  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(() => {
      })
    }
  }, [queryResult.isStale]);

  return queryResult.data
}

export const useTankInfoMemo = (
  tankId: string
) => {
  const queryResult = useQuery({
    queryKey: ["tankInfo", "value", "memo", tankId],
    queryFn: async () => {
      if (tankId === null) return null
      return await getTankInfoMemo(tankId)
    },
    staleTime: 1000 * 60 * 60
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(() => {
      })
    }
  }, [queryResult.isStale]);

  return queryResult.data
}