import {FC, useEffect} from "react";
import useChartStore from "../../store/chart/useChartStore";

import {ChartComponent} from "./ChartComponent";
import {useColorBySchool} from "../../hooks/chart/useColorBySchool";
import {AverageWeightChart} from "./AverageWeightChart";

export type ChartContainerProps = {
  showState: boolean
}

export const ChartContainer: FC<ChartContainerProps> = (
  {
    showState
  }
) => {
  const {properties, selectedSchools} = useChartStore()
  const {colorForEachSchool, setColoredSchools} = useColorBySchool(selectedSchools)

  useEffect(() => {
    setColoredSchools(selectedSchools)
  }, [selectedSchools, setColoredSchools]);

  return (
    <div className="grid grid-cols-2 gap-[2rem] mt-[2rem]">
      {
        showState
          ? properties.map((property, index) => (
            property === "averageWeight"
              ? <AverageWeightChart key={index} schoolColors={colorForEachSchool}/>
              : <ChartComponent key={index} property={property} schoolColors={colorForEachSchool}/>
          ))
          : null
      }
    </div>
  )
}
