import {FC, useEffect} from "react";
import Header from "../components/layouts/Header";
import SideBar from "../components/layouts/SideBar";
import Footer from "../components/layouts/Footer";
import {Outlet} from "react-router-dom";
import {reloadARTRList, reloadBatchList, reloadSchool, reloadTankList} from "../utils/loadStorage";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";
import * as ARStore from "../store/activeRelation";
import {ARInfoBase} from "../store/activeRelation";

const Layout: FC = () => {

  const dispatch = useDispatch()
  const storedARList = useSelector<AppState, ARStore.ARState>(state => state.activeRelation)

  useEffect(() => {
    reloadBatchList(dispatch)
    reloadTankList(dispatch)
    reloadARTRList(dispatch)

  }, [dispatch]);

  useEffect(() => {
    const schoolIdList: string[] = []
    Object.keys(storedARList).forEach((batchId) => {
      storedARList[batchId].forEach((info: ARInfoBase) => {
        if (!!info.schoolId) {
          schoolIdList.push(info.schoolId)
        }
      })
    })
    reloadSchool(schoolIdList, dispatch)
  }, [storedARList, dispatch]);

  return (
    <>
      <Header/>
      <div className="flex flex-row">
        <SideBar/>
        <main className="bg-tdplbisque bg-repeat-y w-screen min-h-[100lvh]">
          <Outlet/>
        </main>
      </div>
      <Footer/>
    </>
  )
}

export default Layout