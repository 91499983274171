import { FC } from "react"
import { Button, useDisclosure } from "@nextui-org/react"
import { CommonAlert } from "../common/Alert"
import useHomeEditBatchStore from "../../store/home/useHomeEditBatchStore"
import { postEditBatch } from "../../api/batch/editBatch"
import { useDispatch } from "react-redux"
import { useQueryClient } from "@tanstack/react-query"

type SelectTanksEditBatchButtonProps = {
  onOpen: () => void
  onClose: () => void
}

export const SelectTanksEditBatchButton:FC<SelectTanksEditBatchButtonProps> = ({
  onOpen,
  onClose
}) => {
  const {setBatchNameBorderColor, setStartDateBorderColor, setSelectedTanksBorderColor, editBatchData, batchId, batchName, startDate, selectedTanks, selectedEmptyTanks, nameDuplicated} = useHomeEditBatchStore()
  const {isOpen: isOpenNotEmptyAlert, onOpen: onOpenEmptyAlert, onClose: onCloseNotEmptyAlert} = useDisclosure()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const saveJob = (onClose: () => void) => {
    if (editBatchData !== undefined) {
      if (batchId) {
        postEditBatch(editBatchData, batchId, dispatch, onClose).then( async () => {
          await queryClient.invalidateQueries({queryKey: ["undoable"]})
        })
      } else {
        alert("DataType Error")
      }
    }
  }

  const checkTanksBeforeSave = (onClose: () => void, onOpenEmptyAlert: () => void) => {
    setBatchNameBorderColor()
    setStartDateBorderColor()
    setSelectedTanksBorderColor()

    if (batchName !== null && !batchName.includes("$") && startDate !== null && selectedTanks.length !== 0 && !nameDuplicated) {
      if (selectedEmptyTanks.length !== 0) {
        onOpenEmptyAlert()
      } else {
        saveJob(onClose)
      }
    }
  }

  const finishJob = (onClose: () => void, onOpen: () => void) => {
      onClose()
      onOpen()
  }

  return (
    <>
      <Button className="font-bold text-white w-[6rem] mr-0 bg-tdplplum" onPress={() => {
        checkTanksBeforeSave(onClose, onOpenEmptyAlert)
      }}>
        Save
      </Button>
      <CommonAlert key="EmptyTanksAlert" isOpenAlert={isOpenNotEmptyAlert} onCloseAlert={onCloseNotEmptyAlert} onOpen={onOpen} onClose={onClose}
        continueJob={finishJob}
      title='Empty tanks have been added!'
      children={
        <>
          <p>Some tanks you selected are empty,</p>
          <p>do you want to add more fish?</p>
        </>
      }/>
    </>
  )
}