import {Chip} from "@nextui-org/react";
import React, {FC, HTMLProps} from "react";
import {SubmitTankType} from "../../types/apiResponse/tankTypes";

export type TankChipProps = {
  submitTank: SubmitTankType
  isActivated: boolean
  onTankChipClick: (tankId: string) => void
} & HTMLProps<HTMLDivElement>

export const TankChip: FC<TankChipProps> = (
  {
    submitTank,
    isActivated,
    onTankChipClick,
    ...props
  }
) => {
  return (
    <div {...props}>
      <Chip
        radius="sm"
        classNames={{
          base: `p-0 border-opacity-60 my-2 mx-[5px] ${
            isActivated
              ? "bg-tdplplum border-tdplhibiscus border-[2px]"
              : submitTank.batchId !== "$empty"
                ? "bg-tdplsky border-tdpldarkgrey border-[1px]"
                : "bg-tdpllightgrey border-tdpldarkgrey border-[1px]"
          }`,
          content: "font-semibold fit-content text-[1rem]"
        }}
        onClick={() => onTankChipClick(submitTank.tankId)}
        >
        {submitTank.tankName}
      </Chip>
    </div>
  )
}

export default TankChip