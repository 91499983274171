import {Dispatch} from "react"
import {UnknownAction} from "redux"
import {TankValuesArrayType} from "../../types/apiResponse/tankTypes"
import {postData} from "../../utils"
import {reloadARTRList, reloadBatchList, reloadSchool} from "../../utils/loadStorage"

export const postStock = async (tankInfo: TankValuesArrayType, dispatch: Dispatch<UnknownAction>, onClose: () => void) => {
  await postData<string[]>('/stock', tankInfo)
    .then((response) => {
      reloadSchool(response, dispatch)
      reloadBatchList(dispatch)
      reloadARTRList(dispatch)
      onClose()
    })
    .catch(() => {
      console.log("Stock API Error")
      alert("ERROR!!")
    })
}