import {FC, useEffect, useState} from "react";

import {DrawerBody, DrawerContent, DrawerFooter, DrawerHeader} from "@chakra-ui/react";
import {Card, CardBody} from "@chakra-ui/react";
import {Button} from "@nextui-org/react";
import {useTankInfoStageStore} from "../../store/tankInfo/useTankInfoStageStore";

import {SettingCard} from "./cards/SettingCard";
import {TankSettingCard} from "./cards/TankSettingCard";
import {useToggleSettings} from "../../hooks/tankInfo/useToggleSettings";
import {useDetailSettings, useTankSpec} from "../../hooks/tankInfo/useDetailSettings";
import {totalSettingMutation} from "../../api/tankInfo/typeSettingChange";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {TankNameInput} from "./TankNameInput";
import {AppState} from "../../store";
import {TankState} from "../../store/tank";
import { Bang } from "./svg/Bang";
import { useDispatch, useSelector } from "react-redux";
import { reloadTankList } from "../../utils/loadStorage";
import {useTankInfoModalStore} from "../../store/tankInfo/useTankInfoModalStore";

export type SettingDrawerProps = {
  tankId: string
  tankName: string | null
  onClose: () => void
}

export const SettingDrawer: FC<SettingDrawerProps> = (
  {
    tankId,
    onClose
  }
) => {
  const {clear, init, callbackQueryKeys, tank, gaugeSettings, typeSettings, toggleSettings: tgSettings} = useTankInfoStageStore()
  const {setTank} = useTankInfoModalStore()
  const [nameStatus, setNameStatus] = useState<boolean>(false)
  const toggleSettings = useToggleSettings().data
  const waterDetailSettings = useDetailSettings("Water").data
  const fishDetailSettings = useDetailSettings("Fish").data
  const {data:tankSpec, refetch:tankRefetch} = useTankSpec(tankId)
  const queryClient = useQueryClient()
  const tankState = useSelector<AppState, TankState>(state => state.tank)
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setNameStatus(false)
  }, [tank])

  useEffect(() => {
    let saveActiveSwitch: boolean = false
    if (tank !== undefined) saveActiveSwitch = true
    else if (JSON.stringify(gaugeSettings) !== "{}") saveActiveSwitch = true
    else if (JSON.stringify(typeSettings) !== "{}") saveActiveSwitch = true
    else if (JSON.stringify(tgSettings) !== "{}") saveActiveSwitch = true
    if (saveActiveSwitch) setIsSaveActive(true)
    else setIsSaveActive(false)
  }, [tank, gaugeSettings, typeSettings, tgSettings]);

  useEffect(() => {
    clear()
    if (toggleSettings !== undefined && waterDetailSettings && fishDetailSettings && tankSpec) {
      init(toggleSettings, waterDetailSettings, fishDetailSettings, tankSpec)
    }
  }, [toggleSettings, waterDetailSettings, fishDetailSettings, tankSpec, clear, init]);

  const {mutate} = useMutation({
    mutationFn: async () => await totalSettingMutation(tankId),
    onSuccess: async () => {
      for (const queryKey of callbackQueryKeys) {
        await queryClient.invalidateQueries({queryKey: queryKey, refetchType:"all"})
        // await queryClient.refetchQueries({queryKey: queryKey, type:"all"})
      }
      if (tank) {
        reloadTankList(dispatch)
        setTank(tankId, tank.tankName)
        await tankRefetch()

      }

    }
  })

  const onPressSubmit = () => {
    if (tank && Object.entries(tankState).filter(item => item[0] !== tankId).map(item => item[1].tankName).includes(tank.tankName)) {
      setNameStatus(true)
    } else {
      mutate()
      onClose()
    }
  }

  const waterSettingCards = waterDetailSettings ? waterDetailSettings.map((detailSetting) => {
      return <SettingCard
        key={detailSetting.cardName}
        cardName={detailSetting.cardName}
        cardType={detailSetting.cardType}
        isToggled={toggleSettings ? toggleSettings[detailSetting.cardName] : true}
        gaugeSetting={detailSetting.gaugeSetting!==null ? detailSetting.gaugeSetting: undefined}
      />
    })
    : <></>

  const fishSettingCards = fishDetailSettings ? fishDetailSettings.map((detailSetting) => {
      return <SettingCard
        key={detailSetting.cardName}
        cardName={detailSetting.cardName}
        cardType={detailSetting.cardType}
        isToggled={toggleSettings ? toggleSettings[detailSetting.cardName] : true}
        gaugeSetting={detailSetting.gaugeSetting!==null ? detailSetting.gaugeSetting: undefined}
      />
    })
    : <></>

  return (
    <DrawerContent>
      <DrawerHeader>
        <p className="text-[2rem] text-tdplindigo font-bold">
          Configuration
        </p>
        <p className="text-[0.875rem] font-normal text-tdplindigo">
          Select the data field and visualization method you want to monitor in "Tank Information".
        </p>
      </DrawerHeader>
      <DrawerBody>
        <p className="font-bold text-[1rem] text-tdplindigo my-1">Tank</p>
        <Card bg={'#F5F2F0'} borderColor={nameStatus?'#B22E2E':'#5A6C6F99'} borderWidth={nameStatus?2:0.5} my={2}  shadow={0}>
          <CardBody className="flex flex-row justify-between" py={2}>
            <p className="font-bold text-[1rem] text-tdpldarkgrey">Name</p>
            {
              tankSpec? <TankNameInput tankSpec={tankSpec}/>:<p>{""}</p>
            }
          </CardBody>
        </Card>
        {nameStatus? <p className="flex text-sm font-semibold text-tdplmaroon">{Bang()}A tank with this name already exists.</p>:<></>}
        <TankSettingCard
          isToggled={toggleSettings ? toggleSettings["tankVolume"] : true}
          tankSpec={tankSpec}
        />
        <SettingCard
          cardName={"memo"}
          cardType={toggleSettings ? toggleSettings["memo"] ? "on" : "off" : "on"}
          isToggled={toggleSettings ? toggleSettings["memo"] : true}
        />
        <p className="font-bold text-[1rem] text-tdplindigo my-1">Water</p>
        {waterSettingCards}
        <p className="font-bold text-[1rem] text-tdplindigo my-1">Fish</p>
        {fishSettingCards}
      </DrawerBody>
      <DrawerFooter>
        <div className="flex flex-row items-end">
          <Button onPress={onClose}
                  className="mr-3 mx-2 w-[6rem] bg-tdpllightgrey text-tdpldarkgrey text-[1rem] font-bold">Cancel</Button>
          <Button onPress={onPressSubmit} className="mx-2 w-[6rem] text-white bg-tdplplum text-[1rem] font-bold" isDisabled={!isSaveActive}>Save</Button>
        </div>
      </DrawerFooter>
    </DrawerContent>
  )
}
