import {CardNameLiteral} from "../../types/apiResponse/tankInfoSettingTypes";

export const cardPossibleOptions: {[cardName in CardNameLiteral]: string[]} = {
  tankVolume: ["on", "off"],
  memo: ["on", "off"],
  do: ["gauge", "off"],
  ph: ["gauge", "off"],
  waterLevel: ["gauge", "off"],
  nh3: ["gauge", "graph", "off"],
  no2: ["gauge", "graph", "off"],
  temperature: ["gauge", "off"],
  feeding: ["spec", "graph", "off"],
  count: ["spec", "graph", "off"],
  stockingDensity: ["spec", "graph", "off"],
  sampleWeight: ["graph", "off"],
  averageWeight: ["graph", "off"],
  biomass: ["graph", "off"],
  kFactor: ["graph", "off"],
  fcr: ["graph", "off"],
  weightDistribution: ["distribution", "off"]
}


export const tankOrder: CardNameLiteral[] = ["tankVolume", "memo"]
export const waterOrder: CardNameLiteral[] = ["nh3", "no2", "do", "ph", "waterLevel", "temperature"]
// const fishOrder: CardNameLiteral[] = ["feeding", "count", "sampleWeight", "averageWeight", "weightDistribution", "stockingDensity", "biomass", "kFactor", "fcr"]
export const fishOrder: CardNameLiteral[] = ["feeding", "count", "sampleWeight", "averageWeight", "weightDistribution", "stockingDensity", "biomass", "kFactor"]

export const getOrder = (section: "Water" | "Fish" | "Tank") => {
  switch (section) {
    case "Fish":
      return fishOrder
    case "Water":
      return waterOrder
    case "Tank":
      return tankOrder
  }
}