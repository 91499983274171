import {DotProps} from "recharts";

export const CustomizedDot = (props: DotProps & { payload: any }) => {
  const {cx, cy, payload} = props;
  const x = cx ? cx : 0
  const y = cy ? cy : 0

  return (
    <svg key={payload.index}>
      {
        payload.isActive ?
          <circle cx={x} cy={y} r="8" fill="#1C647D" fillOpacity="0.2"/>
          : <></>
      }
      {
        payload.isActive ?
          <circle cx={x} cy={y} r="4" fill="#1C647D"/>
          : <></>
      }
    </svg>
  );
}