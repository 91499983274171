import { useEffect } from 'react'
import {Button, useDisclosure} from "@nextui-org/react"
import { InitialInfo } from './InitialInfo';
import { SelectTanksStock } from './SelectTanksStock';
import useHomeStockStore from '../../store/home/useHomeStockStore';

export const Stock = () => {
  const { reset } = useHomeStockStore()
  const {isOpen: isOpen1, onOpen: onOpen1 , onOpenChange: onOpenChange1} = useDisclosure();
  const {isOpen: isOpen2, onOpen: onOpen2 , onOpenChange: onOpenChange2} = useDisclosure();

  useEffect(() => {
    if (!isOpen1 && !isOpen2) {
      reset()
    }
  }, [isOpen1, isOpen2, reset])

  return (
    <div>
      <Button 
        onPress={onOpen1} 
        className='font-bold bg-tdplbisque text-tdplprussian mx-2 text-[1.25rem] hover:bg-tdpllightgrey hover:text-tdplindigo'
      >
        Stock
      </Button>
      <SelectTanksStock 
        isOpen={isOpen1} 
        onOpenChange={onOpenChange1} 
        onOpen={onOpen2}
      />
      <InitialInfo 
        onOpenChange={onOpenChange2} 
        isOpen={isOpen2} 
        isBack={onOpen1}
      />
    </div>
    )
}