import {FC} from "react";
export type DownloadCSVIconProps = {
  isActive: boolean
}

export const DownloadCSVIcon: FC<DownloadCSVIconProps> = (
  {
    isActive
  }
) => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.166992 14.1667H11.8337V12.5H0.166992V14.1667ZM11.8337 5H8.50033V0H3.50033V5H0.166992L6.00033 10.8333L11.8337 5Z"
        fill="#092028" fillOpacity={isActive?"1":"0.5"}/>
    </svg>

  )
}