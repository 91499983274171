import {FC, PropsWithChildren} from "react";
import {useResolutionLimit} from "../../hooks/useResolutionLimit";
import {UnderLimit} from "./UnderLimit";


export const ResolutionLimit: FC<PropsWithChildren> = (
  {
    children
  }
) => {
  const {isUnderLimit} = useResolutionLimit()


  return (
    <>
      {
        isUnderLimit
          ? <UnderLimit/>
          : <>
            {children}
          </>
      }
    </>
  )
}
