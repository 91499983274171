import {FC} from "react";
import {GetHistogramResponseType} from "../../../types/apiResponse/chartTypes";
import {ResponsiveLine} from "@nivo/line";
import {HistTooltip} from "./tooltips/HistTooltip";
import {makeTotalHistChartData} from "../../../utils/chart/makeTotalHistChartData";

export type SingleDomainHistogramProps = {
  histQueryResult: GetHistogramResponseType
  schoolColors: { [id: string]: string }
}

export const SingleDomainHistogram: FC<SingleDomainHistogramProps> = (
  {
    histQueryResult,
    schoolColors
  }
) => {
  const {xTicks, chartData} = makeTotalHistChartData(histQueryResult, schoolColors)
  const minMax = xTicks.length >= 2
    ? {min: xTicks.at(0), max: xTicks.at(-1)}
    : {min: 0, max: 0}

  return (
    <ResponsiveLine
      data={chartData}
      margin={{top: 50, right: 50, bottom: 50, left: 70}}
      lineWidth={5}
      xScale={{
        type: 'linear',
        min: minMax.min,
        max: minMax.max
      }}
      yScale={{
        type: 'linear',
        reverse: false
      }}
      colors={d => d.color}
      axisBottom={{
        tickSize: 5,
        tickValues: xTicks
      }}
      axisLeft={{
        tickSize: 5,
        format: (value) => `${value} %`
      }}
      curve="monotoneX"
      pointSize={0}
      enableSlices="x"
      enableArea
      enableGridX={false}
      enableGridY={false}
      sliceTooltip={(p) => <HistTooltip item={p} labels={xTicks}/>}
    />
  )
}