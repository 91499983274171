import {FC, useRef, useEffect } from 'react'
import {
  ModalContent,
  ModalBody,
  Card,
} from "@nextui-org/react";
import {InsertTable} from '../common/Table';
import columns from './columns';
import useHomeGradeStore from '../../store/home/useHomeGradeStore';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { InitialInfoButton } from './InitialInfoButton';
import { ModalText } from '../common/Text';

export interface InitialInfoProps {
  onOpenChange: () => void
  isOpen: boolean
  isBack: () => void
}

export const InitialInfo: FC<InitialInfoProps> = ({
  onOpenChange,
  isOpen,
  isBack,
}) => {
  const { insertData, tanksValues, setSendGradeData, tanksInfoBorderColor, typeError, requiredError } = useHomeGradeStore()
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(tanksValues.length).fill(null));

  useEffect(() => {
    setSendGradeData()
  }, [tanksValues, setSendGradeData])

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Grade' description={ModalText('InsertTable')}/>
            <ModalBody className='gap-0 min-h-[25rem] pr-10 pl-10'>
              <BaseDivider/>
              <Card className={`mt-3 ${tanksInfoBorderColor} border-opacity-60 min-h-[20rem]`}>
                <InsertTable tanksValues={tanksValues} inputRefs={inputRefs} insertData={insertData} columns={columns} isGrade={true}/>
              </Card>
              {typeError?
                <p className='m-0 text-sm font-semibold text-tdplmaroon'>*Please enter a natural number value in the STOCK field.</p> :
                <p></p>}
              {requiredError?
                <p className='m-0 text-sm font-semibold text-tdplmaroon'>*Stock field is required.</p> :
                <p></p>}
            </ModalBody>
              <InitialInfoButton isBack={isBack} onClose={onClose} />
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
};
