import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "@nextui-org/react";

export const NotFoundForm: FC = () => {
  const navigate = useNavigate()

  const onClickLogin = () => {
    navigate("/login")
  }
  const onClickPrev = () => {
    navigate(-1)
  }

  return (
    <div className="w-[315px] h-[362px] flex flex-col justify-center -mt-[45px]">
      <div className="w-[280px] flex flex-col items-center">
        <p className="h-[140px] text-[10rem] font-bold text-white align-top">404</p>
        <p className="text-[1.875rem] h-fit font-bold p-0 text-white mt-[45px]">PAGE NOT FOUND</p>
      </div>
      <div className="w-[280px] mt-10">
        <p className="text-[14px] text-white text-center">
          The page you were looking for appears to have been moved, removed or doesn not exist. You can go to the pages below.
        </p>
      </div>
      <div className="w-[295px] grid grid-cols-2 gap-4 mt-10">
        <Button className="text-white font-bold text-[1rem] bg-tdpllightgrey" onClick={onClickLogin}>
          Login Page
        </Button>
        <Button className="text-white font-bold text-[1rem] bg-tdplplum" onClick={onClickPrev}>
          Previous Page
        </Button>
      </div>
    </div>
  )
}