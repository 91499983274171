import { Card, Chip, Modal, ModalContent, useDisclosure } from "@nextui-org/react"
import useHomeGradeStore from "../../store/home/useHomeGradeStore"
import SelectTank from "../../pages/SelectTank"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes"

export const SelectArrivalTanks = () => {
  const {isOpen: isOpenArrTanks, onOpen: onOpenArrTanks, onOpenChange: onOpenChangeArrTanks} = useDisclosure();
  const {depBatchId, selectArrTanksBorderColor, selectedArrTanks, setSelectedArrTanks } = useHomeGradeStore()

  return (
    <>
      {depBatchId ? (
        <div className="mt-3 font-bold">Arrival Tanks</div>
      ) : (
        <div className="mt-3 font-semibold text-tdpllightgrey">
          Arrival Tanks{" "}
          <span className="text-xs ">**Please select Departure Tank first</span>
        </div>
      )}
      <Card
        key="arrival"
        className={`flex flex-row flex-wrap justify-start p-1 ${selectArrTanksBorderColor} border-opacity-60 min-h-[3rem]`}
        radius='sm'
        shadow="sm"
        isPressable={depBatchId !== null}
        onPress={onOpenArrTanks}
        isDisabled={depBatchId === null}>
        <Modal isOpen={isOpenArrTanks} onOpenChange={onOpenChangeArrTanks} isDismissable={false}
                isKeyboardDismissDisabled={true} size="3xl">
          <ModalContent>
            {(onCloseTanks) => (
              <SelectTank
                key="arrival"
                submitTankArray={(tankSelectedList: SubmitTankArrayType) => setSelectedArrTanks(tankSelectedList)}
                batchIdList={depBatchId!==null?[depBatchId, "$empty", "$unbatched"]:["$empty", "$unbatched"]}
                onClose={onCloseTanks}
                initialTankArray={selectedArrTanks}
              />
            )}
          </ModalContent>
        </Modal>
        {selectedArrTanks.map((item, index) => (
          <Chip key={index} radius="sm" classNames={{
            base: `border-small border-tdpldarkgrey bg-${item.batchName === "$empty" ? "tdpllightgrey" : "tdplsky"} border-opacity-60 m-1 p-0`,
            content: "font-bold"
          }}>{item.tankName}</Chip>
        ))}
      </Card>
      {selectArrTanksBorderColor.includes("tdplmaroon") ?
        <p className='m-0 text-sm font-semibold text-tdplmaroon'>Please select Arrival Tanks.</p> :
        <p></p>}
      </>
  )
}