import React, {FC, PropsWithChildren, useEffect} from "react";
import {Modal, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import {TankInfo} from "../../pages/TankInfo";
import {useTankInfoModalStore} from "../../store/tankInfo/useTankInfoModalStore";

export const TankInfoProvider: FC<PropsWithChildren> = (
  {
    children
  }
) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const modalStore = useTankInfoModalStore()

  useEffect(() => {
    modalStore.initialize(onOpen, onClose)
  }, []);

  useEffect(() => {
    modalStore.setIsOpen(isOpen)
  }, [isOpen]);

  return (
    <>
      {
        modalStore.tankId !== ""
          ? <Modal isOpen={isOpen} size={"6xl"} scrollBehavior="outside" onClose={()=> {
            modalStore.close()
            onClose()
          }}>
            <ModalOverlay/>
            <ModalContent p={"40px"}>
              <TankInfo/>
            </ModalContent>
          </Modal>
          : <></>
      }
      {children}
    </>
  )
}