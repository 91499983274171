import {FC} from "react";
import useChartStore from "../../store/chart/useChartStore";
import {useQuery} from "@tanstack/react-query";
import {getHistogramData} from "../../api/chart/chartComponent";
import {Card} from "@nextui-org/react"
import {SingleDomainHistogram} from "./plot/SingleDomainHistogram";
import {Spinner} from "@chakra-ui/react";

export type HistComponentProps = {
  date: Date
  schoolColors: { [id: string]: string }
}

export const HistComponent: FC<HistComponentProps> = (
  {
    date,
    schoolColors
  }
) => {
  const newDate = Number(date.toISOString().split("T")[0].replaceAll("-", ""))

  const {selectedSchools} = useChartStore()

  const histQueryResult = useQuery({
    queryKey: [selectedSchools, date],
    queryFn: async () => {
      if (selectedSchools.length === 0) return null
      else return await getHistogramData({
      // else return await getTestHistogramData({
        date: newDate,
        schoolIds: selectedSchools
      })
    },
    staleTime: 500
  })
  return (
    <div className="flex flex-col">
      <div className="mx-1 mb-1 flex flex-row justify-between">
        <p className="font-bold text-[1.25rem]">Weight Histogram</p>
      </div>
      {
        histQueryResult.isLoading?
          <Card shadow="none" className="h-[25dvw] max-h-[35dvh] min-w-[100%] border-[1px] border-tdpldarkgrey border-opacity-60
          flex items-center justify-center">
            <Spinner color="#B98CA4" emptyColor="gray.200" size="lg"/>
          </Card>
        :!histQueryResult.isError && histQueryResult.data !== undefined && histQueryResult.data !== null
          ? <Card shadow="none" className="min-w-[100%] border-[1px] border-tdpldarkgrey border-opacity-60">
            <div className="p-0 h-[25dvw] max-h-[35dvh]">
              <SingleDomainHistogram schoolColors={schoolColors} histQueryResult={histQueryResult.data}/>
            </div>
          </Card>
          : null
      }
    </div>
  )
}