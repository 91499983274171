import { create } from "zustand";
import { SubmitTankArrayType, TankValuesArrayType } from "../../types/apiResponse/tankTypes";
import { editBatchType } from "../../api/batch/editBatch";

type HomeEditBatchState = {
  startDate : number | null
  batchId: string | null
  batchName: string | null
  selectedTanks: SubmitTankArrayType
  batchNameBorderColor: string
  startDateBorderColor: string
  selectedTanksBorderColor: string
  editBatchData: editBatchType
  selectedEmptyTanks: TankValuesArrayType
  notAllowedBatchName:boolean
  filledrequired: boolean
  tanksInfoBorderColor:string
  typeError: boolean
  requiredError: boolean
  nameDuplicated: boolean
};

type HomeEditBatchAction = {
  setBaseData: (baseDate:number, batchId:string, batchName:string) => void
  setBatchName: (batchName:string|null, batchNameList:string[]) => void
  setStartDate: (startDate: number|null) => void
  setSelectedTanks: (selectedTanks:SubmitTankArrayType) => void
  setEditBatchData: (editBatchData:editBatchType) => void
  setBatchNameBorderColor: () => void
  setStartDateBorderColor: () => void
  setSelectedTanksBorderColor: () => void
  setSelectedEmptyTanks: (selectedEmptyTanks:TankValuesArrayType) => void
  setTanksInfoBorderColor: () => void
  reset: () => void
};

const initialState: HomeEditBatchState = {
  startDate: null,
  batchId: null,
  batchName: null,
  selectedTanks: [],
  batchNameBorderColor:'border border-tdpldarkgrey',
  startDateBorderColor:"[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60",
  selectedTanksBorderColor:'border border-tdpldarkgrey',
  editBatchData: {batchName:null, startDate:null, filledTanks:[],emptyTanks:[]},
  selectedEmptyTanks: [],
  notAllowedBatchName: false,
  filledrequired: false,
  tanksInfoBorderColor: "border border-tdpldarkgrey",
  typeError: false,
  requiredError: false,
  nameDuplicated: false
}

const useHomeEditBatchStore = create<HomeEditBatchState & HomeEditBatchAction>(
  (set, get) => ({
    ...initialState,
    setBaseData: (baseDate, batchId, batchName) => {
      set((state) => ({
        startDate:baseDate,
        batchId:batchId,
        batchName:batchName,
        editBatchData: {...state.editBatchData, batchName:batchName, startDate:baseDate}
      }))
    },
    setSelectedTanks: (selectedTanks) => {
      set((state) => ({
        selectedTanks:selectedTanks,
        selectedTanksBorderColor:'border border-tdpldarkgrey',
        editBatchData: {
          ...state.editBatchData,
          filledTanks:selectedTanks.filter(item=> item.batchName !== "$empty").map(item=>item.tankId),
          emptyTanks: selectedTanks.filter(item=> item.batchName === "$empty").map((item) => {
            return {
              tankId: item.tankId,
              count: null,
              moveDate: null,
              sampleWeight: null
            }
          })
        },
        selectedEmptyTanks: selectedTanks.filter(item=> item.batchName === "$empty").map((item) => {
          return {
            tankName:item.tankName,
            tankId: item.tankId,
            batchName: item.batchName,
            count: null,
            moveDate: null,
            sampleWeight: null
          }
        })
      }))
    },
    setBatchName: (batchName, batchNameList) => {
      set((state) => ({
        batchName:batchName===""?null:batchName,
        notAllowedBatchName: batchName !== null && batchName.includes('$')? true:false,
        batchNameBorderColor: batchName === null || batchName.includes('$')?
          "border-2 border-tdplmaroon data-[hover=true]:border-tdplmaroon group-data-[focus=true]:border-tdplmaroon"
          :'border border-tdpldarkgrey',
        editBatchData:{...state.editBatchData, batchName:batchName},
        nameDuplicated: batchName? batchNameList.includes(batchName): false
      }))
    },
    setStartDate: (startDate) => {
      set((state) => ({
        startDate:startDate,
        startDateBorderColor:"[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60",
        editBatchData: {...state.editBatchData, startDate:startDate}
      }))
    },
    setEditBatchData: (editBatchData) => {
      set(() => ({editBatchData: editBatchData}))
    },
    setBatchNameBorderColor: () => {
      set((state) => ({batchNameBorderColor: state.batchName === null || state.batchName.includes('$') || state.nameDuplicated?
        "border-2 border-tdplmaroon data-[hover=true]:border-tdplmaroon group-data-[focus=true]:border-tdplmaroon"
        :"border border-tdpldarkgrey"}))
    },
    setStartDateBorderColor: () => {
      set((state) => ({
        startDateBorderColor:state.startDate === null
        ? "[&>div]:border-2 [&>div]:border-tdplmaroon [&>div]:hover:border-tdplmaroon"
        : "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
      }))
    },
    setSelectedTanksBorderColor: () => {
      set((state) => ({selectedTanksBorderColor: state.selectedTanks.length === 0? "border-2 border-tdplmaroon":"border border-tdpldarkgrey"}))
    },
    setSelectedEmptyTanks: (selectedEmptyTanks) => {
      set((state)=>{
        const typeError = !selectedEmptyTanks.every(item=> item.count === null || Number.isInteger(item.count))
        return {
        selectedEmptyTanks:selectedEmptyTanks,
        filledrequired: selectedEmptyTanks.every(item=>item.count!== null && item.moveDate!==null),
        tanksInfoBorderColor:typeError?"border-2 border-tdplmaroon":"border border-tdpldarkgrey",
        editBatchData: {...state.editBatchData, emptyTanks: selectedEmptyTanks.map(({tankName, batchName, ...item}) => ({...item}))},
        typeError: typeError,
        requiredError: false,
      }})
    },
    setTanksInfoBorderColor: () => {
      set((state) => {
        const requiredError = !state.selectedEmptyTanks.every(item=>item.count!== null && item.moveDate!==null)
        return {
          tanksInfoBorderColor:requiredError?
          "border-2 border-tdplmaroon"
          : "border-tdpldarkgrey",
          requiredError:requiredError
      }})
    },
    reset: () => {
      set(initialState)
    },
  }),
)


export default useHomeEditBatchStore;
