import {FC} from "react";
import {BarSVG} from "./svg/BarSVG";
import {ReactComponent as RedIndicatorSVG} from '../../assets/svg/tankInfo/indicator-red.svg'
import {ReactComponent as BlueIndicatorSVG} from '../../assets/svg/tankInfo/indicator-blue.svg'

export type MeasureGaugeProps = {
  minLimit: number
  maxLimit: number
  minWarning: number
  maxWarning: number
  value: number | undefined | null
}

export const MeasureGauge: FC<MeasureGaugeProps> = (
  {
    minLimit,
    maxLimit,
    minWarning,
    maxWarning,
    value
  }
) => {
  const indicator = value === undefined || value === null
    ? <></>
    : minWarning < value && maxWarning > value
      ? <BlueIndicatorSVG width="28px" height="28px"/>
      : <RedIndicatorSVG width="28px" height="28px"/>
  const position = value === undefined || value === null? 0
    : value >= maxLimit ? 100
    : value <= minLimit ? 0
    : (value - minLimit) / (maxLimit - minLimit) * 100
  // const position = 0

  const minWarningPortion = (minWarning - minLimit) / (maxLimit - minLimit)
  const maxWarningPortion = (maxWarning - minLimit) / (maxLimit - minLimit)
  const greyStart = minWarningPortion * 100
  const greyEnd = maxWarningPortion * 100
  const redEnd = 100 - greyEnd

  const textLeftMargin = minWarningPortion * 100 - 10
  const textRightMargin = 100 - (maxWarningPortion * 100) - 10

  return (
    <div className="flex flex-col w-[100px] h-[65pxrem] content-between">
      <div className="flex justify-between align-top h-[14px]">
        <p className="text-[10px] font-bold text-tdplmaroon"
           style={{marginLeft: `${textLeftMargin}px`}}>{minWarning}</p>
        <p className="text-[10px] font-bold text-tdplmaroon"
           style={{marginRight: `${textRightMargin < 0 ? 0 : textRightMargin}px`}}>{maxWarning}</p>
      </div>
      <div className="align-middle h-[28px]">
        <div className="relative justify-center align-middle">
          <div className="absolute z-10" style={{top: ".4375rem"}}>
            <BarSVG greyStart={greyStart} greyEnd={greyEnd} redEnd={redEnd}/>
          </div>
          {
            value === 0 ? <></>
              : <div className="absolute z-20" style={{left: `${position - 14}px`}}>
                {indicator}
              </div>
          }
        </div>
      </div>
      <div className="flex justify-between align-bottom h-[14px]">
        <p className="text-[10px] font-bold text-tdplprussian" style={{marginLeft: "-10px"}}>{minLimit}</p>
        <p className="text-[10px] font-bold text-tdplprussian">{maxLimit}</p>
      </div>
    </div>
  )
}


export default MeasureGauge