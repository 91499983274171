import {FC, useEffect, useState} from "react";
import {Card, ModalBody, ModalHeader, useDisclosure} from "@nextui-org/react";
import {TankModalContent} from "../components/selectTanks";
import TankSelected from "../components/selectTanks/TankSelected";
import {CommonAlert} from "../components/common/Alert";
import {useSelectTankStore} from "../store/selectTank/useSelectTankStore";
import {SubmitTankArrayType} from "../types/apiResponse/tankTypes";
import { BaseModalFooter } from "../components/common/Modal";


export type SelectTankProps = {
  submitTankArray: (selectedTanks: SubmitTankArrayType) => void
  initialTankArray?: SubmitTankArrayType | undefined
  batchIdList: string[]
  limit?: number | undefined
  onClose: () => void
}

export const SelectTank: FC<SelectTankProps> = (
  {
    submitTankArray,
    initialTankArray,
    batchIdList,
    limit,
    onClose
  }
) => {
  const {selectedTankList, initialize, close, detach} = useSelectTankStore()
  const [isSubmitActivated, setIsSubmitActivated] = useState<boolean>(false)
  const {isOpen: isAlertOpen, onOpen: onAlertOpen, onOpenChange: onAlertOpenChange} = useDisclosure()

  useEffect(() => {
    if (selectedTankList.length !== 0) {
      if (limit !== undefined && selectedTankList.length > limit) setIsSubmitActivated(false)
      else setIsSubmitActivated(true)
    }
    else setIsSubmitActivated(false)
  }, [selectedTankList]);

  useEffect(() => {
    if (limit !== undefined && selectedTankList.length > limit) {
      onAlertOpen()
      detach(selectedTankList[1].tankId)
    }
  }, [selectedTankList, limit, onAlertOpen]);

  useEffect(() => {
    if (initialTankArray !== undefined) initialize(initialTankArray)
  }, [initialTankArray]);

  const onSubmitSelectedTanks = () => {
    if (isSubmitActivated) {
      submitTankArray(selectedTankList);
      onSelectTankClose();
    }
  }

  const onSelectTankClose = () => {
    close()
    onClose()
  }

  return (
    <Card className="bg-white p-[0.5rem] border-1 border-opacity-60 border-tdpldarkgrey">
      <ModalHeader className="mt-[1rem]">
        <h1 className="text-tdplindigo font-semibold text-[2rem] w-full">Select Tanks</h1>
      </ModalHeader>
      <p className="text-tdplindigo text-[0.875rem] mx-7 mb-[1rem]">Tap the tank cards below to select the tank you want to operate.</p>
      <ModalBody>
        <TankModalContent batchIdList={batchIdList}/>
        <TankSelected/>
      </ModalBody>
      <BaseModalFooter back="Cancel" next="Select" backOnPress={onSelectTankClose} nextOnPress={onSubmitSelectedTanks} 
        nextClassName={`${isSubmitActivated ? "bg-tdplplum" : "bg-tdplpigpink"} text-white`}/>
      <CommonAlert isOpenAlert={isAlertOpen} onCloseAlert={onAlertOpenChange} containContinue={false}
                   title="Tank Limit Over Alert">
        <p>
          only {limit} tank(s) can be selected
        </p>
      </CommonAlert>
    </Card>
  )
}

export default SelectTank
