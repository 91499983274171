import {Card, CardBody} from "@nextui-org/react";
import EmptyCard from "./EmptyCard";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import * as ARStore from "../../store/activeRelation"
import * as TankStore from "../../store/tank"

export default function EmptyCardContainer() {
  const activeRelationList = useSelector<AppState, ARStore.ARState>(state => state.activeRelation)
  const storedTankList = useSelector<AppState, TankStore.TankState>(state => state.tank)
  const createEmptyBlocks = activeRelationList["$empty"]?.map(
    (value) => value.tankId
  )?.map((tankId) => {
    if (tankId in storedTankList) {
      const emptyTank = storedTankList[tankId]
      return (
        <span key={tankId} className="mx-2 my-1">
                <EmptyCard tankName={emptyTank.tankName} tankId={tankId}/>
            </span>
      )
    } else return null
  })
  return (
    <div>
      {activeRelationList["$empty"] && activeRelationList["$empty"]?.length !== 0
        ?
        <div className="mt-10">
          <div className="flex justify-between my-1">
            <div className="flex justify-start gap-[1.25rem]">
              <div className="ml-1 mb-2 text-tdplindigo text-[1.25rem]">Empty Tanks</div>
            </div>
          </div>
          <Card className="bg-white border-1 border-tdpldarkgrey border-opacity-50" shadow="none">
            <CardBody className="flex flex-wrap flex-row justify-start">
              {createEmptyBlocks}
            </CardBody>
          </Card>
        </div>
        : <></>
      }
    </div>
  )
}