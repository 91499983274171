import { useDisclosure } from "@nextui-org/react"
import useHomeGradeStore from "../../store/home/useHomeGradeStore"
import { CommonAlert } from "../common/Alert"
import { postGrade } from "../../api/tank/grade"
import { useDispatch } from "react-redux"
import { FC } from "react"
import { BaseModalFooter } from "../common/Modal"
import { useQueryClient } from "@tanstack/react-query"

type InitialInfoButtonProps = {
  onClose: () => void
  isBack: () => void
}
export const InitialInfoButton:FC<InitialInfoButtonProps> = ({
  onClose,
  isBack
}) => {
  const {setTanksInfoBorderColor, tanksValues, depCount, sumCount, sendGradeData, gradeDate, departureSchoolId, typeError} = useHomeGradeStore()
  const {isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert} = useDisclosure()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const chekTanksValuesBeforeFinish = () => {
    setTanksInfoBorderColor()
    if (tanksValues.every(item=>item.count !== null && item.count>0) && !typeError) {
      if ((depCount !== sumCount) && sendGradeData) {
        onOpenAlert()
      } else if (sendGradeData) {
        finishJob(onClose)
      }
    }
  }

  const finishJob = (onClose: () => void) => {
    if (sendGradeData && departureSchoolId && gradeDate) {
      postGrade(gradeDate, sendGradeData, dispatch, departureSchoolId, onClose).then( async () => {
        await queryClient.invalidateQueries({queryKey: ["undoable"]})
      })
    }
  }

  return (
    <>
    <BaseModalFooter back="Back" next="Finish" backOnPress={()=>{onClose();isBack()}} nextOnPress={chekTanksValuesBeforeFinish}/>
    <CommonAlert key="CountsNotEqualAlert" isOpenAlert={isOpenAlert} onCloseAlert={onCloseAlert} onClose={onClose} 
      continueJob={finishJob} title="The Count doesn't match!"
      children={
        <p>{`The sum of the counts, ${sumCount}, does not match the departure tank's count of ${depCount}. Do you want to continue grading?`}</p>
      }/>
    </>
    )
}