import {AreaChart, Area, XAxis} from 'recharts';
import {FC} from "react";
import {makeIndexedDistForm} from "../../../utils/graph/tinyLineGraphUtils";

export type TinyLineGraphProps = {
  values: number[]
  xTicks: number[]
}

export const TinyLineDist: FC<TinyLineGraphProps> = ({values, xTicks}) => {
  const data = makeIndexedDistForm(values, xTicks)
  const domainMin = Math.min(...xTicks)
  const domainMax = Math.max(...xTicks)


  return (
    <AreaChart width={215} height={90} data={data}>
      <Area dataKey="value" type="monotone" stroke="#355968" fill="#355968" fillOpacity={0.2} strokeWidth={2}/>
      {/*그래프 자체가 멀쩡하게 나오게 하기 위한 Tick 구성*/}
      <XAxis dataKey="xTick" hide={true} />
      {/*틱을 한개 더 보여주기 위한 구성*/}
      <XAxis dataKey="xTick" interval="preserveStartEnd" xAxisId="ticks" type="number" domain={[domainMin, domainMax]} tickCount={data.length + 1} className="text-[0.625rem]"/>
    </AreaChart>
  )
}