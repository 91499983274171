import {create} from "zustand";

export type useAvgDistDateState = {
  date: Date | null
}

export type useAvgDistDateAction = {
  setDate: (date: Date) => void
}


export const useAvgDistDateStore = create<useAvgDistDateAction&useAvgDistDateState>(
  (set) => ({
    date: null,
    setDate: (date) => set(state => ({...state, date: date}))
  })
)
