import {FC, useEffect, useState} from "react";
import {CardNameLiteral, GraphSetting} from "../../../types/apiResponse/tankInfoSettingTypes";
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {makeTitle} from "../../../utils/tankInfo";
import {TinyLineGraph} from "./TinyLineGraph";
import {convertMetric} from "../../../utils/metricConvert";
import {findLastValidNumber} from "../../../utils/graph/tinyLineGraphUtils";
import {useTankInfoGraph} from "../../../hooks/tankInfo/useTankInfoValues";
import {AlertIcon} from "../svg/AlertIcon";
import {NoContent} from "./NoContent";

export type GraphProps = {
  tankId: string
  cardName: CardNameLiteral
  graphSetting: GraphSetting
}

export const GraphCard: FC<GraphProps> = (
  {
    tankId,
    cardName,
    graphSetting,
  }
) => {
  const tankInfoGraph = useTankInfoGraph(tankId, cardName)
  const values = tankInfoGraph ? tankInfoGraph[cardName]?.values : undefined
  const {title, subTitle} = makeTitle(cardName)
  const valuesValid = !(!values)
  const isNoContent = valuesValid && values.every(ele => ele === null)
  const {val, metric} = convertMetric(
    findLastValidNumber(values),
    cardName,
    graphSetting?graphSetting.metric:""
  )
  const [isToday, setIsToday] = useState<boolean>(true)

  useEffect(() => {
    if (tankInfoGraph !== undefined && tankInfoGraph !== null) {
      if (tankInfoGraph[cardName] !== undefined) {
        const cardResponse = tankInfoGraph[cardName]
        if (cardResponse !== undefined) setIsToday(cardResponse.isToday)
      }
    }
  }, [tankInfoGraph]);

  return (
    <Card className={`rounded-lg col-span-1 outline outline-[1px] outline-tdpldarkgrey/[.60] w-[253px] h-[140px] p-[19px]
      ${isToday ? "bg-tdplbisque" : "bg-tdpllightgrey"}`}
          style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
      <CardHeader className="flex flex-row items-start m-0 p-0 mb-[15px] h-[22px]">
        {isToday ? <></> : <div className="mt-[6px] mr-1"><AlertIcon/></div>}
        <p className="text-tdpldarkgrey text-[1rem] font-bold mr-2">
          {title}
        </p>
        {subTitle !== undefined
          ? <p className="text-tdplmiddlegrey text-[1rem]">
            {subTitle}
          </p>
          : null
        }
      </CardHeader>
      <CardBody className="m-0 p-0 h-[65px] overflow-hidden">
        {
          isNoContent || !valuesValid || val === "---"
            ? <NoContent/>
            : <div className="flex flex-row">
              <div className="flex flex-col w-[115px] items-start">
                <p className="text-[30px] font-bold text-tdplprussian">
                  {val}
                </p>
                <p className="text-[14px] text-tdplprussian">
                  {graphSetting.metric ? metric : null}
                </p>
              </div>
              <div className="w-[100px] flex flex-col justify-center">
                {!isNoContent && valuesValid ? <TinyLineGraph values={values}/> : null}
              </div>
            </div>
        }
      </CardBody>
    </Card>
  )
}
