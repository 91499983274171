import * as T from './types'

export const addSchool = (payload: T.SchoolInfo & { schoolId: string }): T.AddSchoolAction => ({
  type: '@school/addSchool',
  payload
})

export const delSchool = (payload: { schoolId: string }): T.DelSchoolAction => ({
  type: '@school/delSchool',
  payload
})

export const setSchool = (payload: T.SchoolState): T.SetSchoolAction => ({
  type: '@school/setSchool',
  payload
})

export const updateSchool = (payload: T.SchoolInfo & { schoolId: string }): T.UpdateSchoolAction => ({
  type: '@school/updateSchool',
  payload
})