import { Card } from "@nextui-org/react"
import { MouseEvent } from "react"
import ReactFlow, { Node, Background, BackgroundVariant, useReactFlow } from "reactflow"
import { TankNode } from "./TankNode"
import { TankEdge } from "./TankEdge"
import { ThemeConfig } from "./config"
import useChartStore from "../../store/chart/useChartStore"
import { useShallow } from 'zustand/react/shallow';

const nodeTypes = {
  tankNode: TankNode 
}
const edgeTypes = {
  tankEdge: TankEdge
}

export const FlowChart = () => {
  const {setSelectedEdges, setSelectedTanks, onNodesChange, onEdgesChange, nodes, edges} = useChartStore(
    useShallow((state) => ({
      getSchools: state.getSchools,
      setSelectedEdges: state.setSelectedEdges,
      setSelectedTanks: state.setSelectedTanks,
      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      nodes: state.nodes,
      edges: state.edges,
      setSchools: state.setSchools,
      batchId: state.batchId,
      preBatchId: state.preBatchId
    })),
  )
  const { fitView } = useReactFlow();

  const onNodeClick = (event:MouseEvent, node:Node) => {
    setSelectedTanks({schoolId:node.id, tankName:node.data.tankName})
  }

  const onNodeMouseEnter = (event:MouseEvent, node:Node) => {
    setSelectedEdges(node.id)
  }

  const onNodeMouseLeave = () => {
    setSelectedEdges(null)
  }

  return (
    <Card className="border shadow-none border-tdpldarkgrey border-opacity-60 bg-tdplbisque">
      <div style={{ width: 'full', height: '50vh' }}>
        <ReactFlow
          minZoom={0.2}
          maxZoom={1}
          nodes={nodes}
          edges={edges}
          onNodesChange={(event) => {
            onNodesChange(event)
            fitView()
          }}
          onEdgesChange={onEdgesChange}
          onNodeClick={onNodeClick}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{ hideAttribution: true }}
          nodesDraggable={false}
          nodesConnectable={false}
          elementsSelectable={false}
          zoomOnDoubleClick={false}
          draggable={false}
          preventScrolling={false}
          onError={(event) => console.log(event)}
        >
        <Background
          gap={[ThemeConfig.unitSize*ThemeConfig.widthSize, 1000]}
          color="#9CA7A9"
          variant={BackgroundVariant.Lines}
        />
        </ReactFlow>
      </div>
    </Card>
  )
}