import {FC, useRef } from 'react'
import { ModalContent, ModalBody, Divider, Card } from "@nextui-org/react";
import { UpdateSchoolValuesType } from '../../types/apiResponse/tankTypes';
import { InsertTable } from '../common/Table';
import { insertDataInGradeUpdate } from '../../utils/selectTank/common';
import columns from './columns';
import useHomeUpdateStore from '../../store/home/useHomeUpdateStore';
import { BaseModal, BaseModalHeader } from '../common/Modal';
import { InitialInfoButton } from './InitialInfoButton';
import { EventType } from '../common/Input';
import { ModalText } from '../common/Text';

export type InitialInfoProps = {
  onOpenChange: () => void
  isOpen: boolean
  isBack: () => void
}

export const InitialInfo: FC<InitialInfoProps> = ({
  onOpenChange,
  isOpen, 
  isBack, 
}) => {
  const {tableBorderColor, updateTableData, setInsertUpdateTableData, typeError, requiredCheck } = useHomeUpdateStore()
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(updateTableData.length).fill(null));

  const insertData = (event:EventType) => {
    insertDataInGradeUpdate<UpdateSchoolValuesType>(event, updateTableData, setInsertUpdateTableData)
  }

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"}>
      <ModalContent>
      {(onClose) => (
        <>
        <BaseModalHeader title='Update' description={ModalText('InsertTable')}/>
        <ModalBody className='gap-0 min-h-[25rem] pr-10 pl-10' >
          <Divider className="my-4" style={{ margin: "1px" }}/>
          <Card className={`mt-3 ${tableBorderColor} border-opacity-60 min-h-[20rem]`}>
          <InsertTable tanksValues={updateTableData} inputRefs={inputRefs} columns={columns} insertData={insertData}/>
          </Card>
          {requiredCheck?
          <p className='m-0 text-sm font-semibold text-tdplmaroon'>FEED AMOUNT and FEED TIMES fields must be entered together.</p>
          : <p> </p>}
          {typeError? 
            <p className='m-0 text-sm font-semibold text-tdplmaroon'>Please enter a natural number value in the FEED TIMES, MORTALITY, and HARVEST fields.</p> 
            : <p> </p>}
        </ModalBody>
        <InitialInfoButton onClose={onClose} isBack={isBack}/>
        </>
      )}
      </ModalContent>
    </BaseModal>
  )
};
