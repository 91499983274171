import { Button, Divider, Modal, ModalFooter, ModalHeader } from "@nextui-org/react"
import { FC, ReactNode } from "react"

type SizeType = "sm" | "md" | "lg" | "xl" | "2xl" | "xs" | "3xl" | "4xl" | "5xl" | "full" | undefined
type BackDropType = "transparent" | "opaque" | "blur" | undefined

type BaseModalProps = {
  isOpen: boolean 
  onOpenChange:()=>void
  size:SizeType
  children:ReactNode
  backdrop?: BackDropType
  isDismissable?:boolean
}

export const BaseModal: FC<BaseModalProps> = ({
  isOpen,
  onOpenChange,
  children,
  size,
  backdrop,
  isDismissable
}) => {
  return (
    <Modal 
      isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={isDismissable??false}
      size={size} backdrop={backdrop}
      hideCloseButton={true}
      isKeyboardDismissDisabled={false}
      motionProps={{
        variants: {
          enter: {y: 0, opacity: 1, transition: {duration: 0.3, ease: "easeOut"}},
          exit: {y: 0, opacity: 0, transition: {duration: 0, ease: "easeIn"}},
        }
      }}
      >
      {children}
    </Modal>
  )
}

type BaseModalHeaderProps = {
  title: string
  description: string | ReactNode
}

export const BaseModalHeader: FC<BaseModalHeaderProps> = ({
  title,
  description
}) => {
  return (
    <ModalHeader className="flex flex-col gap-5 pt-12 pl-12 pr-12 font-bold leading-5"
                  style={{fontSize: '2rem'}}>
      {title}
      <small className="" style={{fontSize: '0.9rem', fontWeight: 'normal'}}>{description}</small>
    </ModalHeader>
  )
}

type BaseModalFooterProps = {
  back: string
  next: string
  backOnPress?:()=>void
  nextOnPress?:()=>void
  nextClassName?:string
}

export const BaseModalFooter: FC<BaseModalFooterProps> = ({
  back,
  next,
  backOnPress,
  nextOnPress,
  nextClassName,
}) => {
  return (
    <ModalFooter className='pb-8 pl-10 pr-10'>
    <Button className='mr-3 font-bold w-[6rem] bg-tdpllightgrey text-tdpldarkgrey' onPress={backOnPress}>
      {back}
    </Button>
    <Button className={"font-bold text-white w-[6rem] mr-0 bg-tdplplum "+nextClassName??null}
            onPress={nextOnPress}>
      {next}
    </Button>
  </ModalFooter>
  )
}

export const BaseDivider = () => {
  return <Divider className="my-4 bg-tdpllightgrey" style={{ margin: "1px" }}/>
}