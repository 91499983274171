import {ChangeEvent, FC, useEffect, useState} from "react";
import {TankType} from "../../types/apiResponse/tankSpecTypes";
import {useTankInfoStageStore} from "../../store/tankInfo/useTankInfoStageStore";

const re = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/

export type TankNameInputProps = {
  tankSpec: TankType
}

export const TankNameInput: FC<TankNameInputProps> = (
  {
    tankSpec
  }
) => {
  const [tankName, setTankName] = useState<string>(tankSpec.tankName)
  const {tank, editTank} = useTankInfoStageStore()

  const handleTankNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <=10) setTankName(e.target.value.replace(re, ""))
  }

  useEffect(() => {
    if (tank === undefined) editTank({...tankSpec, tankName: tankName})
    else editTank({...tank, tankName: tankName})
  }, [tankName]);

  useEffect(() => {
    setTankName(tankSpec.tankName)
  }, [tankSpec]);

  return (
    <input className="text-[1rem] text-right text-tdplindigo bg-tdplbisque outline-none" onChange={handleTankNameChange} value={tankName}/>
  )
}
