import {
  CardDetailSettingResponse,
  CardToggleSetting,
} from "../../types/apiResponse/tankInfoSettingTypes";
import {getData} from "../../utils";

export const getTankInfoCardToggleSetting = async (
) => {
  return await getData<CardToggleSetting>(`tank-info-setting`)
}
export const getTankInfoCardDetail = async (
  cardNames: string[],
) => {
  const cardQueryParams = cardNames.join("&card-names=")
  return await getData<CardDetailSettingResponse[]>(`tank-info-card-detail?card-names=${cardQueryParams}`)
}