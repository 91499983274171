import { Card, Chip, Modal, ModalContent, useDisclosure } from "@nextui-org/react"
import SelectTank from "../../pages/SelectTank"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes";
import useHomeEditBatchStore from "../../store/home/useHomeEditBatchStore";

export const SelectEditBatchTanks = () => {
  const {isOpen: isOpenTanks, onOpen: onOpenTanks, onOpenChange: onOpenChangeTanks} = useDisclosure();
  const {selectedTanksBorderColor, setSelectedTanks, batchId, selectedTanks} = useHomeEditBatchStore()
  return (
    <>
    <div className='mt-3 font-bold'>Tanks</div>
    <Card key="arrival"
          className={`flex flex-row flex-wrap justify-start p-1 ${selectedTanksBorderColor} border-opacity-60 min-h-[3rem]`}
          radius='sm' shadow="sm" isPressable onPress={onOpenTanks}>
      <Modal isOpen={isOpenTanks} onOpenChange={onOpenChangeTanks} isDismissable={false}
              isKeyboardDismissDisabled={true} size="3xl">
        <ModalContent>
          {(onCloseTanks) => (
            <SelectTank
              key="arrival"
              submitTankArray={(tankSelectedList: SubmitTankArrayType) => setSelectedTanks(tankSelectedList)}
              batchIdList={[batchId?batchId:"", "$empty", "$unbatched"]}
              onClose={onCloseTanks}
              initialTankArray={selectedTanks}
            />
          )}
        </ModalContent>
      </Modal>
      {selectedTanks.map((item, index) => (
        <Chip key={index} radius="sm" classNames={{
          base: `border-small border-tdpldarkgrey bg-${item.batchName === "$empty" ? "tdpllightgrey" : "tdplsky"} border-opacity-60 m-1 p-0`,
          content: "font-bold"
        }}>{item.tankName}</Chip>
      ))}
    </Card>
    {selectedTanksBorderColor.includes("tdplmaroon") ?
      <p className='m-0 text-sm font-semibold text-tdplmaroon'>You need to select at least one tank.</p> :
      <p></p>}
    </>
  )
}