import { FC } from "react"
import useHomeStockStore from "../../store/home/useHomeStockStore"
import { postStock } from "../../api/tank/stock"
import { useDispatch } from "react-redux"
import { BaseModalFooter } from "../common/Modal"
import { useQueryClient } from "@tanstack/react-query"

type InitialInfoButtonProps = {
  onClose: () => void
  isBack: () => void
}

export const InitialInfoButton:FC<InitialInfoButtonProps> = ({
  onClose,
  isBack
}) => {
  const {setTanksInfoBorderColor, stockData, typeError } = useHomeStockStore()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  
  const chekTanksValuesBeforeFinish = () => {
    setTanksInfoBorderColor()
    if (stockData && stockData.every(item => item.count !== null && item.moveDate !== null) && !typeError) {
      postStock(stockData, dispatch, onClose).then( async () => {
        await queryClient.invalidateQueries({queryKey: ["undoable"]})
      })
    }
  }

  return <BaseModalFooter back="Back" next="Finish" backOnPress={()=>{onClose();isBack()}} nextOnPress={chekTanksValuesBeforeFinish}/>
}