import {ChangeEvent, FC, useEffect, useState} from "react";
import {Button, Card, CardBody} from "@nextui-org/react";
import saveIcon from "../../../assets/svg/tankInfo/refresh.svg"
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {patchTankMemo} from "../../../api/tankInfo/memoChange";
import {useTankInfoMemo} from "../../../hooks/tankInfo/useTankInfoValues";

export type MemoCardProps = {
  tankId: string
}


export const MemoCard: FC<MemoCardProps> = (
  {
    tankId,
  }
) => {
  const memoResponse = useTankInfoMemo(tankId)
  const memo = memoResponse ? memoResponse.memo : ""
  const [currentMemo, setCurrentMemo] = useState<string>(memo)
  const queryClient = useQueryClient()

  const onChangeMemo = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentMemo(e.target.value)
  }

  const {mutate} = useMutation<
    void, unknown, { tankId: string, memo: string }, null
  >({
    mutationFn: async (state) => await patchTankMemo(state.tankId, state.memo),
    onSuccess: async () => await queryClient.invalidateQueries({queryKey: ["tankInfo", "value", "memo", tankId]})
  })
  const onSave = () => {
    if (tankId === null) return
    mutate({tankId: tankId, memo: currentMemo})
  }

  useEffect(() => {
    setCurrentMemo(memo)
  }, [memo, setCurrentMemo]);

  return (
    <Card className="rounded-lg basis-[48.5%] outline outline-[1px] outline-tdpldarkgrey/[.60] bg-tdplbisque h-[140px] w-[253px] p-[19px]"
          style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
      {/*<CardHeader className="flex flex-row justify-between overflow-hidden "></CardHeader>*/}
      <CardBody className="m-0 p-0 h-[65px] overflow-hidden">
        <div className="flex flex-row justify-between overflow-hidden p-0 m-0">
          <p className="text-tdpldarkgrey text-[1rem] font-bold">
            Memo
          </p>
          <Button isIconOnly className="h-fit w-fit bg-tdplbisque min-w-0" size="sm">
            <img src={saveIcon} alt="" onClick={onSave}/>
          </Button>

        </div>
        <textarea className="m-0 mt-[10px] p-0 h-fit border-0 bg-tdplbisque font-sans text-wrap text-[1rem] break-words
        focus:outline-none resize-none"
                  rows={4} onChange={onChangeMemo} value={currentMemo}/>
      </CardBody>
    </Card>
  )
}