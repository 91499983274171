import {FC} from "react";
import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  Divider,
  ModalBody,
  ModalOverlay,
} from "@chakra-ui/react";
import {BookDemoForm} from "./BookDemoForm";


export const BookDemo: FC = () => {

  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <div onClick={onOpen} className="w-[15%] text-white text-[0.875rem] flex justify-center cursor-pointer">
        Want to request a Demo?
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader m={5} mb={0} pb={0}>
            <p className="font-bold text-tdplindigo text-[2rem]">Request a Demo</p>
          </ModalHeader>
          <ModalBody>
          <Divider orientation="horizontal" m={0} mb={5}/>
            <BookDemoForm onCloseModal={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
