import {FC} from "react";
import {
  ChartResponseBody,
  GetChartResponseType,
  QueryPropertyName,
  TimeUnitName
} from "../../../types/apiResponse/chartTypes";
import {convertIntToDate} from "../../../utils/chart/stringUtils";
import {chartBasicColors} from "../../../hooks/chart/useColorBySchool";
import {ResponsiveLine} from "@nivo/line";
import {TimeTooltip} from "./tooltips/TimeTooltip";

export type DoubleDomainPlotProps = {

  timeUnit: TimeUnitName
  properties: QueryPropertyName[]
  schoolColors: { [schoolId: string]: string }
  chartResponses: GetChartResponseType
}

export const DoubleDomainPlot: FC<DoubleDomainPlotProps> = (
  {
    timeUnit,
    properties,
    chartResponses,
    schoolColors
  }
) => {
  const lengthRatio = Math.floor(255 / properties.length)
  const colorOpacityHexDigits = properties.map((_, index)=> {
    const digit = (index + 1) * lengthRatio
    if (digit > 255) return "FF"
    else return digit.toString(16).toUpperCase()
  })
  const candidatesMinMax: { yMax: number, yMin: number, xMax: number, xMin: number }[] = []
  properties.forEach((property: QueryPropertyName) => {
    if (chartResponses[property] !== undefined) candidatesMinMax.push({
        yMax: (chartResponses[property] as ChartResponseBody).yMax,
        yMin: (chartResponses[property] as ChartResponseBody).yMin,
        xMax: (chartResponses[property] as ChartResponseBody).xMax,
        xMin: (chartResponses[property] as ChartResponseBody).xMin
      })
  })
  const chartData: { id: string, data: { x: Date, y: number }[], color: string }[] = []
  properties.forEach((property: QueryPropertyName, index: number) => {
    const chartResponse = chartResponses[property]
    if (chartResponse === undefined) return {}
    Object.entries(chartResponse.value).forEach((entry) => {
      const schoolId = entry[0]
      const points = entry[1].sort((a, b) => a.x < b.x ? -1 : 1)
      const values = points.map(({x, y}) => ({
        x: convertIntToDate(x),
        y: y
      }))
      chartData.push({
        id: schoolId + property,
        data: values,
        color: schoolColors[schoolId] !== undefined
          ? schoolColors[schoolId] + colorOpacityHexDigits[index]
          : chartBasicColors[Math.floor(Math.random() * 14)] + colorOpacityHexDigits[index]
      })
    })
  })
  const timeFormat =
    timeUnit === "day" ? "%a %d"
    : timeUnit === "week" ? "%b %d"
    : timeUnit === "month" ? "%B" : ""


  return (
    <ResponsiveLine
      data={chartData}
      margin={{top: 50, right: 50, bottom: 50, left: 70}}
      lineWidth={5}
      xScale={{
        type: 'time',
        min: convertIntToDate(Math.min(...candidatesMinMax.map(o => o.xMin))),
        max: convertIntToDate(Math.max(...candidatesMinMax.map(o => o.xMax)))
      }}
      yScale={{
        type: 'linear',
        min: Math.min(...candidatesMinMax.map(o => o.yMin)),
        max: Math.max(...candidatesMinMax.map(o => o.yMax)),
        reverse: false
      }}
      colors={d => d.color}
      axisBottom={{
        format: timeFormat,
        tickSize: 5,
        tickRotation: 30
      }}
      axisLeft={{
        tickSize: 5,
      }}
      curve={"monotoneX"}
      pointSize={0}
      enableSlices="x"
      enableGridX={false}
      enableGridY={false}
      sliceTooltip={(p) => <TimeTooltip item={p}/>}
    />
  )
}