import {DownloadCSVIcon} from "./svg/DownloadCSVIcon";
import {FC} from "react";
import useChartStore from "../../store/chart/useChartStore";
import {CSVLink} from "react-csv"


export type DownloadCSVProps = {
  csvData: object[],
  isActive: boolean
}

export const DownloadCSV: FC<DownloadCSVProps> = (
  {
    isActive,
    csvData
  }
) => {

  const {batchName} = useChartStore()

  return (
    <div className="mt-2">
      {
        isActive && csvData
          ? <CSVLink data={csvData} separator="," filename={`${batchName}.csv`}>
            <DownloadCSVIcon isActive={isActive}/>
          </CSVLink>
          : <DownloadCSVIcon isActive={false}/>
      }
    </div>
  )
}