import * as T from './types'

const initialState: T.TRState = {}

export const reducer = (state: T.TRState = initialState, action: T.TRActions) => {
  switch (action.type) {
    case '@TR/setTR':
      return action.payload
  }
  return state
}