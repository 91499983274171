import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import useChartStore from "../../store/chart/useChartStore";
import { Properties } from "./config";

export const SelectProperties = () => {
  const { properties, setProperties } = useChartStore();


  const onSelectionChange = (event: any) => {
      setProperties(Array.from(event))
  };

  return (
    <Dropdown className="flex ml-auto">
      <DropdownTrigger>
        <Button 
          variant="bordered" 
          className="font-bold capitalize border border-opacity-0 text-tdplprussian"
        >
          Properties ▾
        </Button>
      </DropdownTrigger>
    <DropdownMenu
      aria-label="Select Properties"
      selectionMode="multiple"
      closeOnSelect={false}
      selectedKeys={properties}
      onSelectionChange={onSelectionChange}
    >
      {Object.entries(Properties)
      .map((item) => {
        return (
          <DropdownItem className="text-tdplprussian" key={item[0]}>{item[1]}</DropdownItem>
        )
      })}
    </DropdownMenu>
    </Dropdown>
  );
}