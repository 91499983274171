import {GetHistogramResponseType} from "../../types/apiResponse/chartTypes";
import {chartBasicColors} from "../../hooks/chart/useColorBySchool";

export const makeTotalHistChartData = (
  histQueryResult: GetHistogramResponseType,
  schoolColors: { [id: string]: string }
) => {
  const labels = histQueryResult.labels
  if (labels.length===0){
    return {xTicks: [], chartData: []}
  }
  if (labels.length===1) {
    const newLabel = [parseFloat((labels[0]).toFixed(2)) -1, parseFloat((labels[0]).toFixed(2)), parseFloat((labels[0]).toFixed(2)) +1]
    const chartData = Object.entries(histQueryResult.schoolValues).map(entry => {
      const schoolId = entry[0]
      return {
        id: schoolId,
        data: [{x:newLabel[0], y:"0"}, {x:newLabel[1], y: "100.0"}, {x:newLabel[2], y: "0"}],
        color: schoolColors[schoolId] !== undefined ? schoolColors[schoolId] : chartBasicColors[Math.floor(Math.random() * 14)]
      }
    })
    return {
      xTicks: newLabel,
      chartData: chartData
    }
  }

  const chartData = Object.entries(histQueryResult.schoolValues).map(entry => {
    const schoolId = entry[0]
    const values = entry[1]
    const data = values.map(
      (v, i) => ({x: (labels[i] + labels[i + 1]) / 2, y: (v * 100).toFixed(2)})
    )
    return {
      id: schoolId,
      data: data,
      color: schoolColors[schoolId] !== undefined ? schoolColors[schoolId] : chartBasicColors[Math.floor(Math.random() * 14)]
    }
  })
  return {
    xTicks: labels,
    chartData: chartData
  }
}