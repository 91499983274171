import {useEffect, useState} from "react";

const ResolutionWidthLimit = 1152

export const useResolutionLimit = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [isUnderLimit, setIsUnderLimit] = useState(false)

  useEffect(() => {
    function handleWidthResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleWidthResize)
    handleWidthResize()
  }, []);

  useEffect(() => {
    if (windowWidth < ResolutionWidthLimit) setIsUnderLimit(true)
    else setIsUnderLimit(false)
  }, [windowWidth]);

  return {isUnderLimit}
}