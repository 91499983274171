import axios, {Axios, AxiosError, AxiosRequestConfig} from 'axios'
import {useAuthStore} from "../store/auth/useAuthStore";
import {logout, refresh} from "../api/axiosAuthorization";


// axios 인스턴스 생성
const client: Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
})
client.interceptors.request.use(
  (config) => {
    config.headers.Authorization = "Bearer " + useAuthStore.getState().token
    return config
  }
)
client.interceptors.response.use(
  (res) => res,
  async (err: AxiosError) => {
    const refreshToken = useAuthStore.getState().refreshToken
    if (err.response?.status === 401 && refreshToken !== null) {
      const refreshingResult = await refresh(refreshToken)
      if (refreshingResult.status === 200){
        useAuthStore.getState().refresh(refreshingResult.data.accessToken)
        const originalConfig = err.config!
        originalConfig.headers.Authorization = "Bearer " + useAuthStore.getState().token
        return axios.request(originalConfig)
      }
      else {
        logout()
        return Promise.reject(err)
      }
    } else return Promise.reject(err)
  }
)


function checkValidateStatus(status: number) {
  if (status === 404) return false
  else if (status === 401) return false
  else return status !== 400;
}

export const getData = async <T>(url: string, config?: AxiosRequestConfig) => {
  try {
    if (config === undefined) config = {validateStatus: checkValidateStatus}
    else (config.validateStatus = checkValidateStatus)
    const response = await client.get<T>(url, config);
    return response.data;
  } catch (error: unknown) {
    throw error
  }
};

export const postData = async <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
  try {
    if (config === undefined) config = {validateStatus: checkValidateStatus}
    else (config.validateStatus = checkValidateStatus)
    const response = await client.post<T>(url, data, config);
    return response.data;
  } catch (error: unknown) {
    throw error
  }
};

export const putData = async <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
  try {
    if (config === undefined) config = {validateStatus: checkValidateStatus}
    else (config.validateStatus = checkValidateStatus)
    const response = await client.put<T>(url, data, config);
    return response.data;
  } catch (error: unknown) {
    throw error
  }
};

export const deleteData = async <T>(url: string, config?: AxiosRequestConfig) => {
  try {
    if (config === undefined) config = {validateStatus: checkValidateStatus}
    else (config.validateStatus = checkValidateStatus)
    const response = await client.delete<T>(url, config);
    return response.data;
  } catch (error: unknown) {
    throw error
  }
};

export const patchData = async <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
  try {
    if (config === undefined) config = {validateStatus: checkValidateStatus}
    else (config.validateStatus = checkValidateStatus)
    const response = await client.patch<T>(url, data, config);
    return response.data;
  } catch (error: unknown) {
    throw error
  }
};