import {FC} from "react";
import {Card, Modal, ModalBody, ModalContent, ModalOverlay} from "@chakra-ui/react";
import {Button} from "@nextui-org/react";

export type BookDemoAlertProps = {
  disclosureProps: {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
  }
  isFailed: boolean,
  modalClose: () => void
}

export const BookDemoAlert: FC<BookDemoAlertProps> = (
  {
    disclosureProps,
    isFailed,
    modalClose
  }
) => {

  const onClickFailed = () => {
    disclosureProps.onClose()
    modalClose()
  }

  const onClickSucceed = () => {
    disclosureProps.onClose()
    modalClose()
  }
  return (
    <Modal isOpen={disclosureProps.isOpen} onClose={disclosureProps.onClose} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalBody>
          <div className="flex flex-col align-middle items-center">
            {
              isFailed
                ? <>
                  <p className="font-bold text-[1.25rem] my-2">Your request has been failed.</p>
                  <p className="text-[1rem] my-2">Please Submit once again.</p>
                  <Button className="text-tdplindigo font-bold bg-tdpldarkgrey w-[50%] my-2" onClick={onClickFailed}>
                    OK
                  </Button>
                </>
                : <>
                  <p className="font-bold text-[1.25rem] my-2">Your request has been submitted.</p>
                  <p className="text-[1rem] my-2">We are going to check your request and reply ASAP</p>
                  <Button className="text-white font-bold bg-tdplplum w-[50%] my-2" onClick={onClickSucceed}>
                    OK
                  </Button>
                </>
            }
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}