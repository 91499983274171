import { KeyboardEvent, MutableRefObject} from "react";
import { EventType } from "../../components/common/Input";

export const insertDataInGradeUpdate = <T>(
  event: EventType,
  tanksValues: Array<T>,
  setTanksValues: (tankValues: Array<T>) => void,
  setTanksInfoBorderColor?: (colorClassName: string) => void
) => {
  let tank_id: string = event.id
  let name: string = event.name
  let preValue = event.value
  let value: null | number
  if (preValue === "") {
    value = null
  } else {
    value = Number(preValue)
  }
  let newTanksValues = [...tanksValues]
  let newTankValue = newTanksValues[Number(tank_id)]
  newTanksValues[Number(tank_id)] = {
    ...newTankValue,
    [name]: value
  }
  setTanksValues(newTanksValues)
  if (setTanksInfoBorderColor) {
    setTanksInfoBorderColor("border-tdpldarkgrey border-opacity-60")
  }
}

export const keyDownChange = (
  event: KeyboardEvent<HTMLInputElement>,
  index:number,
  inputRefs:MutableRefObject<(HTMLInputElement | null)[]>
  ) => {
  if (event.key === "Enter") {
    inputRefs.current[index + 1]?.focus()
  }
}

export const continueJob = (onClose: () => void, onOpen: () => void) => {
  onClose()
  onOpen()
}