import {create} from "zustand";
import {SubmitTankArrayType, SubmitTankType} from "../../types/apiResponse/tankTypes";

export type SelectTankState = {
  selectedTankList: SubmitTankArrayType
}

export type SelectTankAction = {
  initialize: (initialTankList: SubmitTankArrayType) => void
  close: () => void
  attach: (tank: SubmitTankType) => void
  detach: (tankId: string) => void
  contains: (tankId: string) => boolean
}

export const useSelectTankStore = create<SelectTankState & SelectTankAction>((set, get) => ({
    selectedTankList: [],
    initialize: (initialTankList) => {
      set((state) => ({...state, selectedTankList: initialTankList}), true)
    },
    close: () => {
      set((state)=> ({...state, selectedTankList: []}), true)
    },
    attach: tank => {
      set(state => {
        const properBatchName = tank.batchId==="$empty"?"$empty":tank.batchId==="$unbatched"?"$unbatched":tank.batchName
        const attachingTarget: SubmitTankType = {...tank, batchName: properBatchName}
        return {...state, selectedTankList: [...state.selectedTankList, attachingTarget]}
      })
    },
    detach: tankId => {
      set(state => {
          const newSelectedTankList: SubmitTankArrayType = []
          state.selectedTankList.forEach(tank => {
            if (tank.tankId !== tankId) newSelectedTankList.push(tank)
          })
          return {...state, selectedTankList: newSelectedTankList}
        }
      )
    },
    contains: tankId => {
      let containSwitch: boolean = false
      get().selectedTankList.forEach(tank => {
        if (tank.tankId === tankId) containSwitch = true
      })
      return containSwitch
    }
  })
)