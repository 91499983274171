import { Card, Chip, Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import useHomeGradeStore from "../../store/home/useHomeGradeStore";
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes";
import { TRState } from "../../store/tankRelation";
import { SchoolState } from "../../store/school";
import { AppState } from "../../store";
import * as BatchStore from "../../store/batch";
import { SelectTank } from "../../pages/SelectTank";

type SelectDepatrueTankProps = {
  schoolIdList: TRState;
  storedSchoolList: SchoolState;
};

export const SelectDepatureTank: FC<SelectDepatrueTankProps> = ({
  schoolIdList,
  storedSchoolList,
}) => {
  const {
    setSelectedDepTanks,
    selectDepTanksBorderColor,
    selectedDepTanks,
  } = useHomeGradeStore();
  const {isOpen: isOpenDepTanks, onOpen: onOpenDepTanks, onOpenChange: onOpenChangeDepTanks} = useDisclosure();
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>((state) => state.batch);
  const batchIdList = Object.keys(storedBatchList).filter((batchId) => batchId !== "$empty" && batchId !== "$unbatched");

  const onSubmitDepTanksArray = (tankSelectedList: SubmitTankArrayType) => {
    if (tankSelectedList.length === 1) {
      setSelectedDepTanks(tankSelectedList, schoolIdList, storedSchoolList);
    }
  };
  return (
    <>
      <div className="mt-3 font-bold">Departure Tank</div>
      <Card
        key="departure"
        className={`flex flex-row flex-wrap justify-start p-1 ${selectDepTanksBorderColor} border-opacity-60 min-h-[3rem]`}
        radius="sm"
        shadow="sm"
        isPressable
        onPress={onOpenDepTanks}
      >
        <Modal
          isOpen={isOpenDepTanks}
          onOpenChange={onOpenChangeDepTanks}
          isDismissable={false}
          isKeyboardDismissDisabled={true}
          size="3xl"
        >
          <ModalContent>
            {(onCloseTanks) => (
              <SelectTank
                key="departure"
                submitTankArray={onSubmitDepTanksArray}
                batchIdList={[...batchIdList, "$unbatched"]}
                onClose={onCloseTanks}
                limit={1}
                initialTankArray={selectedDepTanks}
              />
            )}
          </ModalContent>
        </Modal>
        {selectedDepTanks.map((item, index) => (
          <Chip
            key={index}
            radius="sm"
            classNames={{
              base: "border-small border-tdpldarkgrey bg-tdplsky border-opacity-60 m-1 p-0",
              content: "font-bold",
            }}
          >
            {item.tankName}
          </Chip>
        ))}
      </Card>
      {selectDepTanksBorderColor.includes("tdplmaroon") ? (
        <p className="m-0 text-sm font-semibold text-tdplmaroon">
          Please select a Departure Tank.
        </p>
      ) : (
        <p></p>
      )}
    </>
  );
};
