import { Card, Chip, Modal, ModalContent, useDisclosure } from "@nextui-org/react"
import SelectTank from "../../pages/SelectTank"
import useHomeStockStore from "../../store/home/useHomeStockStore"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes"

export const SelectStockTanks = () => {
  const {selectedTanks, setSelectedTanks, selectTanksBorderColor} = useHomeStockStore()
  const {isOpen: isOpenTanks, onOpen: onOpenTanks, onOpenChange: onOpenChangeTanks} = useDisclosure();

  return (
    <>
    <div className='mt-3 font-bold'>Tanks to Stock</div>
    <Card className={`flex flex-row flex-wrap justify-start p-1 ${selectTanksBorderColor} border-opacity-60 min-h-[3rem]`} radius='sm' shadow="sm" isPressable onPress={onOpenTanks}>
      <Modal isOpen={isOpenTanks} onOpenChange={onOpenChangeTanks} isDismissable={false} isKeyboardDismissDisabled={true} size="3xl">
        <ModalContent>
          {(onCloseTanks) => (
            <SelectTank
              submitTankArray={(tankSelectedList: SubmitTankArrayType) => setSelectedTanks(tankSelectedList)}
              batchIdList={["$empty"]}
              onClose={onCloseTanks}
              initialTankArray={selectedTanks}
            />
          )}
        </ModalContent>
      </Modal>
      {selectedTanks.map((item, index) => (
        <Chip key={index} radius="sm" classNames={{base:'border-small border-tdpldarkgrey bg-tdpllightgrey border-opacity-60 m-1 p-0', content:"font-bold"}}>{item.tankName}</Chip>
      ))}
    </Card>
    {selectTanksBorderColor && selectTanksBorderColor.includes("tdplmaroon") 
    ? <p className='m-0 text-sm font-semibold text-tdplmaroon'>You need to select at least one empty tank.</p> 
    : <p> </p>}
    </>
  )
}