import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import avatarIcon from "../../assets/icons/avatar.png"
import {logout} from "../../api/axiosAuthorization";

export const UserAvatar = () => {
  return (
    <div className="mx-2">
      <Dropdown className="">
        <DropdownTrigger>
          <Avatar src={avatarIcon} alt=""/>
        </DropdownTrigger>
        <DropdownMenu aria-label="profile" variant="flat">
          <DropdownItem startContent className="text-tdplmaroon" key="logout" color="danger" onClick={logout}>
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
     </div>
  )
}