import SchoolCardContainer from "../components/home/SchoolCardContainer";
import {Stock, Grade} from "../components";
import {AddBatch} from "../components/addbatch";
import {Update} from "../components/update";
import EmptyCardContainer from "../components/home/EmptyCardContainer";
import {useSelector} from "react-redux";
import * as BatchStore from "../store/batch"
import * as ARStore from "../store/activeRelation"
import {AppState} from "../store";
import {UndoButton} from "../components/home/Undo";

export default function Home() {
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)
  const storedARList = useSelector<AppState, ARStore.ARState>(state => state.activeRelation)

  const createBatchBlocks = Object.keys(storedBatchList)
    .sort((batchIdBefore, batchIdAfter) => {
      if (storedBatchList[batchIdBefore].startDate < storedBatchList[batchIdAfter].startDate) return 1
      else return -1
    })
    .map((batchId) => {
    if (storedARList[batchId] === undefined || storedARList[batchId].length === 0) {
      return null
    }
    return batchId === "$empty" ? null : <SchoolCardContainer key={batchId} batchId={batchId}/>
  })
  return (
    <div className="p-[40px]">
      <div className="flex justify-between">
        <div>
          <h1 className="font-bold text-tdplindigo text-[2rem]">Home</h1>
          {/*<h1 className="text-tdplindigo ml-4 text-[2rem]">Home</h1>*/}
        </div>
        <div className="flex justify-evenly">
          <Update/>
          <Grade/>
          <AddBatch/>
          <Stock/>
          <UndoButton/>
        </div>
      </div>
      <div>
        {createBatchBlocks}
      </div>
      <div>
        <EmptyCardContainer/>
      </div>
    </div>
  )
}