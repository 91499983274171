import {FC} from "react";
import LoginBackgroundImage from "../../assets/image/LoginBackground.png";

export const UnderLimit: FC = () => {
 return (
   <div className="relative w-[100%] h-[100dvh] z-999">
     <img className="absolute w-[100%] h-[100dvh] object-fill z-0" src={LoginBackgroundImage} alt=""/>
     <div className="absolute w-[100%] h-[100dvh] flex flex-col justify-center items-center">
       <div className="w-[315px] h-[362px] flex flex-col justify-center -mt-[45px]">
         <div className="w-[280px] flex flex-col items-center">
           <p className="h-[140px] text-[8rem] font-bold text-white align-top">Sorry,</p>
           <p className="text-[1.875rem] h-fit font-bold p-0 text-white text-center mt-[45px]">can't support small window width</p>
         </div>
         <div className="w-[280px] mt-10">
           <p className="text-[14px] text-white text-center">
             Please resize up your browser window horizontally or use another device
           </p>
         </div>
       </div>
     </div>
   </div>
 )
}