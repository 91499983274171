import {Card, CardBody} from "@nextui-org/react";

import {EmptyTankResponseType} from "../../types/apiResponse/tankTypes";
import React from "react";
import {useTankInfoModalStore} from "../../store/tankInfo/useTankInfoModalStore";

export default function EmptyCard({tankName, tankId}: EmptyTankResponseType) {
  const {open, setTank} = useTankInfoModalStore()
  const onOpenTankInfo = () => {
    setTank(tankId, tankName)
    open()
  }

  return (
    <Card className="bg-tdpllightgrey border-1 border-tdpldarkgrey border-opacity-60"
          style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}} isPressable isHoverable onPress={onOpenTankInfo}>
      <CardBody>
        <p className="text-tdpldark font-bold">{tankName}</p>
        <p className="hidden">{tankId}</p>
      </CardBody>
    </Card>
  )
}