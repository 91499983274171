import {FC, useEffect} from 'react'
import {ModalContent, ModalBody, ModalFooter} from "@nextui-org/react"
import { useSelector} from 'react-redux';
import {AppState} from '../../store';
import * as ARStore from "../../store/activeRelation"
import * as TankStore from "../../store/tank"
import useHomeEditBatchStore from '../../store/home/useHomeEditBatchStore';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { InputBatchName } from './InputBatchName';
import { SelectEditBatchTanks } from './SelectEditBatchTanks';
import { SelectTanksEditBatchButton } from './SelectTanksEditBatchButton';
import { CustomDatePicker } from '../common/Input';
import { ModalText } from '../common/Text';

export interface SelectTanksGradeProps {
  isOpen: boolean
  onOpenChange: () => void
  onOpen: () => void
}

export const SelectTanksEditBatch: FC<SelectTanksGradeProps> = ({
  isOpen,
  onOpenChange,
  onOpen
}) => {
  const {
    batchName,
    batchId, 
    setSelectedTanks,
    startDate,
    setStartDate,
    startDateBorderColor
  } = useHomeEditBatchStore()

  const storedUnBatchedARList = useSelector<AppState, ARStore.ARState>(state => state.activeRelation)
  const storedTankList = useSelector<AppState, TankStore.TankState>(state => state.tank)

  useEffect(() => {
    if (storedUnBatchedARList && storedTankList && batchId && batchName) {
      let bathTanksList = storedUnBatchedARList[batchId].map((item) => {
        return {
          tankName: storedTankList[item.tankId]?.tankName,
          tankId: item.tankId,
          batchId: batchId,
          batchName: batchName
        }
      })
      setSelectedTanks(bathTanksList)
    }
  }, [storedUnBatchedARList, storedTankList, batchName, batchId, setSelectedTanks])

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"} isDismissable={true}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Edit Batch Information' description={ModalText('Edit Batch Information')}/>
            <ModalBody className='gap-1 min-h-[20rem] pr-10 pl-10'>
              <BaseDivider/>
              <InputBatchName/>
              <CustomDatePicker label='Start Date' value={startDate} setValue={setStartDate} dateBorderColor={startDateBorderColor}/>
              <SelectEditBatchTanks/>
            </ModalBody>
            <ModalFooter className='pb-8 pl-10 pr-10'>
              <SelectTanksEditBatchButton onClose={onClose} onOpen={onOpen} />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
}