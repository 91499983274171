export const ThemeConfig = {
  inactiveEdgeColor: "#092028",
  inactiveNodeColor: "#C8DAE1", 
  activeEdgeColor: "rgb(0,0,255)",
  activeNodeColor: "rgb(0,235,0)",
  unitSize: 100,
  heightSize: 0.5,// unitSize * 0.5,
  widthSize: 3,
  rawSize: 1.5
}

export const Properties = {
  averageWeight: "Average Weight",
  sampleWeight: "Sample Weight",
  feedingAmount: "Feed Amount",
  count: "Count",
  biomass: "Biomass",
  nh3no2: "NH3 & NO2",
}