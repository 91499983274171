import { FC } from "react"
import useHomeEditBatchStore from "../../store/home/useHomeEditBatchStore"
import { postEditBatch } from "../../api/batch/editBatch"
import { useDispatch } from "react-redux"
import { BaseModalFooter } from "../common/Modal"
import { useQueryClient } from "@tanstack/react-query"

type InitialInfoButtonProps = {
  onClose: () => void
  isBack: () => void
}

export const InitialInfoButton:FC<InitialInfoButtonProps> = ({
  onClose,
  isBack,
}) => {
  const { setTanksInfoBorderColor, filledrequired, editBatchData, batchId, typeError} = useHomeEditBatchStore()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  
  const checkTanksValuesBeforeFinish = () => {
    setTanksInfoBorderColor()
    if (filledrequired && editBatchData.batchName && editBatchData.startDate && batchId && !typeError) {
      postEditBatch(editBatchData, batchId, dispatch, onClose).then( async () => {
        await queryClient.invalidateQueries({queryKey: ["undoable"]})
      })
    }
  }

  return <BaseModalFooter back="Back" next="Finish" backOnPress={()=>{onClose();isBack()}} nextOnPress={checkTanksValuesBeforeFinish}/>

}