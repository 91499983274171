import { create } from "zustand";
import { GradeTankValuesArrayType, SubmitTankArrayType } from "../../types/apiResponse/tankTypes";
import { TRState } from "../tankRelation";
import { SchoolState } from "../school";
import { gradeDataType } from "../../api/tank/grade";
import { EventType } from "../../components/common/Input";

type HomeGradeState = {
  gradeDate: number | null
  tanksValues: GradeTankValuesArrayType
  selectedDepTanks: SubmitTankArrayType
  depBatchId: string | null
  departureTankId: string | null
  departureSchoolId: string | null
  selectedArrTanks: SubmitTankArrayType
  filledTanks: string[]
  selectDepTanksBorderColor: string
  selectArrTanksBorderColor: string
  gradeDateBorderColor: string
  depCount: number | null
  sendGradeData: gradeDataType | null
  sumCount: number
  tanksInfoBorderColor: string,
  typeError: boolean
  requiredError: boolean
};

type HomeGradeAction = {
  setGradeDate: (gradeDate: number | null) => void
  setSelectedDepTanks: (selectedDepTanks:SubmitTankArrayType, schoolIdList:TRState, storedSchoolList:SchoolState) => void
  setDepBatchId: (depBatchId: string|null) => void
  setDepartureTankId: (departureTankId:string) => void
  setSelectedArrTanks: (selectedArrTanks:SubmitTankArrayType) => void
  setArrTanksValues: (schoolIdList:TRState, storedSchoolList:SchoolState) => void
  setFilledTanks: (tank:string) => void
  setArrBorderColor: () => void
  setDepBorderColor: () => void
  setGradeDateBorderColor: () => void
  reset: () => void
  setSendGradeData: () => void
  setSumCount: (num:number) => void
  insertData: (event:EventType) => void
  setTanksInfoBorderColor: () => void
};

const initialState: HomeGradeState = {
  gradeDate: null,
  tanksValues: [],
  selectedDepTanks:[],
  depBatchId: null,
  departureTankId: null,
  departureSchoolId: null,
  selectedArrTanks: [],
  filledTanks: [],
  selectDepTanksBorderColor: "border border-tdpldarkgrey",
  selectArrTanksBorderColor: "border border-tdpldarkgrey",
  gradeDateBorderColor: "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60",
  depCount: null,
  sendGradeData: null,
  sumCount: 0,
  tanksInfoBorderColor: "border border-tdpldarkgrey",
  typeError: false,
  requiredError: false
}

const useHomeGradeStore = create<HomeGradeState & HomeGradeAction>(
  (set, get) => ({
    ...initialState,
    setGradeDate: (gradeDate) => {
      set(() => ({
        gradeDate: gradeDate,
        gradeDateBorderColor: 
        "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
      }))
    },
    setSelectedDepTanks: (selectedDepTanks, schoolIdList, storedSchoolList) => {
      set((state) => ({
        selectedDepTanks:selectedDepTanks,
        depBatchId: selectedDepTanks[0].batchId,
        depCount: storedSchoolList[schoolIdList[selectedDepTanks[0].tankId][0].schoolId].count ?? null,
        departureTankId: selectedDepTanks[0].tankId ?? null,
        departureSchoolId: schoolIdList[selectedDepTanks[0].tankId][0].schoolId ?? null,
        selectDepTanksBorderColor: selectedDepTanks.length === 1? "border border-tdpldarkgrey": state.selectDepTanksBorderColor,
        selectedArrTanks: []
      }))
    },
    setDepBatchId: (depBatchId) => {
      set(() => ({depBatchId:depBatchId}))
    },
    setDepartureTankId: (departureTankId) => {
      set(() => ({departureTankId:departureTankId}))
    },
    setSelectedArrTanks: (selectedArrTanks) => {
      set((state) => ({
        filledTanks: selectedArrTanks.filter(item => item.batchId !== "$empty").map(item => item.tankId),
        selectedArrTanks: selectedArrTanks,
        selectArrTanksBorderColor: selectedArrTanks.length !== 0? "border border-tdpldarkgrey": state.selectArrTanksBorderColor
      }))
    },
    setFilledTanks: (tank) => {
      set((state) => ({filledTanks: [...state.filledTanks, tank]}))
    },
    setArrTanksValues: (schoolIdList, storedSchoolList) => {
      set((state) => ({
        tanksValues: storedSchoolList && schoolIdList? state.selectedArrTanks.map((item) => {
          let schoolData = schoolIdList[item.tankId]? storedSchoolList[schoolIdList[item.tankId][0].schoolId] : null
          return {
            tankId: item.tankId,
            batchName: item.batchName,
            tankName: item.tankName,
            count: null,
            moveDate: item.batchName === "$empty" || state.departureTankId === item.tankId? state.gradeDate 
            : schoolData && schoolData.moveDate? schoolData.moveDate: null,
            sampleWeight: null,
            resultingCount: item.batchName === "$empty"? 0
            : item.tankId === state.departureTankId? 0
            : schoolData && schoolData.count? schoolData.count : 0,
            existCount: item.batchName === "$empty" || state.departureTankId === item.tankId? 0
            : schoolData && schoolData.count? schoolData.count : 0
          }
        }) : [],
        sumCount: 0
    }))
    },
    setArrBorderColor: () => {
      set((state) => ({selectArrTanksBorderColor: state.selectedArrTanks.length !== 0? "border border-tdpldarkgrey":"border-2 border-tdplmaroon"}))
    },
    setDepBorderColor: () => {
      set((state) => ({selectDepTanksBorderColor: state.selectedDepTanks.length !== 0? "border border-tdpldarkgrey": "border-2 border-tdplmaroon"}))
    },
    setGradeDateBorderColor: () => {
      set((state) => ({
        gradeDateBorderColor: state.gradeDate
        ? "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
        : "[&>div]:border-2 [&>div]:border-tdplmaroon [&>div]:hover:border-tdplmaroon [&>div]:border-opacity-60"
      }))
    },
    setSumCount: (num) => {
      set((state) => ({sumCount: Number(state.sumCount) + num}))
    },
    reset: () => {
      set(initialState)
    },
    setSendGradeData: () => {
      set((state) => ({
        sumCount: Number(state.tanksValues.map(item => item.count).reduce((acc, count) => Number(acc) + Number(count), 0)),
        sendGradeData: state.departureTankId? {
          departureTankId: state.departureTankId,
          arrivalTankList: state.tanksValues.map((item) => {
            return {
              tankId: item.tankId,
              count: item.count,
              moveDate: item.moveDate,
              sampleWeight: item.sampleWeight
            }
          }),
        } : null
      }))
    },
    insertData: (event) => {
      set((state) => {
        const newTanksValues = [...state.tanksValues]
        const newtankValue = newTanksValues[Number(event.id)]
        newTanksValues[Number(event.id)] = {
          ...newtankValue,
          [event.name] : event.value? Number(event.value):null,
          resultingCount: event.name === "count"?
            Number(newtankValue.existCount) + Number(event.value)
            : newtankValue.resultingCount
        }
        const typeError = !newTanksValues.every(item=> item.count === null || Number.isInteger(item.count))
        return {
          tanksValues: newTanksValues,
          tanksInfoBorderColor:typeError?"border-2 border-tdplmaroon":"border border-tdpldarkgrey",
          typeError:typeError,
          requiredError: false
        }
      })
    },
    setTanksInfoBorderColor: () => {
      set((state) => {
        const requiredError = !state.tanksValues.every(item=>item.count !== null && item.count > 0)
        return {
        tanksInfoBorderColor:requiredError || state.typeError?
        "border-2 border-tdplmaroon"
        : "border border-tdpldarkgrey",
        requiredError:requiredError
      }})
    }
  }),
)

export default useHomeGradeStore;
