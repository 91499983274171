import {FC, useEffect, useState} from "react";
import MeasureGauge from "../MeasureGauge";
import {CardNameLiteral, GaugeSetting} from "../../../types/apiResponse/tankInfoSettingTypes";
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {makeTitle} from "../../../utils/tankInfo";
import {useTankInfoGauge} from "../../../hooks/tankInfo/useTankInfoValues";
import {AlertIcon} from "../svg/AlertIcon";
import {convertMetric} from "../../../utils/metricConvert";
import {NoContent} from "./NoContent";


export type GaugeProps = {
  tankId: string
  cardName: CardNameLiteral
  gaugeSetting: GaugeSetting
}

export const GaugeCard: FC<GaugeProps> = (
  {
    tankId,
    cardName,
    gaugeSetting,
  }
) => {
  const tankInfoGauge = useTankInfoGauge(tankId, cardName)
  const {val, metric} = convertMetric(
    tankInfoGauge?tankInfoGauge[cardName]?.curValue:undefined,
    cardName,
    gaugeSetting?gaugeSetting.metric:""
  )
  const curValue = tankInfoGauge ? tankInfoGauge[cardName]?.curValue : undefined
  const isStationary = curValue === undefined || curValue === null || (curValue > gaugeSetting.minWarning && curValue < gaugeSetting.maxWarning)
  const {title, subTitle} = makeTitle(cardName)
  const [isToday, setIsToday] = useState<boolean>(!(curValue === undefined || curValue === null))

  useEffect(() => {
    if (tankInfoGauge !== undefined && tankInfoGauge !== null) {
      if (tankInfoGauge[cardName] !== undefined) {
        const cardResponse = tankInfoGauge[cardName]
        if (cardResponse !== undefined) setIsToday(cardResponse.isToday)
      }
    }
  }, [tankInfoGauge]);

  return (
    <Card className={`rounded-lg col-span-1 w-[253px] h-[140px] p-[19px] pr-0 outline
        ${isStationary ? "outline-[1px]" : "outline-[3px]"}
        ${isStationary ? "outline-tdpldarkgrey/[.60]" : "outline-tdplmaroon"}
        ${isStationary ? isToday ? "bg-tdplbisque" : "bg-tdpllightgrey" : "bg-tdplmaroon bg-opacity-10"}
        `} style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
      <CardHeader className="flex flex-row items-start m-0 p-0 mb-[15px] h-[22px]">
        {isToday ? null : <div className="mt-[6px] mr-1"><AlertIcon/></div>}
        <p className={`${isStationary ? "text-tdpldarkgrey" : "text-tdplmaroon"} text-[1rem] font-bold mr-2`}>{title}</p>
        {
          subTitle !== undefined
            ? <p className={`${isStationary ? "text-tdplmiddlegrey" : "text-tdplmaroon text-opacity-30"} text-[1rem]`}>
              {subTitle}
            </p>
            : null
        }
      </CardHeader>
      <CardBody className="mt-0 m-0 p-0 h-[65px] overflow-hidden">
        {
          val === "---"
            ? <NoContent/>
            : <div className="flex flex-row justify-between">
              <div className="flex flex-col items-start w-[115px]">
                <p className={`text-[1.75rem] font-bold ${isStationary ? "text-tdplprussian" : "text-tdplmaroon"}`}>
                  {val}
                </p>
                <p className="text-[0.875rem] text-tdplprussian">
                  {metric? metric : null}
                </p>
              </div>
              <div className="w-[100px] mr-[19px]">
                <MeasureGauge
                  value={curValue}
                  minWarning={gaugeSetting.minWarning}
                  maxWarning={gaugeSetting.maxWarning}
                  minLimit={gaugeSetting.minLimit}
                  maxLimit={gaugeSetting.maxLimit}
                />
              </div>
            </div>
        }
      </CardBody>
    </Card>
  )
}