import {FC} from "react";

export type BarSVGProps = {
    greyStart: number
    greyEnd: number
    redEnd: number
}

export const BarSVG: FC<BarSVGProps> = ({
    greyStart,
    greyEnd,
    redEnd,
}) => {
    return (
        <svg width="100" height="14" viewBox="0 0 100 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_930_135)">
                <rect width="100" height="14" rx="5" fill="#D9D9D9"/>
                <rect width={`${greyStart}`} height="16" fill="#E0ABAB"/>
                <rect x={`${greyEnd}`} width={`${redEnd}`} height="16" fill="#E0ABAB"/>
            </g>
            <defs>
                <clipPath id="clip0_930_135">
                    <rect width="100" height="14" rx="5" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
