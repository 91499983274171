import { Button, useDisclosure } from "@nextui-org/react"
import useHomeGradeStore from "../../store/home/useHomeGradeStore"
import { FC } from "react"
import { CommonAlert } from "../common/Alert"
import { continueJob } from '../../utils/selectTank/common';


type SelectTanksContinueProps = {
  onClose:()=>void
  onOpen:()=>void
}

export const SelectTanksContinue: FC<SelectTanksContinueProps> = ({
  onClose,
  onOpen,
}) => {
  const {isOpen: isOpenNotEmptyAlert, onOpen: onOpenNotEmptyAlert, onClose: onCloseNotEmptyAlert} = useDisclosure()
  const {isOpen: isOpenEqualAlert, onOpen: onOpenEqualAlert, onClose: onCloseEqualAlert} = useDisclosure()
  const {setArrBorderColor, setDepBorderColor, setGradeDateBorderColor, tanksValues, filledTanks, departureTankId} = useHomeGradeStore()

  const checkTanksBeforeContinue = (onClose:()=>void, onOpen:()=>void, onOpenNotEmptyAlert: ()=>void, onOpenEqualAlert: ()=>void) => {
    setArrBorderColor()
    setDepBorderColor()
    setGradeDateBorderColor()
    if (tanksValues.length !== 0) {
      if (filledTanks.length !== 0 && departureTankId && !filledTanks.includes(departureTankId)) {
        onOpenNotEmptyAlert()
      } else if ( departureTankId && filledTanks.includes(departureTankId)) {
        onOpenEqualAlert()
      } else {
        continueJob(onClose, onOpen)
      }
    }
  }

  return (
    <>
      <Button className="font-bold text-white w-[6rem] mr-0 bg-tdplplum" onPress={() => {
        checkTanksBeforeContinue(onClose, onOpen, onOpenNotEmptyAlert, onOpenEqualAlert)
      }}>
        Continue
      </Button>
      <CommonAlert key="DepEqualArrAlert" isOpenAlert={isOpenEqualAlert} onCloseAlert={onCloseEqualAlert} onClose={onClose}
        onOpen={onOpen} continueJob={continueJob} 
        title={
          <div>
            <p>The Departure tank was</p>
            <p>selected as the Arrival!</p>
          </div>
        }
        children={<p>
          The graded fish will be restocked into the Departure Tank. Do you want to continue?
        </p>}/>
      <CommonAlert key="EmptyTanksAlert" isOpenAlert={isOpenNotEmptyAlert} onCloseAlert={onCloseNotEmptyAlert} onClose={onClose}
      onOpen={onOpen} continueJob={continueJob} title='Tanks are not empty'
      children={
        <>
          <p>Some tanks you selected are not empty,</p>
          <p>do you want to add more fish?</p>
        </>
      }/>
    </>
  )
}