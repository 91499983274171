import {useEffect, useState} from "react";
import {CameraDetailResponseType} from "../../types/apiResponse/liveTypes";
import {getCameraDetail} from "../../api/live";

export const useSingleLive = (tankId: string | null, initialPosition: string | null, initialHlsUri: string | null) => {
  const [cameraPosition, setCameraPosition] = useState<string | null>(initialPosition)
  const [hlsUri, setHlsUri] = useState<string | null>(initialHlsUri)
  const [cameraDetail, setCameraDetail] = useState<CameraDetailResponseType | null>(null)


  useEffect(() => {
    if (tankId !== null) {
      setCameraPosition(initialPosition)
      setHlsUri(initialHlsUri)
      getCameraDetail(tankId, (cameraDetail) => {
        if (cameraDetail&&cameraDetail.leftCamera) {
          setHlsUri(cameraDetail.leftCamera.hlsUri)
          setCameraPosition("left")
        } else if (cameraDetail&&cameraDetail.rightCamera) {
          setHlsUri(cameraDetail.rightCamera.hlsUri)
          setCameraPosition("right")
        }
        setCameraDetail(cameraDetail)
      })
    }
  }, [tankId, initialHlsUri, initialPosition]);

  useEffect(() => {
    switch (cameraPosition) {
      case "left":
       if (cameraDetail&&cameraDetail.leftCamera) setHlsUri(cameraDetail.leftCamera.hlsUri)
        break
      case "right":
        if (cameraDetail&&cameraDetail.rightCamera) setHlsUri(cameraDetail.rightCamera.hlsUri)
        break
      case "top":
        if (cameraDetail&&cameraDetail.topCamera) setHlsUri(cameraDetail.topCamera.hlsUri)
        break
    }
  }, [cameraPosition, cameraDetail]);

  return {hlsUri, cameraPosition, setCameraPosition, cameraDetail}
}