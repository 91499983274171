import { Button, Card, CardBody, ModalContent, useDisclosure } from "@nextui-org/react"
import { BaseModal } from "../common/Modal"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes"
import { PositionDropdown } from "./DropDown"
import { FC, useState } from "react"
import cross from '../../assets/icons/cross.png'
import { camListArrayType, camListType } from "../../types/props/live"
import {PressEvent} from "@react-types/shared";
import { cameraListUpdater, removeCamera } from "../../utils/live/cameraPositionFuncs"
import {SelectTank} from '../../pages/SelectTank';

type SelectPreviewTankProps = {
  loc:number
  item:camListType
  i:number
  index:number
  cameraList:camListArrayType
  setCameraList:(camList:camListArrayType) => void
  batchIdList:string[]
}

export const SelectPreviewTank:FC<SelectPreviewTankProps> = ({
  loc,
  item,
  i,
  index,
  cameraList,
  setCameraList,
  batchIdList
}) => {
  const {isOpen: isOpenTanks, onOpen: onOpenTanks, onOpenChange: onOpenChangeTanks} = useDisclosure();
  const [isEnter, setIsEnter] = useState<boolean>(false)
  
  const selectCamera = (event: PressEvent) => {
    onOpenTanks()
    // setSelectedLoc(event.target.id)
  } 

  return (
    <Card key={loc} onMouseEnter={() => setIsEnter(true)} onMouseLeave={()=>setIsEnter(false)} shadow='sm' className='m-0 border shadow-none border-tdpldarkgrey border-opacity-60'>
      <Button
      hidden={isEnter && item.tankName? false:true}
      size="sm"
      className='absolute right-2 top-2 z-10 w-[1rem] h-[1rem] min-w-0 min-h-0 bg-opacity-0' 
      isIconOnly 
      onClick={() => removeCamera(loc, cameraList, setCameraList)}
      >
        <img className='object-scale-down w-[1rem] h-[1rem]' src={cross} alt=""/>
      </Button>
      <CardBody className="py-2 overflow-visible">
        <Button
          key={loc}
          id={String(loc)}
          size="sm"
          className={`font-bold border border-tdpldarkgrey border-opacity-60 bg-tdplsky ${item.tankName?"":"text-opacity-40"}`}
          style={{
            width: "fit-content",
            alignSelf: 'center',
            fontSize: "1rem",
            marginBottom: "0.5rem"
          }}
          onPress={selectCamera}
        >
          {item.tankName ? item.tankName : "Select Tank"}
        </Button>
        <BaseModal isOpen={isOpenTanks} onOpenChange={onOpenChangeTanks} size={"3xl"} backdrop={'transparent'}>
          <ModalContent>
            {(onCloseTanks) => (
              <SelectTank
                key={loc}
                submitTankArray={(tankSelectedList: SubmitTankArrayType) => cameraListUpdater(
                  loc,
                  tankSelectedList,
                  cameraList,
                  setCameraList
                )}
                batchIdList={batchIdList}
                onClose={onCloseTanks}
                limit={1}
              />
            )}
          </ModalContent>
        </BaseModal>
        <PositionDropdown splitIndex={i} arrIndex={index} item={item}/>
      </CardBody>
    </Card>
  )
}