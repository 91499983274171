import {FC, useEffect} from 'react';
import {Modal, ModalContent, ModalBody, ModalFooter, Button, useDisclosure, Card, Divider} from "@nextui-org/react"
import { Icon } from './Icon';
import { CameraPosition } from './CameraPosition';
import { postLive } from '../../api/live';
import { cameraMapType, videoMapResponseType } from '../../types/apiResponse/liveTypes';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import * as BatchStore from "../../store/batch"
import { SetGrid } from './SetGrid';
import useCustomizeLiveStore from '../../store/live/useCustomizeLiveStore';
import { BaseModalHeader } from '../common/Modal';
import { ModalText } from '../common/Text';

export type CustomizeLiveProps = {
  setIsUpdated: () => void
  videoMap: videoMapResponseType | null
}

export const CustomizeLive: FC<CustomizeLiveProps> = ({
  setIsUpdated, videoMap
}) => {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)
  const batchIdList = Object.keys(storedBatchList).filter(batchId => batchId !== "$empty" && batchId !== "$unbatched")
  const {setVideoMap, saveData} = useCustomizeLiveStore()

  useEffect(() => {
    if (videoMap !== null) {
      setVideoMap(videoMap)
    }
  }, [videoMap, isOpen, setVideoMap])


  const saveCustomizeLive = (onClose:() => void, saveData:cameraMapType | null) => {
    if (saveData) {
      postLive(saveData, setIsUpdated, onClose).then(() => {})
    }
  }

  return (
    <>
      <Button onPress={onOpen} isIconOnly className='bg-backgroud'><Icon name="edit"/></Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} size="4xl" >
        <ModalContent>
          {(onClose) => (
            <>
            <BaseModalHeader title='Customize Live' description={ModalText('Customize Live')}/>
              <ModalBody className='pl-10 pr-10'>
                <Divider className="my-4" style={{ margin: "1px" }}/>
                <SetGrid/>
                <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>Preview</div>
                <Card className="py-4 bg-tdplbisque" style={{padding:"1rem", height:"100%"}}>
                  <CameraPosition batchIdList={batchIdList}/>
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button className="mr-0 font-bold text-white pr-7 pl-7 bg-tdplplum"onPress={()=> saveCustomizeLive(onClose, saveData)}>
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

