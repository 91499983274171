import {FC, useEffect, useState} from "react";
import {Menu, MenuButton, MenuList, MenuItem, Button} from "@chakra-ui/react";
import {CameraDetailResponseType} from "../../types/apiResponse/liveTypes";

export type CameraDropdownProps = {
  initialPosition: string | null
  cameraDetail: CameraDetailResponseType | null
  setCameraPosition: (cameraPosition: string) => void
}

export const CameraDropdown: FC<CameraDropdownProps> = (
  {
    initialPosition,
    cameraDetail,
    setCameraPosition
  }
) => {
  const [curPosition, setCurPosition] = useState<string|null>(initialPosition)
  const [topCameraAvailable, setTopCameraAvailable] = useState<boolean>(false)
  const [leftCameraAvailable, setLeftCameraAvailable] = useState<boolean>(false)
  const [rightCameraAvailable, setRightCameraAvailable] = useState<boolean>(false)
  const cameraOnActive = cameraDetail !== null
  const onPositionChange = (key: string) => {
    setCameraPosition(key)
    setCurPosition(key)
  }

  useEffect(() => {
    if (cameraDetail?.topCamera?.hlsUri) {
      setTopCameraAvailable(true)
    }
    if (cameraDetail?.leftCamera?.hlsUri) {
      setLeftCameraAvailable(true)
    }
    if (cameraDetail?.rightCamera?.hlsUri) {
      setRightCameraAvailable(true)
    }
  }, [cameraDetail]);

  useEffect(() => {
    setCurPosition(initialPosition)
  }, [initialPosition]);

  return (
    <Menu closeOnSelect={true}>
      <MenuButton as={Button} width='100px' height='30px' variant="bordered"
                  className="capitalize bg-white border-tdpldarkgrey border-1 border-opacity-60"
                  isDisabled={!cameraOnActive}
      >
        <p className="font-normal text-tdplindigo text-[1rem]">
          {curPosition ? curPosition : cameraOnActive ? "Not Selected" : "No Cams"}
        </p>
      </MenuButton>
      <MenuList>
        {topCameraAvailable ? <MenuItem key={"top"} onClick={()=>onPositionChange("top")}>Top</MenuItem>:null}
        {leftCameraAvailable?<MenuItem key={"left"} onClick={()=>onPositionChange("left")}>Left</MenuItem>:null}
        {rightCameraAvailable?<MenuItem key={"right"} onClick={()=>onPositionChange("right")}>Right</MenuItem>:null}
      </MenuList>
    </Menu>
  )
}