import {combineReducers} from "redux";
import * as Batch from './batch'
import * as AR from './activeRelation'
import * as TR from './tankRelation'
import * as Tank from './tank'
import * as School from './school'

export const rootReducer = combineReducers({
  batch: Batch.reducer,
  activeRelation: AR.reducer,
  tankRelation: TR.reducer,
  tank: Tank.reducer,
  school: School.reducer,
})