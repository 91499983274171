import {FC, useEffect, useState} from 'react';
import {camListArrayType} from "../../types/props/live";
import useCustomizeLiveStore from '../../store/live/useCustomizeLiveStore';
import { getAllCameras } from '../../api/live';
import { SelectPreviewTank } from './SelectTank';

export interface CameraPositionProps {
  batchIdList: string[]
}

export const CameraPosition: FC<CameraPositionProps> = ({
    batchIdList
}) => {
  const [splitArray, setSplitArray] = useState<camListArrayType[]>([[]])
  const { setAllCameras, allCamerasMap, cameraGrid, cameraList, setCameraList, setSaveData } = useCustomizeLiveStore()

  useEffect(() => {
    if (!allCamerasMap) {
      getAllCameras(setAllCameras)
    }
  }, [setAllCameras, allCamerasMap])

  function splitArrayD(cameraList: camListArrayType, cameraGrid: String[]) {
    const chuncked: camListArrayType[] = []
    let index = 0
    while (index < (Number(cameraGrid[0]) * Number(cameraGrid[1]))) {
      chuncked.push(cameraList.slice(index, index + Number(cameraGrid[0])))
      index += Number(cameraGrid[0])
    }
    setSplitArray(chuncked)
  }

  useEffect(() => {
    splitArrayD(cameraList, cameraGrid)
    setSaveData()
  }, [cameraGrid, cameraList, setSaveData])

  return (
    <>
      {splitArray.map((arr, i) => (
        <div key={i} className={'grid gap-2 grid-flow-col p-1'} style={{gridTemplateColumns:`repeat(${cameraGrid[0]}, 1fr)`}}>
          {arr.map((item, index) => (
            <SelectPreviewTank
              key={(i) * Number(cameraGrid[0]) + index}
              loc={(i) * Number(cameraGrid[0]) + index} 
              item={item} 
              i={i} 
              index={index}
              cameraList={cameraList}
              setCameraList={setCameraList} 
              batchIdList={batchIdList}/>
          ))}
        </div>
      ))}
    </>
  )
}