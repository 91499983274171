import {FC, useEffect, useState} from "react";
import {Button, Card, CardBody} from "@nextui-org/react";
import {useDisclosure} from "@chakra-ui/react";
import {BookDemoInputForm} from "./BookDemoInputForm";
import {PressEvent} from "@react-types/shared";
import {postBookDemo} from "../../api/bookDemo";
import {BookDemoAlert} from "./BookDemoAlert";
import { BookDemoText } from "../common/Text";

export type BookDemoFormProps = {
  onCloseModal: () => void
}
export const BookDemoForm: FC<BookDemoFormProps> = ({onCloseModal}) => {
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [company, setCompany] = useState<string>("")
  const [purpose, setPurpose] = useState<string>("")
  const [agreeFlag, setAgreeFlag] = useState<boolean>(false)
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false)

  const disclosureProps = useDisclosure()
  const [isFailed, setIsFailed] = useState<boolean>(false)


  const onPressSubmit = (e: PressEvent) => {
    if (!agreeFlag) return
    else postBookDemo({
      firstName: firstName,
      lastName: lastName,
      company: company,
      purpose: purpose,
      email: email
    })
      .then(()=>{
        setIsFailed(false)
        disclosureProps.onOpen()
      })
      .catch((_) => {
        setIsFailed(true)
        disclosureProps.onOpen()
      })
  }

  useEffect(() => {
    if (firstName&&lastName&&email&&company&&purpose&&agreeFlag) setSubmitEnabled(true)
    else setSubmitEnabled(false)
  }, [firstName, lastName, email, company, purpose, agreeFlag]);

  return (
    <>
      <div className="grid grid-cols-2">
        <BookDemoInputForm valueState={firstName} setState={setFirstName} title="First Name"
                           placeholder="Enter First Name"/>
        <BookDemoInputForm valueState={lastName} setState={setLastName} title="Last Name"
                           placeholder="Enter Last Name"/>
      </div>
      <div className="grid grid-cols-2">
        <BookDemoInputForm valueState={email} setState={setEmail} title="E-mail" placeholder="fishscope@tidepool.kr"/>
        <BookDemoInputForm valueState={company} setState={setCompany} title="Company" placeholder="TIDEPOOL, Inc."/>
      </div>
      <BookDemoInputForm valueState={purpose} setState={setPurpose} title="Purpose of Use"
                         placeholder="TIDEPOOL, Inc."/>
      <div className="m-1">
      <p className="mb-2 font-bold">Privacy Policy</p>
      <Card className="overflow-auto bg-opacity-0 border-2 shadow-none border-default-200 min-h-20 max-h-36">
        <CardBody className="gap-2 text-sm">
          <BookDemoText/>
        </CardBody>
      </Card>
      </div>
      <div className="flex items-center my-4">
        <input name="agree" type="checkbox" onChange={() => setAgreeFlag(!agreeFlag)} defaultChecked={agreeFlag}
               className="w-4 h-4 ml-2 rounded-sm text-tdplplum border-tdpllightgrey focus:ring-tdplplum focus:ring-2 checked:accent-tdplplum checked:"
        />
        <label htmlFor="agree" className="mx-3 text-sm font-medium text-gray-900">
          I agree to the Privacy Policy
        </label>
      </div>
      <div className="flex flex-col items-end p-4">
        <Button className={`bg-tdplplum font-bold text-white text-[1rem] p-4 ${!submitEnabled?"bg-opacity-50":""}`}
                onPress={onPressSubmit} disabled={!submitEnabled} disableAnimation={!submitEnabled}>Submit</Button>
      </div>
      <BookDemoAlert disclosureProps={disclosureProps} isFailed={isFailed} modalClose={onCloseModal}/>
    </>
  )
}