import {Card, ScrollShadow} from "@nextui-org/react";
import {FC} from "react";
import {TankChipContainer} from "./TankChipContainer";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import * as BatchStore from "../../store/batch";


export type TankModelContentProps = {
  batchIdList: string[]
}

export const TankModalContent: FC<TankModelContentProps> = ({
                                                              batchIdList
                                                            }) => {
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)

  return (
    <Card className="my-2 rounded-lg bg-tdplbisque min-h-fit max-h-[50dvh] p-[0.75rem] border-tdpldarkgrey border-opacity-60 border-1" shadow="none">
      <ScrollShadow className="w-full h-fit">
        {batchIdList.map((batch) => {
          if (batch !== "$empty" && batch !== "$unbatched") return (
            <TankChipContainer key={batch} title={storedBatchList[batch].batchName} batchId={batch}/>
          )
          else return null
        })}
        {batchIdList.includes("$unbatched") ?
          <TankChipContainer title="Unbatched Tanks" batchId={"$unbatched"}/>
          : null
        }
        {batchIdList.includes("$empty") ?
          <TankChipContainer title="Empty Tanks" batchId={"$empty"}/>
          : null
        }
      </ScrollShadow>
    </Card>
  )
}