import './App.css';
import Home from "./pages/Home";
import Chart from "./pages/Chart"
import Live from "./pages/Live"
import Layout from "./pages/Layout";
import {Routes, Route} from "react-router-dom";
import NotFound404 from "./pages/NotFound404";
import {Provider as ReduxProvider} from "react-redux";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {useStore} from "./store";
import {Login} from "./pages/Login";
import {Authorization} from "./components/authorization/Authorization";
import {ResolutionLimit} from "./components/resolutionLimit/ResolutionLimit";
import {TankInfoProvider} from "./components/tankInfo/TankInfoProvider";

const queryClient = new QueryClient()

function App() {
  const store = useStore()

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
          <Routes>
            <Route path="/test" element={<ResolutionLimit>test</ResolutionLimit>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/" element={
              <Authorization>
                <ResolutionLimit>
                  <TankInfoProvider>
                    <Layout/>
                  </TankInfoProvider>
                </ResolutionLimit>
              </Authorization>
            }>
              <Route path="" element={<Home/>}/>
              <Route path="chart" element={<Chart/>}/>
              <Route path="live" element={<Live/>}/>
            </Route>
            <Route path="*" element={<NotFound404/>}/>
          </Routes>
      </ReduxProvider>
    </QueryClientProvider>
  );
}

export default App;
