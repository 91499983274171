import {FC, ReactNode} from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button} from "@nextui-org/react"
import {PropsWithChildren} from "react";

export type CommonAlertProps = {
  isOpenAlert: boolean
  onCloseAlert: () => void
  onClose?: () => void
  onOpen?: () => void
  continueJob?: (callback1: () => void, callback2: () => void) => void
  title?: string | undefined | ReactNode
  containContinue?: boolean
} & PropsWithChildren

export const CommonAlert: FC<CommonAlertProps> = ({
  isOpenAlert,
  onCloseAlert,
  onClose,
  onOpen,
  continueJob,
  title,
  containContinue,
  children,
}) => {
  const isContainContinue = containContinue === undefined ? true : containContinue
  const realContinueJob = continueJob === undefined ? () => {
  } : continueJob
  const realOnClose = onClose === undefined ? () => {
  } : onClose
  const realOnOpen = onOpen === undefined ? () => {
  } : onOpen

  return (
    <Modal
      size='sm'
      isOpen={isOpenAlert}
      onClose={onCloseAlert}
      hideCloseButton={true}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent>
        {(onCloseAlert) => (
          <>
            <ModalHeader
              className="flex flex-col gap-1 pt-5 text-center text-tdplindigo">{title !== undefined ? title : "unknown alert"}</ModalHeader>
            <ModalBody className='gap-0 text-center text-tdplindigo'>
              {children !== undefined ? children : "unknown alert"}
            </ModalBody>
            <ModalFooter className='flex items-center justify-center'>
              <Button className='w-24 font-semibold bg-tdpllightgrey text-tdpldarkgrey' onPress={onCloseAlert}>
                Back
              </Button>
              {isContainContinue ?
                <Button
                  className='w-24 font-semibold bg-tdplplum'
                  onPress={() => {
                    realContinueJob(realOnClose, realOnOpen)
                    onCloseAlert()
                  }}
                  style={{color: "white"}}
                >
                  Continue
                </Button>
                : null
              }
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}