import { FC } from "react"
import useHomeAddBatchStore from "../../store/home/useHomeAddBatchStore"
import { postAddBatch } from "../../api/batch/addBatch"
import { useDispatch } from "react-redux"
import { BaseModalFooter } from "../common/Modal"
import { useQueryClient } from "@tanstack/react-query"

type InitialInfoButtonProps = {
  onClose: () => void
  isBack: () => void
}

export const InitialInfoButton:FC<InitialInfoButtonProps> = ({
  onClose,
  isBack,
}) => {
  const { setTanksInfoBorderColor, tanksValues, typeError } = useHomeAddBatchStore()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  
  const chekTanksValuesBeforeFinish = () => {
    setTanksInfoBorderColor()
    if (tanksValues.batchName && tanksValues.startDate && tanksValues.emptyTanks.every(item => item.count !== null && item.moveDate !== null) && !typeError) {
      postAddBatch(tanksValues, dispatch, onClose).then( async () => {
        await queryClient.invalidateQueries({queryKey: ["undoable"]})
      })
    }
  }
  return <BaseModalFooter back="Back" next="Finish" backOnPress={()=>{onClose();isBack()}} nextOnPress={chekTanksValuesBeforeFinish}/>

}