type TinyLinePayloadType = {
  x?: number | null
  index: number
  isActive: boolean
}[]

type TinyAreaPayloadType = {
  value: number
  xTick: any
}[]

export const findLastValidNumber = (values: (number | null)[] | null | undefined) => {
  if (values===null || values===undefined) return ""
  else {
    if (values.length === 0) return ""
    let lastValidIndex: number = -1
    values.forEach((val, index) => {
      if (val !== null) lastValidIndex = index
    })
    if (lastValidIndex === -1) return ""
    else if (values[lastValidIndex] !== null) return values[lastValidIndex]
    else return ""
  }
}

export const makeIndexedGraphForm = (values: (number | null)[]) => {
  if (values.length === 0) return []
  let lastValidIndex: number = 0
  const result: TinyLinePayloadType = values.map((value, index) => {
    if (value !== null) lastValidIndex = index
    return {
      x: value,
      index: index,
      isActive: false
    }
  })
  result[lastValidIndex].isActive = true
  result.push({isActive: false, index: values.length})
  return result
}

export const makeIndexedDistForm = (values: number[], xTicks: number[]) => {
  if (values.length === 0) return []
  if (values.length !== xTicks.length - 1) return []
  const result: TinyAreaPayloadType = values.map((value, index) => {
    return {
      value: value,
      xTick: xTicks[index]
    }
  })
  return result
}