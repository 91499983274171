import {ChangeEvent, FC} from "react";
import {Input} from "@nextui-org/react";

export type InputFormProps = {
  valueState: string
  setState: (state: string) => void
  title: string
  placeholder: string
}

export const BookDemoInputForm: FC<InputFormProps> = (
  {
    valueState,
    setState,
    title,
    placeholder
  }
) => {
  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }

  return (
    <Input type="text" label={title} labelPlacement="outside" variant="bordered" classNames={{
      label: "font-bold text-[1rem]",
      input: "text-[1rem] bg-white",
      inputWrapper: "bg-white",
      innerWrapper: "bg-white",
      mainWrapper: "m-1"
    }} value={valueState} placeholder={placeholder} onChange={onChangeInputValue} className="my-2"/>
  )
}
