import * as T from './types'

const initialState: T.ARState = {}

export const reducer = (state: T.ARState = initialState, action: T.ARActions) => {
  switch (action.type) {
    case '@AR/setAR':
      return action.payload
  }
  return state
}