import "reactflow/dist/style.css";
import { ReactFlowProvider } from "reactflow";
import { FlowChart } from "./FlowChart";

export const SelectTanks = () => {
  return (
    <>
    <div className='pb-1 font-bold'>Select Tanks</div>
    <ReactFlowProvider>
      <FlowChart/>
    </ReactFlowProvider>
    </>
  );
};
