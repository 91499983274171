import {FC, SyntheticEvent, useState} from "react";
import {Menu, MenuButton, Button, MenuList, MenuItem} from "@chakra-ui/react";
import {useTankInfoStageStore} from "../../../store/tankInfo/useTankInfoStageStore";
import {CardNameLiteral} from "../../../types/apiResponse/tankInfoSettingTypes";


export type ToggleButtonProps = {
  cardName: CardNameLiteral
  isToggled: boolean
  cardType: string
  onTypeChange: (newCardType: string) => void
  possibleKeys: string[]
}

export const ToggleButton: FC<ToggleButtonProps> = (
  {
    cardName,
    isToggled,
    cardType,
    onTypeChange,
    possibleKeys
  }
) => {

  const [selectedKey, setSelectedKey] = useState<string>(!isToggled ? "off" : cardType)
  const {editToggle, editType} = useTankInfoStageStore()
  const onSelectDropDownItem = (e: SyntheticEvent<HTMLButtonElement>, key: string) => {
    e.preventDefault()
    e.stopPropagation()
    onTypeChange(key)
    if (selectedKey === "off" && key !== "on") editToggle(cardName, true)
    switch (key) {
      case "on" :
        editToggle(cardName, true);
        break
      case "off" :
        editToggle(cardName, false);
        break
      case "gauge":
        editType(cardName, "gauge");
        break
      case "spec":
        editType(cardName, "spec");
        break
      case "graph":
        editType(cardName, "graph");
        break
      case "distribution":
        editType(cardName, "distribution");
        break
    }
    setSelectedKey(key)
  }

  const convertSelectedKey = (key: string) => {
    switch (key) {
      case "on":
        return "ON"
      case "off":
        return "OFF"
      case "gauge":
        return "Gauge"
      case "spec":
        return "Specification"
      case "graph":
        return "Graph"
      case "distribution":
        return "Distribution"
    }
  }


  return (
    <Menu preventOverflow={true}>
      <MenuButton as={Button} size="xs" width='120px' height='30px' mb={1} variant="bordered"
                  className={
                    `${selectedKey !== "off" ? "bg-white" : "bg-tdpllightgrey"} 
                    border-tdpldarkgrey border-1 border-opacity-60`
                  }
      >
        <p className="font-normal text-tdplindigo text-[1rem]">
          {convertSelectedKey(selectedKey)}
        </p>
      </MenuButton>
      <MenuList aria-label={`${cardName}-setting-panel`}>
        {possibleKeys.map((key) => {
          return <MenuItem key={key} aria-labelledby={`${cardName}-setting-panel`}
                           onClick={(e) => onSelectDropDownItem(e, key)}>{convertSelectedKey(key)}</MenuItem>
        })}
      </MenuList>
    </Menu>
  )
}