import * as T from './types'

export const setBatches = (payload: T.BatchState): T.SetBatchAction => ({
  type: '@batch/setBatch',
  payload
})

export const addBatch = (payload: T.BatchInfo & { batchId: string }): T.AddBatchAction => ({
  type: '@batch/addBatch',
  payload
})

export const updateBatch = (payload: T.BatchInfo & { batchId: string }): T.UpdateBatchAction => ({
  type: '@batch/updateBatch',
  payload
})

export const delBatch = (payload: { batchId: string }): T.DelBatchAction => ({
  type: "@batch/delBatch",
  payload
})