import {Card} from "@nextui-org/react";
import {FC, HTMLProps} from "react";
import {useSelectTankStore} from "../../store/selectTank/useSelectTankStore";
import TankChip from "./TankChip";


export const TankSelected: FC<HTMLProps<HTMLDivElement>> = (
  {
    ...props
  }
) => {
  const {selectedTankList, detach} = useSelectTankStore()

  return (
    <div {...props}>
      <p className="text-tdplindigo">Selected Tanks</p>
      <Card
        className='flex flex-row flex-wrap justify-start p-1 border border-tdpldarkgrey border-opacity-60 min-h-[3rem] w-full'
        radius='sm'
        shadow="sm"
      >
        {
          selectedTankList?.map((tankSelected, index) => (
            <TankChip key={index} submitTank={tankSelected} isActivated={false} onTankChipClick={()=>detach(tankSelected.tankId)}/>
          ))
        }
      </Card>
    </div>
  )
}

export default TankSelected;