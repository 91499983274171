import {PropertyName} from "../../types/apiResponse/chartTypes";


export const monthReadableMapping: { [monthString: string]: string } = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
}

export const makeChartYAxisTitle = (property: PropertyName) => {
  switch (property) {
    case "averageWeight":
      return "Average Weight"
    case "biomass":
      return "Biomass"
    case "count":
      return "Count"
    case "feedingAmount":
      return "Feeding Amount"
    case "sampleWeight":
      return "Sample Weight"
    case "nh3no2":
      return "NH3 & NO2"
  }
}
export const convertIntToDate = (x: number) => {
  const dateString = String(x)
  return new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`)
}

// export const convertIntToReadAbleDateString = (x: Date) => {
//   const dateString = String(x)
//   const month = `${dateString.slice(4, 6)}`
//   const day = `${dateString.slice(6, 8)}`
//   const year = `${dateString.slice(0, 4)}`
//   return `${monthReadableMapping[month]}. ${day}, ${year}`
// }


//    %Y - for year boundaries, such as 2011.
//    %B - for month boundaries, such as February.
//    %b %d - for week boundaries, such as Feb 06.
//    %a %d - for day boundaries, such as Mon 07.
//    %I %p - for hour boundaries, such as 01 AM.
//    %I:%M - for minute boundaries, such as 01:23.
//    :%S - for second boundaries, such as :45.
//    .%L - milliseconds for all other times, such as .012.

export const getTimeFormat = (timeUnit: string) => {
  return timeUnit === "day" ? "%a %d" : timeUnit === "week" ? "%b %d" : timeUnit === "month" ? "%B" : ""
}