import * as T from './types'

const initialState: T.SchoolState = {}

export const reducer = (state: T.SchoolState = initialState, action: T.SchoolActions) => {
  switch (action.type) {
    case "@school/addSchool":
      return {
        ...state,
        [action.payload.schoolId]: action.payload
      }
    case "@school/delSchool":
      const deletedSchool: T.SchoolState = {}
      Object.keys(state).forEach((schoolId) => {
        if (schoolId !== action.payload.schoolId) deletedSchool[schoolId] = state[schoolId]
      })
      return deletedSchool
    case "@school/setSchool":
      return action.payload
    case "@school/updateSchool":
      const updatedSchool: T.SchoolState = {}
      Object.keys(state).forEach((schoolId) => {
        if (schoolId !== action.payload.schoolId) updatedSchool[schoolId] = state[schoolId]
        else updatedSchool[schoolId] = action.payload
      })
      return updatedSchool
  }
  return state
}