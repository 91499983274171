import { FC } from 'react';
import { BaseEdge, getStraightPath } from 'reactflow';

type TankEdgeProps = {
    id: string,
    sourceX: number
    sourceY: number
    targetX: number
    targetY: number
}

export const TankEdge : FC<TankEdgeProps> = ({ id, sourceX, sourceY, targetX, targetY }) => {
  const [edgePath] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath}/>
    </>
  );
}
