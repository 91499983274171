import { create } from "zustand";
import { SubmitTankArrayType, UpdateSchoolValuesArrayType, UpdateValuesArrayType } from "../../types/apiResponse/tankTypes";
import { TRState } from "../tankRelation";
import { SchoolState } from "../school";

type HomeUpdateState = {
  updateDate: number | null
  selectedTanks: SubmitTankArrayType
  schoolValues: UpdateValuesArrayType
  updateTableData: UpdateSchoolValuesArrayType
  refreshSchools: string[]
  dateBorderColor: string
  selectTanksBorderColor: string
  updateData: UpdateValuesArrayType
  beEmptyTanks: string[]
  tableBorderColor: string
  typeError: boolean
  requiredError: boolean
  requiredCheck: boolean
};

type HomeUpdateAction = {
  setUpdateDate: (updateDate: number|null) => void
  setSelectedTanks: (selectedTanks: SubmitTankArrayType, schoolIdList:TRState) => void
  setSchoolValues: (schoolVaues:UpdateValuesArrayType, storedSchoolList:SchoolState) => void
  setDateBorderColor: () => void
  setSelectedTanksBorderColor: () => void
  reset: () => void
  setInsertUpdateTableData: (updateTableData: UpdateSchoolValuesArrayType) => void
  checkRequired:() => void
};

const initialState: HomeUpdateState = {
  updateDate: null,
  selectedTanks: [],
  schoolValues: [],
  updateTableData: [],
  refreshSchools: [],
  dateBorderColor: "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60",
  selectTanksBorderColor: "border border-tdpldarkgrey",
  updateData: [],
  beEmptyTanks: [],
  tableBorderColor: "border border-tdpldarkgrey",
  typeError: false,
  requiredError: false,
  requiredCheck: false
}

const useHomeUpdateStore = create<HomeUpdateState & HomeUpdateAction>(
    (set, get) => ({
      ...initialState,
      setUpdateDate: (updateDate) => {
        set(() => ({
          updateDate: updateDate,
          dateBorderColor: updateDate
          ? "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
          : "[&>div]:border-2 [&>div]:border-tdplmaroon [&>div]:hover:border-tdplmaroon"
        }))
      },
      setSelectedTanks: (selectedTanks, schoolIdList) => {
        set(() => ({
          selectedTanks: selectedTanks,
          refreshSchools: selectedTanks.map((item) => {
            return schoolIdList[item.tankId][0].schoolId
          }),
          selectTanksBorderColor: "border border-tdpldarkgrey",
          schoolValues:[],
          updateTableData:[],
          updateData:[]
        }))
      },
      setSchoolValues: (schoolValues, storedSchoolList) => {
        set((state) => ({
          updateTableData: schoolValues.map((item, index) => {
          return {
            ...item,
            tankName: state.selectedTanks[index].tankName,
            tankId: state.selectedTanks[index].tankId,
            resultingCount: (Number(storedSchoolList[item.schoolId].count) + Number(item.harvest) + Number(item.mortality)),
          }
        }),}))
      },
      setInsertUpdateTableData: (updateTableData) => {
        set(() => {
          const typeError = !updateTableData.every(item=>
            (Number.isInteger(item.harvest) || item.harvest ===null) 
            && (Number.isInteger(item.mortality) || item.mortality===null) 
            && (Number.isInteger(item.feedTimes) || item.feedTimes===null))
          return {
          updateTableData: updateTableData,
          updateData:updateTableData.map((item) => {
            let {tankName, tankId, resultingCount, ...values} = item
            return values
          }),
          beEmptyTanks: updateTableData
          .filter((item) => (Number(item.resultingCount) - Number(item.harvest) - Number(item.mortality)) <= 0 && item.tankName)
          .map((item) => {
            return item.tankName? item.tankName : ""
          }),
          typeError: typeError,
          tableBorderColor: typeError?"border-2 border-tdplmaroon":"border border-tdpldarkgrey",
          requiredError: !updateTableData.every(item => (item.feedingAmount!==null) === (item.feedTimes!==null)),
          requiredCheck: false
        }})
      },
      setDateBorderColor: () => {
        set((state) => ({
          dateBorderColor: state.updateDate
          ? "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
          : "[&>div]:border-2 [&>div]:border-tdplmaroon [&>div]:hover:border-tdplmaroon [&>div]:border-opacity-60"
        }))
      },
      setSelectedTanksBorderColor: () => {
        set((state) => ({selectTanksBorderColor:state.refreshSchools.length !== 0? "border border-tdpldarkgrey":"border-2 border-tdplmaroon"}))
      },
      reset: () => {
        set(initialState)
      },
      checkRequired: () => {
        set((state) => {
          return {
            tableBorderColor: state.requiredError?"border-2 border-tdplmaroon":state.tableBorderColor,
            requiredCheck: state.requiredError
          }
        })
      }
    }),
  )


export default useHomeUpdateStore;
