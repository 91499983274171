import {FC} from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button} from "@nextui-org/react"
import {PropsWithChildren} from "react";

export type UndoCheckAlertProps = {
  isOpenAlert: boolean
  onCloseAlert: () => void
  title?: string | undefined
  onContinue: () => void
} & PropsWithChildren

export const UndoCheckAlert: FC<UndoCheckAlertProps> = (
  {
    isOpenAlert,
    onCloseAlert,
    title,
    onContinue,
    children,
  }
) => {
  return (
    <Modal
      size='sm'
      isOpen={isOpenAlert}
      onClose={onCloseAlert}
      hideCloseButton={true}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent>
        {(onCloseAlert) => (
          <>
            <ModalHeader
              className="flex flex-col gap-1 text-center text-tdplindigo">{title !== undefined ? title : "unknown alert"}</ModalHeader>
            <ModalBody className='gap-0 text-center text-tdplindigo'>
              {children !== undefined ? children : "unknown alert"}
            </ModalBody>
            <ModalFooter className='flex items-center justify-center'>
              <Button className='bg-tdpllightgrey text-tdpldarkgrey' onPress={onCloseAlert} style={{fontWeight: "500"}}>
                Cancel
              </Button>
              <Button
                className='bg-tdplplum'
                onPress={() => {
                  onContinue()
                  onCloseAlert()
                }}
                style={{fontWeight: "500", color: "white"}}
              >
                Undo
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}