import {FC, useEffect} from 'react'
import {
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react"
import {useSelector} from 'react-redux';
import {AppState} from "../../store";
import * as TankRelationStore from "../../store/tankRelation"
import * as SchoolStore from '../../store/school'
import useHomeGradeStore from '../../store/home/useHomeGradeStore';
import { SelectDepatureTank } from './SelectDepartureTank';
import { SelectArrivalTanks } from './SelectArrivalTanks';
import { SelectTanksContinue } from './SelectTanksContinue';
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { CustomDatePicker } from '../common/Input';
import { ModalText } from '../common/Text';

export interface SelectTanksGradeProps {
  isOpen: boolean
  onOpen: () => void
  onOpenChange: () => void
}

export const SelectTanksGrade: FC<SelectTanksGradeProps> = ({
  isOpen,
  onOpenChange,
  onOpen
}) => {
  const {
    setArrTanksValues,
    selectedDepTanks,
    selectedArrTanks, 
    gradeDate,
    setGradeDate,
    gradeDateBorderColor
  } = useHomeGradeStore()

  const schoolIdList = useSelector<AppState, TankRelationStore.TRState>(state => state.tankRelation)
  const storedSchoolList = useSelector<AppState, SchoolStore.SchoolState>(state => state.school)

  useEffect(() => {
  if (gradeDate !== null && selectedArrTanks.length !== 0 && selectedDepTanks.length !==0) {
    setArrTanksValues(schoolIdList, storedSchoolList)
  }
  }, [gradeDate, selectedArrTanks, selectedDepTanks, schoolIdList, storedSchoolList, setArrTanksValues])

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"} isDismissable={true}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Grade' description={ModalText('Grade')}/>
            <ModalBody className='gap-1 min-h-[20rem] pr-10 pl-10'>
              <BaseDivider/>
              <SelectDepatureTank
                schoolIdList={schoolIdList}
                storedSchoolList={storedSchoolList}
              />
              <SelectArrivalTanks/>
              <CustomDatePicker label='Grading Date' value={gradeDate} setValue={setGradeDate} 
                dateBorderColor={gradeDateBorderColor} warnText='Please enter a Grading Date.'/>
            </ModalBody>
            <ModalFooter  className='pb-8 pl-10 pr-10'>
              <SelectTanksContinue
                onClose={onClose}
                onOpen={onOpen}
              />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
}