import { create } from "zustand";
import { addBatchType } from "../../api/batch/addBatch";
import { SubmitTankArrayType, TankValuesArrayType } from "../../types/apiResponse/tankTypes";

type HomeAddBatchState = {
  batchName: string | null
  startDate: number | null
  selectedTanks: SubmitTankArrayType
  selectedEmptyTanks: TankValuesArrayType
  tanksValues: addBatchType
  notAllowedBatchName: boolean
  selectTanksBorderColor: string
  nameBorderColor: string
  dateBorderColor: string
  tanksInfoBorderColor: string
  typeError: boolean
  requiredError:boolean
  nameDuplicated: boolean
};

type HomeAddBatchAction = {
  setBatchName: (batchName:string|null, batchNameList:string[]) => void
  setStartDate: (startDate: number|null) => void
  setSelectedTanks: (selectedTanks:SubmitTankArrayType) => void
  setSelectedEmptyTanks: (selectedEmptyTanks:TankValuesArrayType) => void
  setSelectedTanksBorderColor: () => void
  setNameBorderColor: () => void
  setDateBorderColor: () => void
  setTanksInfoBorderColor: () => void
  reset: () => void
};

const initialState: HomeAddBatchState = {
  batchName: null,
  startDate: null,
  selectedTanks:[],
  selectedEmptyTanks: [],
  tanksValues: {batchName:null,startDate:null, filledTanks:[],emptyTanks:[]},
  notAllowedBatchName: false,
  selectTanksBorderColor:"border border-tdpldarkgrey",
  nameBorderColor:"border border-tdpldarkgrey",
  dateBorderColor:"[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60",
  tanksInfoBorderColor: "border border-tdpldarkgrey",
  typeError:false,
  requiredError: false,
  nameDuplicated: false
}

const useHomeAddBatchStore = create<HomeAddBatchState & HomeAddBatchAction>(
  (set, get) => ({
    ...initialState,
    setBatchName: (batchName, batchNameList) => {
      set((state) => ({
        batchName:batchName===""?null:batchName,
        tanksValues: {...state.tanksValues, batchName:batchName},
        notAllowedBatchName: batchName && batchName.includes("$")? true :false,
        nameBorderColor: "border border-tdpldarkgrey",
        nameDuplicated: batchName? batchNameList.includes(batchName): false
      }))
    },
    setStartDate: (startDate) => {
      set((state) => ({
        startDate: startDate,
        tanksValues: {...state.tanksValues, startDate:startDate},
        dateBorderColor: 
        "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
      }))
    },
    setSelectedTanks: (selectedTanks) => {
      set((state) => {
        const emptyTanks = selectedTanks.filter(item => item.batchId === "$empty").map((item) => {
          return {
            tankId: item.tankId,
            batchName: item.batchName,
            tankName: item.tankName,
            count:null,
            moveDate:null,
            sampleWeight: null
          }
        })
        return {
          selectedTanks:selectedTanks,
          tanksValues: {
            ...state.tanksValues, 
            filledTanks:selectedTanks.filter(item => item.batchId !== "$empty").map(item=> item.tankId),
            emptyTanks: emptyTanks
          },
          selectedEmptyTanks:emptyTanks,
          selectTanksBorderColor: selectedTanks.length === 0? "border2 border-tdplmaroon":"border border-tdpldarkgrey"
        }
      })
    },
    setSelectedEmptyTanks: (selectedEmptyTanks) => {
      set((state) => {
        const typeError = !selectedEmptyTanks.every(item=> item.count === null || Number.isInteger(item.count))
        return {
        selectedEmptyTanks:selectedEmptyTanks,
        tanksValues: {...state.tanksValues, emptyTanks:selectedEmptyTanks},
        tanksInfoBorderColor: typeError?"border-2 border-tdplmaroon":"border border-tdpldarkgrey",
        typeError: typeError,
        requiredError: false,
      }})
    },
    setSelectedTanksBorderColor: () => {
      set((state) => ({selectTanksBorderColor:state.selectedTanks.length === 0? "border-2 border-tdplmaroon":"border border-tdpldarkgrey"}))
    },
    setNameBorderColor: () => {
      set((state) => ({
        nameBorderColor:state.batchName === null || state.batchName.includes("$") || state.nameDuplicated? "border-2 border-tdplmaroon data-[hover=true]:border-tdplmaroon":"border border-tdpldarkgrey"
      }))
    },
    setDateBorderColor: () => {
      set((state) => ({
        dateBorderColor:state.startDate === null
        ? "[&>div]:border-2 [&>div]:border-tdplmaroon [&>div]:hover:border-tdplmaroon [&>div]:border-opacity-60"
        : "[&>div]:border [&>div]:border-tdpldarkgrey [&>div]:border-opacity-60 [&>div]:hover:border-tdpldarkgrey [&>div]:hover:border-opacity-60"
      }))
    },
    setTanksInfoBorderColor: () => {
      set((state) => {
        const requiredError = !state.selectedEmptyTanks.every(item => item.count !== null && item.moveDate !== null)
        return {
        tanksInfoBorderColor:requiredError?
        "border-2 border-tdplmaroon" 
        : "border border-tdpldarkgrey",
        requiredError:requiredError
      }})
    },
    reset: () => {
      set(initialState)
    },
  }),
)


export default useHomeAddBatchStore;
