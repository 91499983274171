import {FC} from 'react'
import {
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react"
import { BaseDivider, BaseModal, BaseModalHeader } from '../common/Modal';
import { InputBatchName } from './InputBatchName';
import { SelectAddBatchTanks } from './SelectAddBatchTanks';
import { SelectTanksAddBatchButton } from './SelectTanksAddBatchButton';
import { CustomDatePicker } from '../common/Input';
import useHomeAddBatchStore from '../../store/home/useHomeAddBatchStore';
import { ModalText } from '../common/Text';

export interface SelectTanksAddBatchProps {
  isOpen: boolean
  onOpen: () => void
  onOpenChange: () => void
}

export const SelectTanksAddBatch: FC<SelectTanksAddBatchProps> = ({
  isOpen,
  onOpen,
  onOpenChange,
}) => {
  const {startDate, dateBorderColor, setStartDate} = useHomeAddBatchStore()

  return (
    <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"3xl"} isDismissable={true}>
      <ModalContent>
        {(onClose) => (
          <>
            <BaseModalHeader title='Add Batch' description={ModalText('Add Batch')} />
            <ModalBody className='gap-1 min-h-[8rem] pr-10 pl-10'>
              <BaseDivider/>
              <InputBatchName />
              <CustomDatePicker label={"Start Date"} value={startDate} dateBorderColor={dateBorderColor} setValue={setStartDate}/>
              <SelectAddBatchTanks/>
            </ModalBody>
            <ModalFooter className='pb-8 pl-10 pr-10'>
              <SelectTanksAddBatchButton onClose={onClose} onOpen={onOpen}/>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </BaseModal>
  )
}