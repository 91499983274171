import * as T from './types'


const initialBatchState = {
  "$unbatched": {batchName: "Unbatched Tanks", startDate: 0},
  "$empty": {batchName: "Empty Tanks", startDate: 0}
}
export const reducer = (state: T.BatchState = initialBatchState, action: T.BatchActions) => {
  switch (action.type) {
    case '@batch/setBatch':
      return action.payload
    case "@batch/addBatch":
      return {
        ...state,
        [action.payload.batchId]: action.payload
      }
    case "@batch/delBatch":
      const deletedBatch: T.BatchState = {}
      Object.keys(state).forEach((batchId) => {
        if (batchId !== action.payload.batchId) deletedBatch[batchId] = state[batchId]
      })
      return {...deletedBatch}
    case "@batch/updateBatch":
      const updatedBatchInfo: T.BatchState = {}
      Object.keys(state).forEach((batchId) => {
        if (batchId !== action.payload.batchId) updatedBatchInfo[batchId] = state[batchId]
        else updatedBatchInfo[batchId] = action.payload
      })
      return updatedBatchInfo
  }
  return state
}