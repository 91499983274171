export const allCamerasTest = [
  {
    tankId: "e0b00795-acf4-403c-b714-eefb5f0fece8",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "40a6c49b-da76-4016-b321-50d3d90eb87f",
    left: false,
    right: false,
    top: false
  },
  {
    tankId: "256f8238-a5b2-464b-9f12-93e98e8069d4",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "084f9255-911e-400e-97b7-9d5a794e42a6",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "1354481f-bf5c-407e-8bfc-287fd0784320",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "68cf36a5-37c4-4ea9-b266-75a6e1a9fbec",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "dc0c1016-1bfe-4436-8801-8bfd215bc2cc",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "1359d908-400a-4a00-a119-a5d7bdae07d3",
    left: true,
    right: true,
    top: false
  },

  {
    tankId: "51566915-0181-4e57-ab5e-23be42bf02de",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "3b9afb8c-9724-474e-ae6c-7cd3412d75f2",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "d1f0f873-955b-4b63-8978-67f2164a99ab",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "b49fe3dc-2314-4bce-8c96-1dd35c5d3c0f",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "9b90a81f-4889-4699-97b2-bb50dd46b295",
    left: true,
    right: true,
    top: false
  },
  {
    tankId: "806cd4c1-99c3-4c60-828a-7370cf263c3b",
    left: true,
    right: true,
    top: false
  },
]
