import logo from "../../assets/logo/FISHSCOPE_logo_black.png"
import {Link} from "react-router-dom";
import { UserAvatar } from "../home/User";
import { Help } from "./Help";

export default function Header() {
  return (
    <header
      className="bg-white border-t-0 border-b-2 border-l-0 border-r-0 border-opacity-50 border-solid border-tdpldarkgrey">
      <div className="flex flex-row justify-between my-2">
        <div className="mx-4 my-2">
          <Link to="/">
            <img src={logo} alt="" width={200} height={40}/>
            {/*<TitleLogo/>*/}
          </Link>
        </div>
        <div className="flex flex-row items-center mx-4 my-2 justify-evenly">
          <Help/>
          <UserAvatar/>
        </div>
      </div>
    </header>
  )
}