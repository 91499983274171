import { Input } from "@nextui-org/react"
import useHomeAddBatchStore from "../../store/home/useHomeAddBatchStore"
import {AppState} from '../../store';
import * as BatchStore from "../../store/batch"
import { useSelector } from "react-redux";

export const InputBatchName= () => {
  const {batchName, setBatchName, nameBorderColor, notAllowedBatchName, nameDuplicated} = useHomeAddBatchStore()
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)

  return (
    <>
    <div className='mt-3 font-bold'>Batch Name</div>
    <div>
      <Input
        defaultValue={batchName === null ? "" : String(batchName)}
        autoComplete="off"
        onBlur={(event) => {
            setBatchName((event.target as HTMLInputElement).value, Object.values(storedBatchList).map(item=>item.batchName))
        }}
        radius='sm'
        placeholder='Enter Batch Name'
        size='lg'
        variant='bordered'
        key="BatchName"
        name='BatchName'
        type='string'
        classNames={{inputWrapper: `${nameBorderColor} border-opacity-60`}}/>
      {nameBorderColor.includes("tdplmaroon") ?
        (notAllowedBatchName ?
          <p className='m-0 text-sm font-semibold text-tdplmaroon'>Batch Names cannot contain "$".</p> : 
            nameDuplicated?
            <p className='m-0 text-sm font-semibold text-tdplmaroon'>A batch with this name already exists.</p>:
          <p className='m-0 text-sm font-semibold text-tdplmaroon'>Please enter a Batch Name.</p>
        )
        :
        <p></p>
      }
    </div>
    </>
  )
}