import {ChangeEvent, FC, useEffect, useState} from "react";
import {CardBody} from "@chakra-ui/react";
import {
  CardNameLiteral,
  GaugeSetting
} from "../../../types/apiResponse/tankInfoSettingTypes";
import {useTankInfoStageStore} from "../../../store/tankInfo/useTankInfoStageStore";


export type GaugeSettingFormProps = {
  cardName: CardNameLiteral
  gaugeSetting: GaugeSetting | undefined
}

export const GaugeSettingForm: FC<GaugeSettingFormProps> = (
  {
    cardName,
    gaugeSetting
  }
) => {
  const [minLimit, setMinLimit] = useState<string>(gaugeSetting ? gaugeSetting.minLimit.toString() : "")
  const [maxLimit, setMaxLimit] = useState<string>(gaugeSetting ? gaugeSetting.maxLimit.toString() : "")
  const [minWarning, setMinWarning] = useState<string>(gaugeSetting ? gaugeSetting.minWarning.toString() : "")
  const [maxWarning, setMaxWarning] = useState<string>(gaugeSetting ? gaugeSetting.maxWarning.toString() : "")
  const {editGauge} = useTankInfoStageStore()
  const handleMinLimitChange = (e: ChangeEvent<HTMLInputElement>) => setMinLimit(e.target.value.replace(/[^.0-9]/g, ""))
  const handleMaxLimitChange = (e: ChangeEvent<HTMLInputElement>) => setMaxLimit(e.target.value.replace(/[^.0-9]/g, ""))
  const handleMinWarningChange = (e: ChangeEvent<HTMLInputElement>) => setMinWarning(e.target.value.replace(/[^.0-9]/g, ""))
  const handleMaxWarningChange = (e: ChangeEvent<HTMLInputElement>) => setMaxWarning(e.target.value.replace(/[^.0-9]/g, ""))
  const metric = gaugeSetting?gaugeSetting.metric?gaugeSetting.metric:"":""


  useEffect(() => {
    if (!isNaN(Number(minLimit)) && !isNaN(Number(maxLimit)) && !isNaN(Number(minWarning)) && !isNaN(Number(maxWarning))) {
      editGauge(cardName, {
        minWarning: Number(minWarning),
        maxWarning: Number(maxWarning),
        minLimit: Number(minLimit),
        maxLimit: Number(maxLimit),
        metric: gaugeSetting?gaugeSetting.metric:null
      })
    }
  }, [minLimit, maxLimit, minWarning, maxWarning, gaugeSetting, cardName]);

  return (
    <CardBody py={1}>
      <div className="flex flex-row justify-between gap-2">
        <div className="flex flex-row justify-between w-1/2">
          <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">MIN</p>
          <div className="flex flex-row justify-end">
            <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none" onInput={handleMinLimitChange}
                   value={minLimit}/>
            {
              metric !== ""
                ?<p className="ml-1">
                  {metric}
                </p>
                : <></>
            }
          </div>
        </div>
        <div className="flex flex-row justify-between w-1/2">
        <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">MAX</p>
          <div className="flex flex-row justify-end">
            <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none" onChange={handleMaxLimitChange}
                   value={maxLimit}/>
            {
              metric !== ""
                ?<p className="ml-1">
                  {metric}
                </p>
                : <></>
            }
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-2">
      <div className="flex flex-row justify-between w-1/2">
          <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">LOWER</p>
          <div className="flex flex-row justify-end">
            <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none" onChange={handleMinWarningChange}
                   value={minWarning}/>
            {
              metric !== ""
                ?<p className="ml-1">
                  {metric}
                </p>
                : <></>
            }
          </div>
        </div>
        <div className="flex flex-row justify-between w-1/2">
        <p className="text-[1rem] w-1/2 font-bold text-tdplmiddlegrey">UPPER</p>
          <div className="flex flex-row justify-end">
            <input className="text-[1rem] w-1/2 text-right bg-tdplbisque outline-none" onChange={handleMaxWarningChange}
                   value={maxWarning}/>
            {
              metric !== ""
                ?<p className="ml-1">
                  {metric}
                </p>
                : <></>
            }
          </div>
        </div>
      </div>
    </CardBody>
  )
}