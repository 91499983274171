import { Button, ModalBody, ModalFooter, ModalHeader } from "@nextui-org/react"
import { FC, ReactNode } from "react"

export const HelperText:FC<{page:string, onClose:()=>void}> = ({page, onClose}) => {
  const header: Record<string, string> = {
    "/": "Home",
    "/chart": "Chart",
    "/live" : "Live",
    any: "None"
  }

  const description: Record<string, ReactNode> = {
    "/":<>In the "Home" tab, you can see key information about your tank at a glance and manage it in batches.
     Utilize tools like "Update", "Grade", and more to keep track of the information being collected in your aquafarm.</>,
    "/chart":<>In the "Chart" tab, you can conveniently search and analyze the data accumulated while running your fish farm.
     Use the search bar to browse past batches and tanks.</>,
    "/live":<>In the "Live" tab, you can view live streaming video from the cameras installed in each tank of your fish farm.
     Tap on each video to open the "Tank Information" window for more details.</>,
    any:<>discription</>
  }

  return (
    <>
      <ModalHeader className="text-3xl p-7">{header[page]}</ModalHeader>
      <ModalBody className="pt-0 text-sm pl-7 pr-7 pb-7">
        {description[page]}
      </ModalBody>
      <ModalFooter className="justify-center pt-10 pb-6">
        <Button className="w-24 h-10 font-semibold bg-tdpllightgrey text-tdpldarkgrey" radius="md" onPress={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}