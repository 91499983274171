import { Button } from "@nextui-org/react"
import { FC } from "react"
import useHomeAddBatchStore from "../../store/home/useHomeAddBatchStore"
import {postAddBatch} from '../../api/batch/addBatch';
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";


type SelectTanksAddBatchButtonProps = {
  onClose: () => void
  onOpen: () => void
}

export const SelectTanksAddBatchButton:FC<SelectTanksAddBatchButtonProps> = ({
  onClose, 
  onOpen,
}) => {
  const {
    setNameBorderColor, 
    setDateBorderColor, 
    setSelectedTanksBorderColor, 
    tanksValues, 
    batchName, 
    startDate, 
    selectedTanks,
    nameDuplicated,
  } = useHomeAddBatchStore()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  
  const checkTanksBeforeContinue = () => {
    setNameBorderColor()
    setDateBorderColor()
    setSelectedTanksBorderColor()
    if (tanksValues && batchName && !batchName.includes("$") && startDate && selectedTanks.length !== 0 && !nameDuplicated) {
      if (tanksValues.emptyTanks.length !== 0) {
        onClose()
        onOpen()
      } else {
        postAddBatch(tanksValues, dispatch, onClose).then( async () => {
          await queryClient.invalidateQueries({queryKey: ["undoable"]})
        })
      }
    }
  }

  return ( 
    <>
      <Button className="font-bold text-white w-[6rem] mr-0 bg-tdplplum" onPress={checkTanksBeforeContinue}>
        Continue
      </Button>
    </>
  )
}