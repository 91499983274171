import {useQuery} from "@tanstack/react-query";
import {getTankInfoCardToggleSetting} from "../../api/tankInfo/cardContainer";
import {useEffect} from "react";

export const useToggleSettings = () => {
  const queryResult =  useQuery({
    queryKey: ["tankInfo", "toggle"],
    queryFn: async () => {
      return await getTankInfoCardToggleSetting()
    },
    staleTime: 1000 * 3600,  //ms 단위.
  })

  useEffect(() => {
    if (queryResult.isStale) {
      queryResult.refetch().then(()=>{})
    }
  }, [queryResult.isStale]);
  return queryResult
}
