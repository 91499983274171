import { Card, Chip, Modal, ModalContent, useDisclosure } from "@nextui-org/react"
import {useSelector} from 'react-redux';
import {AppState} from '../../store';
import * as BatchStore from "../../store/batch"
import useHomeAddBatchStore from "../../store/home/useHomeAddBatchStore"
import SelectTank from "../../pages/SelectTank"
import { SubmitTankArrayType } from "../../types/apiResponse/tankTypes"

export const SelectAddBatchTanks = () => {
  const { selectTanksBorderColor, setSelectedTanks, selectedTanks } = useHomeAddBatchStore()
  const {isOpen: isOpenTanks, onOpen: onOpenTanks, onOpenChange: onOpenChangeTanks} = useDisclosure();
  const storedBatchList = useSelector<AppState, BatchStore.BatchState>(state => state.batch)
  const batchIdList = Object.keys(storedBatchList)
  
  return (
    <>
      <div className='mt-3 font-bold'>Tanks</div>
      <Card
        className={`flex flex-row flex-wrap justify-start p-1 ${selectTanksBorderColor} border-opacity-60 min-h-[3rem]`}
        radius='sm' shadow="sm" isPressable onPress={onOpenTanks}>
        <Modal isOpen={isOpenTanks} onOpenChange={onOpenChangeTanks} isDismissable={false}
                isKeyboardDismissDisabled={true} size="3xl">
          <ModalContent>
            {(onCloseTanks) => (
              <SelectTank
                submitTankArray={(tankSelectedList: SubmitTankArrayType) => setSelectedTanks(tankSelectedList)}
                batchIdList={batchIdList}
                onClose={onCloseTanks}
                initialTankArray={selectedTanks}
              />
            )}
          </ModalContent>
        </Modal>
        {selectedTanks.map((item, index) => (
          <Chip key={index} radius="sm" classNames={{
            base: `border-small border-tdpldarkgrey bg-${item.batchName === "$empty" ? "tdpllightgrey" : "tdplsky"} border-opacity-60 m-1 p-0`,
            content: "font-bold"
          }}>{item.tankName}</Chip>
        ))}
      </Card>
      {selectTanksBorderColor && selectTanksBorderColor.includes("tdplmaroon") ?
        <p className='m-0 text-sm font-semibold text-tdplmaroon'>You need to select at least one tank.</p> :
        <p></p>}
    </>
  )
}