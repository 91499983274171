import {create} from "zustand";

export type UseTankInfoModalStoreState = {
  isOpen: boolean
  tankId: string
  tankName: string | null
  initialHlsUri: string | null
  initialPosition: string | null
  openModal: () => void
  closeModal: () => void
}

export type UseTankInfoModalStoreAction = {
  setIsOpen: (isOpen: boolean) => void
  setTank: (tankId: string, tankName: string | null) => void
  getTank: () => { tankId: string, tankName: string | null }
  setVideo: (initialHlsUri: string | null, initialPosition: string | null) => void,
  initialize: (
    openModal: () => void,
    closeModal: () => void
  ) => void
  open: (tankId?: string, tankName?: string) => void,
  close: () => void
}

export const useTankInfoModalStore = create<
  UseTankInfoModalStoreState & UseTankInfoModalStoreAction
>(
  (set, get) => (
    {
      isOpen: false,
      tankId: "",
      tankName: null,
      initialHlsUri: null,
      initialPosition: null,
      openModal: () => {
      },
      closeModal: () => {
      },
      setIsOpen: (isOpen) => {
        set(state => ({...state, isOpen: isOpen}))
      },
      setTank: (tankId, tankName) => {
        set(state => ({...state, tankId: tankId, tankName: tankName}))
      },
      setVideo: (initialHlsUri, initialPosition) => {
        set(state => ({...state, initialHlsUri: initialHlsUri, initialPosition: initialPosition}))
      },
      getTank: () => {
        return {tankId: get().tankId, tankName: get().tankName}
      },

      initialize: (openModal: () => void, closeModal: () => void) => {
        set(state => ({...state, openModal: openModal, closeModal: closeModal}))
      },
      open: (tankId?: string, tankName?: string) => {
        if (tankId !== undefined) set(state => ({...state, tankId: tankId}))
        if (tankName !== undefined) set(state => ({...state, tankName: tankName}))
        if (get().tankId && get().tankName) get().openModal()
      },
      close: () => {
        set(state => ({...state, tankId: "", tankName: null, initialHlsUri: null, initialPosition: null}))
        get().closeModal()
      },
    }
  )
)
