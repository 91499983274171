import {Dispatch} from "react"
import {UnknownAction} from "redux"
import {UpdateValuesArrayType} from "../../types/apiResponse/tankTypes"
import {getData, patchData} from "../../utils"
import {reloadARTRList, reloadBatchList, reloadSchool} from "../../utils/loadStorage"
import { SchoolState } from "../../store/school"

export const getUpdate = async(
  schoolIds: string[],
  updateDate: number,
  storedSchoolList: SchoolState,
  setSchoolValues: (values:UpdateValuesArrayType, storedSchoolList:SchoolState) => void,
  onClose: ()=>void,
  onOpen:()=>void
) => {
  await getData<UpdateValuesArrayType>(`/update?update-date=${updateDate}&${schoolIds.map(item => `school-ids=${item}`).join('&')}`)
    .then((response) => {
      setSchoolValues(response, storedSchoolList)
      onClose()
      onOpen()
    })
    .catch(() => {
      console.log("Update API Error")
      alert("ERROR!")
    })
}

export const patchUpdate = async (
  updateDate: number,
  updateForm: UpdateValuesArrayType,
  dispatch: Dispatch<UnknownAction>,
  schoolIds: string[],
  onClose: () => void
) => {
  await patchData<string[]>(`/update?update-date=${updateDate}`, updateForm)
    .then((response) => {
      reloadSchool(schoolIds, dispatch)
      reloadBatchList(dispatch)
      reloadARTRList(dispatch)
      onClose()
    })
    .catch(() => {
      console.log("Update API Error")
      alert("ERROR!!")
    })
}