import {
  GaugeSetting, TankInfoDistResponse,
  TankInfoGaugeResponse,
  TankInfoGraphResponse, TankInfoMemoResponse,
  TankInfoSpecResponse
} from "../../types/apiResponse/tankInfoSettingTypes";
import {getData, putData} from "../../utils";
import {doNothing} from "../../utils/common/doNothing";

export const putGaugeSetting = (
  tankId: string,
  gaugeSetting: GaugeSetting,
  settingCallback: () => void
) => {
  putData<null>(`tank-info-card-detail/${tankId}/gauge-setting`, gaugeSetting)
    .then(() => doNothing())
    .finally(() => settingCallback())
}

export const getTankInfoGauge = async (
  tankId: string,
  cardNames: string[],
) => {
  if (cardNames.length === 0) return {}
  else {
    const cardQueryParams = cardNames.join("&card-names=")
    return await getData<TankInfoGaugeResponse>(`tank-info-gauge/${tankId}?card-names=${cardQueryParams}`)
  }
}

export const getTankInfoSpec = async (
  tankId: string,
  cardNames: string[],
) => {
  if (cardNames.length === 0) return {}
  else {
    const cardQueryParams = cardNames.join("&card-names=")
    return await getData<TankInfoSpecResponse>(`tank-info-spec/${tankId}?card-names=${cardQueryParams}`)
  }
}

export const getTankInfoGraph = async (
  tankId: string,
  cardNames: string[],
) => {
  if (cardNames.length === 0) return {}
  else {
    const cardQueryParams = cardNames.join("&card-names=")
    return await getData<TankInfoGraphResponse>(`tank-info-graph/${tankId}?card-names=${cardQueryParams}`)
  }
}

export const getTankInfoMemo = async (
  tankId: string,
) => {
  return await getData<TankInfoMemoResponse>(`tank/${tankId}/memo`)
}

export const getTankInfoDistribution = async (
  tankId: string,
  cardNames: string[],
) => {
  if (cardNames.length === 0) return {}
  else {
    const cardQueryParams = cardNames.join("&card-names=")
    return await getData<TankInfoDistResponse>(`tank-info-distribution/${tankId}?card-names=${cardQueryParams}`)
  }
}
