import { BaseModal, BaseModalHeader } from "../common/Modal"
import { Divider, ModalBody, ModalContent, useDisclosure } from "@nextui-org/react";
import { SelectTanks } from "./SelectTanks";
import { SelectBatch } from "./SelectBatch";
import { SelectButton } from "./SelectButton";
import { SelectTanksBrowserOpen } from "./SelectTanksBrowserOpen";
import { ModalText } from "../common/Text";

export const SelectTanksToBrowse = () => {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  return (
    <>
    <SelectTanksBrowserOpen onOpen={onOpen}/>
      <BaseModal isOpen={isOpen} onOpenChange={onOpenChange} size={"5xl"}>
      <ModalContent>
          {(onClose) => (
            <>
              <BaseModalHeader 
              title="Select Tanks to Browse" 
              description={ModalText("Select Tanks to Browse" )}/>
              <ModalBody className='gap-0 min-h-[20rem] pl-10 pr-10'>
                <Divider className="my-4" style={{margin: "1px"}}/>
                <SelectBatch/>
                <SelectTanks/>
              </ModalBody>
              <SelectButton onClose={onClose}/>
            </>
          )}
        </ModalContent>
      </BaseModal>
    </>
  )
}