import {
  GetTokenValidityIntrospectResponseType,
  PostClaimAccessTokenResponseType,
  PostLoginRequestType,
  PostLoginResponseType
} from "../types/apiResponse/authorizationTypes";
import axios, {Axios} from "axios";
import {useAuthStore} from "../store/auth/useAuthStore";
import useChartStore from "../store/chart/useChartStore";
import useCustomizeLiveStore from "../store/live/useCustomizeLiveStore";

const authClient: Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false
})

export const login = async (
  data: PostLoginRequestType,
) => {
  await authClient.post<PostLoginResponseType>("account/login", data)
    .then((response) => {
      useAuthStore.getState().login(response.data.accessToken, response.data.refreshToken)
    })
  // useAuthStore.getState().login("test Token", "test Token")
};

export const refresh = async (refreshToken: string) => {
  return (
    await authClient.get<PostClaimAccessTokenResponseType>("account/claim-access-token", {headers: {Authorization: "Bearer " + refreshToken}})
    .catch((error) => {return error})
  )
};

export const introspect = (tokenType: "access" | "refresh", setState: (isValid: boolean)=>void) => {
  const token = tokenType === "access"
  ? useAuthStore.getState().token
    : useAuthStore.getState().refreshToken
  if (token === null) setState(false)
  else authClient.get<GetTokenValidityIntrospectResponseType>(`account/introspect`, {headers:{Authorization: "Bearer " + token}}).then(
    (response) => {
      setState(response.data.validity)
    }
  )
  // setState(true)
}

export const logout = () => {
  const token = useAuthStore.getState().refreshToken
  authClient.delete<GetTokenValidityIntrospectResponseType>(`account/logout`, {headers:{Authorization: "Bearer " + token}}, ).then(
    () => {
      useChartStore.getState().reset()
      useCustomizeLiveStore.getState().reset()
      useChartStore.persist.clearStorage()
      useCustomizeLiveStore.persist.clearStorage()
      useAuthStore.getState().logout()
    }
  )
}
