import {FC} from "react";
import {useSelectTankStore} from "../../store/selectTank/useSelectTankStore";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import * as ARSTore from "../../store/activeRelation";
import * as TankStore from "../../store/tank";
import {SubmitTankType} from "../../types/apiResponse/tankTypes";
import TankChip from "./TankChip";

export type TankChipContainerProps = {
  title: string
  batchId: string
}

export const TankChipContainer: FC<TankChipContainerProps> = (
  {
    title,
    batchId
  }
) => {
  const {attach, detach, contains} = useSelectTankStore()
  const storedARList = useSelector<AppState, ARSTore.ARState>(state => state.activeRelation)
  const storedTankList = useSelector<AppState, TankStore.TankState>(state => state.tank)
  const batchName = useSelector<AppState, string>(state => state.batch[batchId].batchName)
  const tankChips = storedARList[batchId] === undefined?[]
    :storedARList[batchId].map((activeRelation, index) => {
    const tankId = activeRelation.tankId
    const tankType: SubmitTankType = {tankId: tankId, tankName: storedTankList[tankId].tankName, batchId: batchId, batchName: batchName}

    const onTankChipClick = (tankId: string) => {
      if (contains(tankId)) detach(tankId)
      else attach(tankType)
    }
    return <TankChip key={index} submitTank={tankType} isActivated={contains(tankId)} onTankChipClick={onTankChipClick}/>
  })

  return (
    <>
      {tankChips.length === 0 ? null
        : <div>
          <p className="text-tdplindigo text-[1rem] my-2">{title}</p>
          <div className="flex flex-row flex-wrap my-2 mx-1">
            {tankChips}
          </div>
        </div>
      }
    </>
  )
}