import {FC, useEffect, useState} from "react";
import {ModalBody} from "@chakra-ui/react"
import TankInfoTitle from "../components/tankInfo/Title";
import TankInfoCardContainer from "../components/tankInfo/TankInfoCardContainer";
import {TankInfoLiveContainer} from "../components/tankInfo/TankInfoLiveContainer";
import {useToggleSettings} from "../hooks/tankInfo/useToggleSettings";
import {getOrder} from "../components/tankInfo/constant";
import {useTankInfoModalStore} from "../store/tankInfo/useTankInfoModalStore";


export const TankInfo: FC = () => {
  const {tankId, tankName, initialHlsUri, initialPosition} = useTankInfoModalStore()
  const {data} = useToggleSettings()
  const [fishCardNames, setFishCardName] = useState(
    data === undefined ? [] : getOrder("Fish").filter((card) => data[card])
  )
  const [waterCardNames, setWaterCardName] = useState(
    data === undefined ? [] : getOrder("Water").filter((card) => data[card])
  )
  const [tankCardNames, setTankCardName] = useState(
    data === undefined ? [] : getOrder("Tank").filter((card) => data[card])
  )

  useEffect(() => {
    if (data !== undefined) {
      setFishCardName(getOrder("Fish").filter((card) => data[card]))
      setWaterCardName(getOrder("Water").filter((card) => data[card]))
      setTankCardName(getOrder("Tank").filter((card) => data[card]))
    }
  }, [data]);


  return (
    <ModalBody p={0}>
      <TankInfoTitle tankId={tankId} tankName={tankName}/>
      <TankInfoCardContainer tankId={tankId} title="Tank" cardNames={tankCardNames}/>
      <TankInfoCardContainer tankId={tankId} title="Water" cardNames={waterCardNames}/>
      <TankInfoCardContainer tankId={tankId} title="Fish" cardNames={fishCardNames}/>
      <TankInfoLiveContainer tankId={tankId} initialHlsUri={initialHlsUri}
                             initialPosition={initialPosition}/>
    </ModalBody>
  )
}