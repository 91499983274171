import { Card, Chip } from "@nextui-org/react"
import searchIcon from '../../assets/icons/searchIcon.png'
import { useShallow } from 'zustand/react/shallow';
import useChartStore from "../../store/chart/useChartStore";
import { FC } from "react";

type SelectTanksBrowserOpenProps = {
  onOpen: () => void
}

export const SelectTanksBrowserOpen :FC<SelectTanksBrowserOpenProps>= ({
  onOpen
}) => {
  const {schools, batchName} = useChartStore(
    useShallow((state) => ({
      schools: state.schools,
      batchName: state.batchName
    })),
  )
  return (
    <Card className='flex flex-row w-full items-center p-[10px] pt-1 pb-1 border border-tdpldarkgrey border-opacity-60' radius='sm' shadow="sm" isPressable onPress={onOpen}>
      <img src={searchIcon} alt="" className="w-[2rem]"/>
      {schools.length ===0 ?
      <div className="flex-grow m-0 text-center align-items: center text-tdpllightgrey">
        Click here to select tanks to browse.
      </div>
      : <div className="flex-grow m-0 text-center align-items: center">
          {batchName}<span className="p-3 text-tdpllightgrey">|</span>
          {schools.map((item) => {
        return (
          <Chip key={item.schoolId} radius="sm" classNames={{base:'border-small border-tdpldarkgrey bg-tdplsky border-opacity-60 m-1 p-0', content:"font-bold fit-content"}}>{item.tankName}</Chip> 
        )
      })}
        </div>
    }
    </Card>
  )
}