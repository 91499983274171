import { create } from "zustand";
import { createJSONStorage, persist, subscribeWithSelector } from "zustand/middleware";
import { cameraMapType, getAllCamerasResponseType, videoMapResponseType } from "../../types/apiResponse/liveTypes";
import { allCamerasTest } from "../../api/test/allCameras";
import { camListArrayType } from "../../types/props/live";
import { Selection } from '@react-types/shared'

type CustomizeLiveState = {
  allCameras: getAllCamerasResponseType | null
  allCamerasMap: { [key:string]: string[] } | null
  cameraList: camListArrayType,
  selectedCol: Selection
  selectedRow: Selection
  cameraGrid: string[]
  saveData: cameraMapType | null
};

type CustomizeLiveAction = {
  setAllCameras: (allCameras:getAllCamerasResponseType) => void
  setCameraList: (camList:camListArrayType) => void
  setVideoMap: (videoMap:videoMapResponseType) => void
  setSelectedCol: (col:Selection) => void
  setSelectedRow: (row:Selection) => void
  setSaveData: () => void
  reset: () => void
};

const initialState: CustomizeLiveState = {
  allCameras: allCamerasTest,
  allCamerasMap: null,
  cameraList: Array(12).fill({tankName: null, tankId: null, cameraPosition: null}),
  selectedCol: new Set(["4"]),
  selectedRow: new Set(["3"]),
  cameraGrid: ["4","3"],
  saveData: null
}

const useCustomizeLiveStore = create(
  subscribeWithSelector(
  persist<CustomizeLiveState & CustomizeLiveAction>(
    (set, get) => ({
      ...initialState,
      setAllCameras: (allCameras) => {
        set(() => ({
          allCameras: allCameras,
          allCamerasMap: allCameras.reduce((acc, { tankId, left, right, top }) => ({
            ...acc,
            [tankId]: (!left ? ["left"] : []).concat(!right ? ["right"] : [], !top ? ["top"] : [])
          }), {})
        }))
      },
      setCameraList: (camList) => {
        set(() => ({cameraList: camList}))
      },
      setVideoMap: (videoMap) => {
        set(() => ({
          selectedCol: new Set([String(videoMap.latestGridKey.col)]),
          selectedRow: new Set([String(videoMap.latestGridKey.row)]),
          cameraGrid: [String(videoMap.latestGridKey.col), String(videoMap.latestGridKey.row)],
          cameraList: Array.from({ length: 12 }, (_, index) => 
            videoMap.videoUrls[index] ? {
              tankName: videoMap.videoUrls[index].tankName,
              tankId: videoMap.videoUrls[index].tankId,
              cameraPosition: videoMap.videoUrls[index].cameraPosition ?? null
            } : { tankName: null, tankId: null, cameraPosition: null }
          )
        }))
      },
      setSelectedCol: (col) => {
        set((state) => ({
          selectedCol: col,
          cameraGrid: [String(Array.from(col)[0]), state.cameraGrid[1]]
        }))
      },
      setSelectedRow: (row) => {
        set((state) => ({
          selectedRow: row,
          cameraGrid: [state.cameraGrid[0], String(Array.from(row)[0])]
        }))
      },
      setSaveData: () => {
        set((state) => ({
          saveData: {
            latestGridKey: {
              col: Number(state.cameraGrid[0]),
              row: Number(state.cameraGrid[1])
            },
            cameraList: state.cameraList.slice(0, state.cameraGrid.reduce((a,b) => Number(a) * Number(b), 1))
            .map((item) => {
              return {
                tankId: item.cameraPosition? item.tankId:null,
                cameraPosition: item.tankId && item.cameraPosition? item.cameraPosition : null
              }
            })
          }
        }))
      },
      reset: () => {
        set({allCameras:null, cameraList:[], allCamerasMap:null})
      }
    }),
    {
      name: "CustomLive-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
));

export default useCustomizeLiveStore;
