import React, {FC} from "react";
import {Button} from "@nextui-org/react";
import settingGear from "../../assets/icons/settingGear.png"
import {Drawer, useDisclosure, DrawerOverlay} from "@chakra-ui/react";
import {SettingDrawer} from "./SettingDrawer";

export type TankInfoTitleProps = {
  tankId: string
  tankName: string | null
}


export const TankInfoTitle: FC<TankInfoTitleProps> = (
  {
    tankId,
    tankName
  }
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (

    <div className="flex flex-col mb-[20px] p-[10px]">
      <div className="flex flex-row justify-between align-middle">
        <div className="flex flex-row">
          <h1 className="text-tdplindigo font-bold text-[2rem]">
            Tank Information
          </h1>
          {
            tankName
              ? <h1 className="text-tdpllightgrey text-[2rem] mx-2">
                {" / "}
              </h1>
              : null
          }
          {
            tankName
              ? <h1 className="text-tdplindigo font-bold text-[2rem]">
                {tankName}
              </h1>
              : null
          }
        </div>
        <div className="flex items-center justify-center align-middle">
          <Button className="bg-white" isIconOnly onClick={onOpen}>
            <img src={settingGear} alt=""/>
          </Button>
        </div>
        <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="sm">
          <DrawerOverlay/>
          <SettingDrawer tankId={tankId} tankName={tankName} onClose={onClose}/>
        </Drawer>
      </div>

      <p className="text-[0.875rem] text-tdplindigo">
        In "Tank Information", you can monitor the detailed data collected for each tank.
      </p>
    </div>
  )
}

export default TankInfoTitle