import { FC, useEffect, useState } from "react";
import useChartStore from "../../store/chart/useChartStore"
import { useShallow } from 'zustand/react/shallow';
import { BaseModalFooter } from "../common/Modal";

type SelectButtonProps = {
  onClose:() => void
}

export const SelectButton :FC<SelectButtonProps> = ({
  onClose
}) => {
  const [active, setActive] = useState<boolean>(false)
  const {setSchools, preSet, returnPreSet, preSelectedTanks, selectedTanks} = useChartStore(
    useShallow((state) => ({
      setSchools: state.setSchools,
      preSet: state.preSet,
      returnPreSet: state.returnPreSet,
      preSelectedTanks: state.preSelectedTanks,
      selectedTanks: state.selectedTanks
    })),
  )

  useEffect(() => {
    if ((preSelectedTanks.filter(item => !selectedTanks.map(item=>item.schoolId).includes(item.schoolId)).length===0 
    && selectedTanks.filter(item => !preSelectedTanks.map(item=>item.schoolId).includes(item.schoolId)).length===0) || selectedTanks.length===0) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [preSelectedTanks, selectedTanks])

  const nextOnPress = () => {
    if (active) {
      preSet()
      setSchools()
      onClose()
    }
  }

  return <BaseModalFooter back="Cancel" next="Select" backOnPress={()=>{returnPreSet();onClose()}} nextOnPress={nextOnPress} 
  nextClassName={`${active ? "bg-tdplplum" : "bg-tdplpigpink"}`}/>

}