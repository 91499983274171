import {FC, useEffect, useState} from "react";
import {CardNameLiteral, SpecSetting} from "../../../types/apiResponse/tankInfoSettingTypes";
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {makeTitle} from "../../../utils/tankInfo";
import {useTankInfoSpec} from "../../../hooks/tankInfo/useTankInfoValues";
import {AlertIcon} from "../svg/AlertIcon";
import {convertMetric} from "../../../utils/metricConvert";
import {NoContent} from "./NoContent";

export type SpecProps = {
  tankId: string
  cardName: CardNameLiteral
  specSetting: SpecSetting
}

export const SpecCard: FC<SpecProps> = (
  {
    tankId,
    cardName,
    specSetting,
  }
) => {
  const tankInfoSpec = useTankInfoSpec(tankId, cardName)
  // const curValue = tankInfoSpec ? tankInfoSpec[cardName]?.curValue : undefined
  const {val, metric} = convertMetric(
    tankInfoSpec?tankInfoSpec[cardName]?.curValue:undefined,
    cardName,
    specSetting?specSetting.metric:""
  )
  const {title, subTitle} = makeTitle(cardName)
  const [isToday, setIsToday] = useState<boolean>(true)

  useEffect(() => {
    if (tankInfoSpec !== undefined && tankInfoSpec !== null) {
      if (tankInfoSpec[cardName] !== undefined) {
        const cardResponse = tankInfoSpec[cardName]
        if (cardResponse !== undefined) setIsToday(cardResponse.isToday === undefined ? true : cardResponse.isToday)
      }
    }
  }, [tankInfoSpec]);

  const subSpecs = tankInfoSpec ? tankInfoSpec[cardName]?.subSpec?.map((subSpec, index) => {
    const filteredSpecSetting = specSetting.subSpec.filter((spec) => spec.specName === subSpec.specName)
    const metricString = (filteredSpecSetting.length === 0) ? "" : filteredSpecSetting[0].metric ? filteredSpecSetting[0].metric : ""
    const {val, metric} = convertMetric(
      subSpec?subSpec.curValue:undefined,
      subSpec.specName,
      filteredSpecSetting.length===0?"":filteredSpecSetting[0].metric,
      3
    )
    return (
      <div className="flex flex-row justify-between place-items-center h-[21px]" key={index}>
        <span className="text-[14px] text-tdplmiddlegrey font-semibold">
          {subSpec.specName}
        </span>
        <span className="text-[18px] text-tdpldarkgrey font-bold">
          {val}
          {metric!=="---"&&metric?
            <span className="text-[14px] text-tdplmiddlegrey">{" " + metricString}</span>
            :null
          }
        </span>
      </div>
    )
  }) : <></>

  return (
    <Card className={`rounded-lg col-span-1 outline outline-[1px] outline-tdpldarkgrey/[0.60] w-[253px] h-[140px] p-[19px]
      ${isToday ? "bg-tdplbisque" : "bg-tdpllightgrey"}`}
          style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
      <CardHeader className="flex flex-row items-start  m-0 p-0 mb-[15px] h-[22px]">
        {isToday ? <></> : <div className="mt-[6px] mr-1"><AlertIcon/></div>}
        <p className="text-tdpldarkgrey text-[1rem] font-bold mr-2">
          {title}
        </p>
        {subTitle !== undefined
          ? <p className="text-tdplmiddlegrey text-[1rem]">
            {subTitle}
          </p>
          : null
        }
      </CardHeader>
      <CardBody className="m-0 p-0 h-[65px] overflow-hidden">
        {
          tankInfoSpec === null || tankInfoSpec === undefined || tankInfoSpec[cardName] === undefined || tankInfoSpec[cardName]?.curValue === null || tankInfoSpec[cardName]?.curValue === undefined
            ? <NoContent/>
            : <div className="flex flex-row justify-between">
              <div className="flex flex-col w-[115px] items-start">
                <p className="text-[30px] font-bold text-tdplprussian">{val}</p>
                <p className="text-[14px] font-normal text-tdplprussian">{metric}</p>
              </div>
              <div className="flex flex-col w-[100px] justify-center">
                {subSpecs}
              </div>
            </div>
        }
      </CardBody>
    </Card>
  )
}