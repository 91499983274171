import { FC } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Chip } from "@nextui-org/react";
import { EventType, InputBase } from "./Input";
import {GradeTankValuesArrayType, TankValuesArrayType, UpdateSchoolValuesArrayType} from "../../types/apiResponse/tankTypes";
import { ColumnsArrayType } from "./columns";
import { format, parse } from "date-fns";

export type InsertTableProps ={
  tanksValues: TankValuesArrayType | UpdateSchoolValuesArrayType | GradeTankValuesArrayType
  inputRefs:  React.MutableRefObject<(HTMLInputElement | null)[]>
  columns : ColumnsArrayType
  insertData: (event:EventType) => void
  isGrade?: boolean
}

export const InsertTable : FC<InsertTableProps>= ({
  tanksValues,
  inputRefs, 
  columns,
  insertData,
  isGrade=false,
}) => {
  return (
    <Table 
      isHeaderSticky
      aria-label="Data Field"
      radius='none'
      classNames={{
        base: "overflow-scroll min-h-[20rem] max-h-[20rem] overflow-y-hidden overflow-x-hidden",
        wrapper: "min-h-[20rem]",
        td:"p-0",
      }}
      >
      <TableHeader columns={columns}>
        {(column) => <TableColumn className='font-bold text-center text-tdplprussian bg-tdplsky' key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody>
        {tanksValues.map((item, index) =>
          <TableRow key={item.tankId} className='border border-l-white border-r-white border-t-white border-b-divider data-[focus-visible=true]:outline-opacity-0'>
            {columns.map((cols, col_index) => 
              <TableCell key={item.tankId+col_index} className='py-0 data-[focus-visible=true]:outline-opacity-0' style={{ minWidth: `${cols.placeholder?.length+1 || 0}ch`, minHeight: "2" }}>
                {cols.key==="tankName"?
                <div className="grid place-items-center">
                  <Chip key={item.tankId} radius="sm" 
                  classNames={{base:`border-small border-tdpldarkgrey bg-${item.batchName === "$empty"? "tdpllightgrey":"tdplsky"} border-opacity-60 m-1 p-0`, content:"font-bold fit-content"}}>{item.tankName}</Chip>
                  </div>
                : cols.key === "resultingCount" && 'resultingCount' in item && isGrade? <p key={cols.key} style={{textAlign:'center'}} >{Math.floor(Number(item.resultingCount))}</p>
                : cols.key === "resultingCount" && "harvest" in item? <p key={cols.key} style={{textAlign:'center'}} >{Math.floor(Number(item.resultingCount) - Number(item.harvest) - Number(item.mortality))}</p>
                : isGrade && cols.key === "moveDate" && "moveDate" in item? <p key={cols.key}style={{textAlign:'center', whiteSpace:"nowrap"}} >{format(parse(String(item.moveDate), 'yyyymmdd', new Date()), 'yyyy-mm-dd') }</p> 
                : <InputBase key={item.tankId} id={index} inputRefs={inputRefs} index={index*(columns.length -1) + col_index -1} insertData={insertData} col={cols.key} value={item} placeholder={cols.placeholder}/>
                }
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}